import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { checkForFeaturedTag } from "../../modules/Menu/utils";
import { getProductCategory } from "../../modules/Products/utils";
import { BasketVerificationLoyalty } from "../../services/api/order/model";
import { AddCartItemPayload, UpdateCartItemPayload } from "../cart/models";
import { MenuSection } from "../menu/models";
import {
  OrderCollectionTypeProps,
  OrderTotals,
  SetupOrderProps,
} from "../order/models";
import { Store } from "../store/models";
import { AllActionsOf } from "../utils/Actions";
import {
  AddUpsellItemToCart,
  AnalyticsState,
  UpdateUserAccountAnalytics,
} from "./models";

const initialState: AnalyticsState = {
  menuSection: null,

  productId: [],
  productName: [],
  productCategory: [],
  productQuantity: [],
  storeName: null,
  storeId: null,
  orderType: null,
  orderDate: null,
  orderTime: null,
  basketId: null,
  localGyg: null,
  featuredItems: [],
  recentOrders: [],
  orderCompletionTime: null,

  tableInit: null,
  groupOrderType: null,

  isAddSomethingElse: false,
  addSomethingElse: [],

  currrentLoyaltyPoints: null,
  pointsToConversion: null,
  pointsAddedtoLoyalty: null,
  loyaltyRewardConversionLimit: null,
  loyaltyRewardAmount: null,
  actualDollarsBalance: null,
  actualPointsBalance: null,
  cardNumber: null,
  afterCheckoutDollarsBalance: null,
  afterCheckoutPointsBalance: null,

  upsellProductId: [],
  didAddToCartUpsell: false,
  isUpsellPreCart: false,

  isAuthTokenExpired: false,
  isFirstTimeUser: true,

  paymentType: null,
  totalAfterDiscount: null,
  totalBeforeDiscount: null,

  updateUserAccountState: null,

  showRatingModal: false,
};

const analyticsSlice = createSlice({
  name: "Analytics",
  initialState: initialState,
  reducers: {
    setMenuSection(state, action: PayloadAction<MenuSection>) {
      state.menuSection = action.payload;
    },

    addCartItem(state, action: PayloadAction<AddCartItemPayload>) {
      const payload = action.payload;
      payload.cartItems.forEach((p) => {
        state.productId.push(p.productId);
        state.productName.push(p.name);
        state.productQuantity.push(p.quantity);
        state.productCategory.push(
          getProductCategory(p.productId, state.menuSection)
        );
        if (state.isAddSomethingElse) {
          state.addSomethingElse.push(p.productId);
        }

        if (checkForFeaturedTag(p.tags, p.tagLookup)?.value) {
          state.featuredItems.push(p.productId);
        }
      });
    },

    updateCartItem(state, action: PayloadAction<UpdateCartItemPayload>) {
      const cartItemToUpdateIndex =
        action.payload.index === undefined
          ? state.productId.findIndex(
              (prodcutId) => prodcutId === action.payload.cartItem.productId
            )
          : action.payload.index;
      const updatedCartItem = {
        ...action.payload.cartItem,
      };
      state.productId[cartItemToUpdateIndex] = updatedCartItem.productId;
      state.productCategory[cartItemToUpdateIndex] = getProductCategory(
        updatedCartItem.productId,
        state.menuSection
      );
      state.productName[cartItemToUpdateIndex] = updatedCartItem.name;
      state.productQuantity[cartItemToUpdateIndex] = updatedCartItem.quantity;
    },

    deleteCartItem(state, action: PayloadAction<number>) {
      state.productId.splice(action.payload, 1);
      state.productCategory.splice(action.payload, 1);
      state.productName.splice(action.payload, 1);
      state.productQuantity.splice(action.payload, 1);
      state.addSomethingElse.splice(action.payload, 1);
      state.featuredItems.splice(action.payload, 1);
    },

    clearCart(state) {
      state.productId = [];
      state.productCategory = [];
      state.productName = [];
      state.productQuantity = [];
      state.addSomethingElse = [];
      state.upsellProductId = [];
      state.featuredItems = [];
      state.didAddToCartUpsell = false;
    },

    setStore(state, action: PayloadAction<Store>) {
      const payload = action.payload;

      state.storeId = payload.id;
      state.storeName = payload.name;
    },

    setupOrderCollectionType(
      state,
      action: PayloadAction<OrderCollectionTypeProps>
    ) {
      const payload = action.payload;
      state.orderType = payload.orderCollectionType;
    },

    setupOrder(state, action: PayloadAction<SetupOrderProps>) {
      const payload = action.payload;

      state.orderType = payload.orderCollectionType;
      state.orderTime = payload.orderTime.toString();
      state.orderDate = moment.unix(payload.orderTime).format("DD/MM/YYYY");
      state.orderType = payload.orderCollectionType;
      state.tableInit = payload.tableInit;
    },

    updateOrderTime(state, action: PayloadAction<number>) {
      const payload = action.payload;
      state.orderTime = payload.toString();
      state.orderDate = moment.unix(payload).format("DD/MM/YYYY");
    },

    setBasketId(state, action: PayloadAction<string>) {
      state.basketId = action.payload;
    },

    setCurrentLoyaltyPoints(state, action: PayloadAction<number>) {
      state.currrentLoyaltyPoints = action.payload;
    },

    setTotals(state, action: PayloadAction<OrderTotals>) {
      state.totalAfterDiscount = action.payload.totalAfterDiscount;
      state.totalBeforeDiscount = action.payload.totalBeforeDiscount;
    },

    setOrderLoyaltyPoints(
      state,
      action: PayloadAction<BasketVerificationLoyalty>
    ) {
      state.pointsAddedtoLoyalty = action.payload.accruedPoints;
      state.pointsToConversion = action.payload.convertedDollars;
      state.loyaltyRewardAmount = action.payload.loyaltyRewardAmount;
      state.loyaltyRewardConversionLimit =
        action.payload.loyaltyRewardConversionLimit;
      state.actualDollarsBalance = action.payload.actualDollarsBalance;
      state.actualPointsBalance = action.payload.actualPointsBalance;
      state.cardNumber = action.payload.cardNumber;
      state.afterCheckoutDollarsBalance =
        action.payload.afterCheckoutDollarsBalance;
      state.afterCheckoutPointsBalance =
        action.payload.afterCheckoutPointsBalance;
    },

    isAddSomethingElse(state, action: PayloadAction<boolean>) {
      state.isAddSomethingElse = action.payload;
    },

    setNearestStore(state, action: PayloadAction<string | null>) {
      state.localGyg = action.payload;
    },

    addUpsellItemToCart(state, action: PayloadAction<AddUpsellItemToCart>) {
      if (action.payload.id) {
        state.upsellProductId = [action.payload.id];
        state.didAddToCartUpsell = action.payload.didAddToCart;
        state.isUpsellPreCart = action.payload.isUpsellPreCart;
      } else {
        state.upsellProductId = [];
        state.didAddToCartUpsell = false;
      }
    },

    setAuthTokenExpired(state, action: PayloadAction<boolean>) {
      state.isAuthTokenExpired = action.payload;
    },

    setIsFirstTimeUser(state, action: PayloadAction<boolean>) {
      state.isFirstTimeUser = action.payload;
    },

    setPaymentType(state, action: PayloadAction<string>) {
      state.paymentType = action.payload;
    },

    setUpdateUserAccountToState(
      state,
      action: PayloadAction<UpdateUserAccountAnalytics | null>
    ) {
      state.updateUserAccountState = action.payload;
    },

    setShowRatingModal(state, action: PayloadAction<boolean>) {
      state.showRatingModal = action.payload;
    },
  },
});

const { actions, reducer } = analyticsSlice;
export type AnalyticsActionType = AllActionsOf<typeof actions>;
export {
  actions as AnalyticsAction,
  reducer as analyticsReducer,
  initialState,
};
