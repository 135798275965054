import type { LottiePlayer } from "lottie-web";
import { useCallback, useEffect, useState } from "react";

interface UseLottieWebReturn {
  lottie: LottiePlayer | undefined;
}

export const useLottieWeb = (): UseLottieWebReturn => {
  const [lottie, setLottie] = useState<LottiePlayer | undefined>();

  const loadLottie = useCallback(async () => {
    const lottieLib = await import("lottie-web");
    setLottie(lottieLib.default);
  }, []);

  useEffect(() => {
    loadLottie();
  }, [loadLottie]);

  return { lottie };
};
