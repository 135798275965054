import { StyleSheet } from "react-native";

import colours from "styles/colours";
import { Typography } from "styles/typography";

const styles = StyleSheet.create({
  orderSetupContentContainer: {
    flexDirection: "row",
    alignSelf: "center",
    width: "100%",
    marginTop: 24,
  },
  mobileContainer: {
    alignSelf: "center",
    width: "100%",
    minHeight: "auto",
  },
  distanceText: {
    ...Typography.bodyTwoBold,
    color: colours.midGrey,
    letterSpacing: 0.27,
    lineHeight: 16,
    paddingBottom: 8,
  },
  storeDetailsContent: {
    flex: 1,
    width: "100%",
    marginRight: 24,
    textAlign: "left",
  },
  storeTitle: {
    paddingBottom: 8,
    textTransform: "uppercase",
    paddingTop: 8,
  },
  infoButtonsWrapper: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 16,
  },
  changeRestaurantButtonWrapper: {
    flex: 1,
    width: "100%",
    marginBottom: 16,
  },
  moreInfoButtonWrapper: {
    flex: 1,
    paddingRight: 0,
    width: "100%",
    marginBottom: 16,
  },
  orderSetupFormWrapper: {
    width: "60%",
  },
  mobileSetupFormWrapper: {
    flex: 1,
    width: "100%",
    textAlign: "left",
  },
  orderType: {
    ...Typography.titleFour,
    paddingBottom: 8,
  },
  orderTypeWithDelivery: {
    ...Typography.titleFour,
    fontSize: 18,
    paddingBottom: 8,
  },
  orderTypeError: {
    ...Typography.body,
    paddingBottom: 8,
    color: colours.red,
  },
  title: {
    ...Typography.headline,
    fontSize: 18,
    marginBottom: 8,
  },
  pickUpTypeContainer: {
    zIndex: 100,
  },
});

export default styles;
