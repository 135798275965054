import React, { useMemo } from "react";
import {
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  View,
} from "react-native";
import { Shadow } from "react-native-shadow-2";

import * as PlatformUtils from "../../modules/platformUtils";
import tickIcon from "../assets/icons/icon_tick.png";
import colours from "../styles/colours";
import { BorderRadius } from "../styles/number";

export interface CheckboxProps {
  disabled?: boolean;
  selected: boolean;
  error?: boolean;
  customImage?: string;
}

const sharedStyles = {
  width: 32,
  height: 32,
  borderRadius: BorderRadius.Light,
};

const styles = StyleSheet.create({
  container: {
    ...sharedStyles,
    backgroundColor: colours.white,
  },
  icon: {
    width: 32,
    height: 32,
    resizeMode: "cover",
  },
  error: {
    ...sharedStyles,
    shadowColor: colours.darkGrey,
    backgroundColor: colours.white,
    borderColor: colours.red,
    borderWidth: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  disabled: {
    ...sharedStyles,
    backgroundColor: colours.lightGrey,
  },
});

const Checkbox: React.FC<CheckboxProps> = ({
  disabled = false,
  selected,
  error,
  customImage = undefined,
}) => {
  const image = customImage ? customImage : tickIcon;

  const viewStyle = useMemo(() => {
    if (error) {
      return styles.error;
    }

    return disabled ? styles.disabled : styles.container;
  }, [error, disabled]);

  return (
    <Shadow distance={4} startColor={colours.shadow} offset={[0, 0]}>
      <View style={viewStyle}>
        {selected && (
          <Image
            {...PlatformUtils.generateTestID(Platform.OS, "CheckIcon")}
            style={styles.icon}
            source={image as ImageSourcePropType}
          />
        )}
      </View>
    </Shadow>
  );
};

export default Checkbox;
