import React from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { OrderReduxAction, OrderReduxModels, RootState } from "../..";
import errorImage from "../../components/assets/images/illustration_error.png";
import ModalWithButton from "../../components/modals/ModalWithButton";
import { HttpStatus } from "../../services/httpClient";
import { getStatusCode } from "../../utils/order";

export const ORDER_ERROR_MODAL_DISPLAYED = "OrderErrorModal was shown:";

const OrderErrorModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { displayGenericOrderError, getOrderError } = useSelector(
    (s: RootState) => s.order
  );

  const onModalDismiss = () => {
    dispatch(
      OrderReduxAction.setDisplayErrorModal(
        OrderReduxModels.ErrorModalState.CLOSED
      )
    );
  };

  const orderErrorStatusCode = getStatusCode(getOrderError?.statusCode);

  return (
    <ModalWithButton
      isVisible={
        displayGenericOrderError === OrderReduxModels.ErrorModalState.ERROR
      }
      image={errorImage as ImageSourcePropType}
      small
      title={t("CheckoutPayment:unExpectedStatusTitle")}
      messageBold={
        getOrderError?.message && orderErrorStatusCode === HttpStatus.BadRequest
          ? getOrderError.message
          : t("CheckoutPayment:unExpectedStatusMessage")
      }
      primaryButton={{
        name: t("CheckoutPayment:okGotItButton"),
        action: onModalDismiss,
      }}
      onModalClose={onModalDismiss}
    />
  );
};

export default OrderErrorModal;
