import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  KeyboardTypeOptions,
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  Text,
  TextInput,
  TextInputChangeEventData,
  TextInputProps,
  TextStyle,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";

import { InputType } from "../../modules/Components/Shared/InputType.enum";
import * as PlatformUtils from "../../modules/platformUtils";
import closeIcon from "../assets/icons/icon_close.png";
import editIcon from "../assets/icons/icon_edit.png";
import hideIcon from "../assets/icons/icon_hide_small.png";
import showIcon from "../assets/icons/icon_show_small.png";
import tickIcon from "../assets/icons/icon_tick_small.png";
import colours from "../styles/colours";
import { styles, wrapperStyles } from "./FormInput.styles";

export interface FormInputProps extends TextInputProps {
  isDisabled?: boolean;
  isDisablePadding?: boolean;
  isHidden?: boolean;
  hideEdit?: boolean;
  name?: string | null;
  confirm?: boolean;
  error?: boolean;
  errorMsg?: string | null;
  type?: InputType;
  placeHolder?: string | null;
  value?: string;
  isBorderBottomOnly?: boolean;
  testId?: string;
  inputPrefix?: string;
  contextMenuHidden?: boolean;
  disableClipboard?: boolean;
  onEdit?: () => void;
  // note the prop has to differ from TextInput RN component
  // onChangeText prop due to custom handling for text changes
  handleChangeText?: (text: string) => void;
  onTouchInput?: () => void;
  onActivation?: () => void;
  onSubmit?: () => void;
}

const FormInput = React.forwardRef(
  (props: FormInputProps, ref: React.Ref<TextInput>) => {
    const { t } = useTranslation();
    const [isFocused, setFocused] = useState<boolean>(false);
    const [isCensored, setCensored] = useState<boolean>(
      props.type === InputType.PASSWORD
    );

    const containerStyles = props.isBorderBottomOnly
      ? styles(props.error, !!props.inputPrefix, isFocused)
          .inputContainerWithIcon
      : styles(props.error, !!props.inputPrefix, isFocused, !!props.isDisabled)
          .inputContainer;
    const prefixStyles = styles(
      props.error,
      !!props.inputPrefix,
      isFocused
    ).prefix;

    const getTextInputStyles = () => {
      if (Platform.OS === "web") {
        return styles(props.error, !!props.inputPrefix).inputText;
      } else if (props.isBorderBottomOnly) {
        return styles(props.error, !!props.inputPrefix).inputTextBorderBottom;
      } else if (props.value && props.value.length > 0) {
        return styles(props.error, !!props.inputPrefix).inputText;
      } else {
        return styles(props.error, !!props.inputPrefix).placeholderText;
      }
    };

    const keyboardType = (): KeyboardTypeOptions | undefined => {
      switch (props.type) {
        case InputType.BIRTHDAY:
          return "numeric";
        case InputType.PHONE:
          return "phone-pad";
        case InputType.EMAIL:
          return "email-address";
        case InputType.DECIMAL:
          return "decimal-pad";
        case InputType.NUMBER:
          return "number-pad";
        default:
          return "default";
      }
    };

    const onFocus = () => {
      setFocused(true);
      if (Platform.OS === "android" && props.onTouchInput) {
        props.onTouchInput();
      }
      if (props.onActivation) props.onActivation();
    };

    const onBlur = () => {
      setFocused(false);
    };

    const handleOnChange = (
      changeEvent: NativeSyntheticEvent<TextInputChangeEventData>
    ) => {
      // if native event is cut or paste, set the input value to previous value or empty
      const { inputType, text } = changeEvent.nativeEvent as never;
      if (props.handleChangeText) {
        if (inputType === "deleteByCut" || inputType === "insertFromPaste") {
          changeEvent.preventDefault();
          changeEvent.stopPropagation();
        }
        props.handleChangeText(text);
      }
    };

    const handlePasswordIconPress = () => setCensored(!isCensored);

    const handleRemoveText = () =>
      props.handleChangeText && props.handleChangeText("");

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && props.onSubmit) {
        // Perform action on Enter key press
        props.onSubmit();
      }
    };

    const inputType =
      props.type === InputType.PASSWORD
        ? isCensored
          ? "password"
          : "text"
        : props.type === InputType.EMAIL
          ? "email"
          : "text";

    return (
      <>
        {!props.isHidden ? (
          <View style={wrapperStyles(props.isDisablePadding).container}>
            {!!props.name && (
              <Text style={styles(props.error).inputName}>{props.name}</Text>
            )}
            <View style={containerStyles}>
              {props.inputPrefix && (
                <Text style={prefixStyles}>{props.inputPrefix}</Text>
              )}
              {Platform.select({
                web: (
                  <input
                    {...PlatformUtils.generateTestID(
                      Platform.OS,
                      props.testId ?? "TextInput"
                    )}
                    ref={ref as unknown as React.Ref<HTMLInputElement>}
                    type={inputType}
                    name={inputType}
                    autoComplete={
                      props.type === InputType.EMAIL
                        ? "email"
                        : props.type === InputType.PASSWORD
                          ? "current-password"
                          : undefined
                    }
                    placeholder={props.placeHolder || undefined}
                    value={props.value}
                    disabled={props.isDisabled}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={(e) => props.handleChangeText?.(e.target.value)}
                    style={getTextInputStyles()}
                    onKeyDown={handleKeyDown}
                  />
                ),
                default: (
                  <TextInput
                    {...props}
                    {...PlatformUtils.generateTestID(
                      Platform.OS,
                      props.testId ?? "TextInput"
                    )}
                    ref={ref}
                    contextMenuHidden={props.contextMenuHidden}
                    autoCorrect={undefined} // Not applicable on web
                    autoCapitalize={
                      props.type === InputType.EMAIL ? "none" : undefined
                    }
                    keyboardType={keyboardType()}
                    maxLength={
                      props.type === InputType.BIRTHDAY ? 10 : undefined
                    }
                    secureTextEntry={isCensored}
                    placeholder={props.placeHolder || undefined}
                    placeholderTextColor={colours.uiGrey}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={props.value}
                    editable={!props.isDisabled}
                    onChange={handleOnChange}
                    style={getTextInputStyles() as StyleProp<TextStyle>}
                    returnKeyType={
                      props.type === InputType.NUMBER ||
                      props.type === InputType.BIRTHDAY
                        ? "done"
                        : "none"
                    }
                  />
                ),
              })}
              {props.isBorderBottomOnly && !props.hideEdit && (
                <TouchableOpacity
                  style={styles(false).editContainer}
                  onPress={props.onEdit}>
                  <Image
                    style={styles(props.error).editIcon}
                    source={editIcon as ImageSourcePropType}
                  />
                  <Text style={styles(false).editText}>
                    {t("UserManagement:editLabel")}
                  </Text>
                </TouchableOpacity>
              )}
              {props.type === InputType.PASSWORD ? (
                <TouchableWithoutFeedback onPress={handlePasswordIconPress}>
                  <Image
                    style={styles(props.error).icon}
                    source={
                      (isCensored ? showIcon : hideIcon) as ImageSourcePropType
                    }
                  />
                </TouchableWithoutFeedback>
              ) : props.type === InputType.NUMBER &&
                props.value &&
                props.value.length > 0 ? (
                <TouchableWithoutFeedback onPress={handleRemoveText}>
                  <Image
                    style={styles(props.error).closeIcon}
                    source={closeIcon as ImageSourcePropType}
                  />
                </TouchableWithoutFeedback>
              ) : props.error ? (
                <Image
                  {...PlatformUtils.generateTestID(
                    Platform.OS,
                    "FormInputEyeIcon"
                  )}
                  style={styles(props.error).redIcon}
                  source={closeIcon as ImageSourcePropType}
                />
              ) : (
                props.confirm &&
                !isFocused && (
                  <Image
                    {...PlatformUtils.generateTestID(
                      Platform.OS,
                      "FormInputEyeIcon"
                    )}
                    style={styles(props.error).tickIcon}
                    source={tickIcon as ImageSourcePropType}
                  />
                )
              )}
            </View>

            <Text
              {...PlatformUtils.generateTestID(Platform.OS, "FormInputError")}
              style={styles(props.error).errorMsg}>
              {props.error &&
                (props.errorMsg
                  ? props.errorMsg
                  : t("CheckoutPayment:mandatoryField"))}
            </Text>
          </View>
        ) : null}
      </>
    );
  }
);

export default FormInput;
