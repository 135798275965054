import moment from "moment";
import { RootState } from "redux_store/rootReducer";
import { createSelector } from "reselect";

import { OrderModules } from "gyg_common";

export const basketValueSelector = createSelector(
  (state: RootState) => state.order.getOrderResponse,
  (getOrderResponse) => {
    return getOrderResponse
      ? OrderModules.OrderUtils.formatOrderResponse(getOrderResponse)
          .basketValue
      : 0;
  }
);

export const orderTimeSelector = createSelector(
  (state: RootState) => state.order,
  (state: RootState) => state.store,
  (order, store) => {
    return moment
      .tz(
        order.orderTime,
        store.storeOrderTimeSlots?.storeTimeZoneInfo?.storeTimeZone
      )
      .format("YYYY-MM-DDTHH:mm");
  }
);
