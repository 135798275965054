import React from "react";
import { useTranslation } from "react-i18next";
import {
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";

import { PlatformUtils } from "gyg_common";
import closeIcon from "gyg_common/components/assets/icons/icon_close.svg";
import FormInput from "gyg_common/components/FormInput";
import MiddleModal, {
  WebModalSize,
} from "gyg_common/components/modals/MiddleModal";
import colours from "styles/colours";
import { Typography } from "styles/typography";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colours.white,
    position: "relative",
    height: "100%",
    borderRadius: 8,
  },
  topContainer: {
    flexDirection: "row",
    marginTop: 20,
    justifyContent: "space-between",
    width: "100%",
  },
  icon: {
    width: 32,
    height: 32,
    marginRight: 16,
    marginLeft: 8,
  },
  heading: {
    ...Typography.largeTitle,
    color: colours.darkGrey,
    textAlign: "center",
    marginLeft: 40,
    flexWrap: "wrap",
    flex: 1,
  },
  messageContainer: {
    marginLeft: 16,
    marginRight: 16,
  },
  message: {
    marginTop: 16,
    ...Typography.body,
    color: colours.darkGrey,
    textAlign: "center",
  },
  textInput: {
    marginTop: 16,
  },
  buttonContainer: {
    marginBottom: 16,
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: -4,
  },
});

export interface IStoreLinkModal {
  isVisible: boolean;
  link: string;
  onClose: () => void;
}

export const StoreLinkModal: React.FC<IStoreLinkModal> = ({
  isVisible,
  link,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <MiddleModal
      isVisible={isVisible}
      webModalSize={WebModalSize.SMALL}
      testId='rewards-detail-modal'>
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <Text style={styles.heading}>{t("Loyalty:referLinkTitle")}</Text>

          <TouchableWithoutFeedback onPress={onClose}>
            <Image
              {...PlatformUtils.generateTestID(Platform.OS, "close-icon-modal")}
              style={styles.icon}
              source={closeIcon as ImageSourcePropType}
            />
          </TouchableWithoutFeedback>
        </View>

        <View style={styles.messageContainer}>
          <View style={styles.textInput}>
            <form style={{ display: "contents" }} autoComplete='on'>
              <FormInput name='' isDisabled={true} value={link} />
            </form>
          </View>
        </View>
      </View>
    </MiddleModal>
  );
};
