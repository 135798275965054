import { TagLookup } from "services/api/menu/model";

import {
  Modifier,
  ProductDiscountsApplied,
} from "../../redux_store/cart/models";
import { ClientTokenReponse } from "../../services/api/checkout/model";
import { BasketItem, Surcharge } from "../../services/api/order/model";
import { StoresTimeZone } from "../../services/api/store/model";
import { ErrorResponse } from "../error/models";
import { CollectionType, DeliveryOrder } from "../order/models";

// TODO: Check COUPON/GIFTCARD in use? It is filtered out in PaymentTypeInfo
// CASH is also not in use (but not filtered out)
export enum PaymentType {
  BRAINTREE = "BRAINTREE",
  COUPON = "COUPON",
  GYGDOLLAR = "GYGDOLLAR",
  CASH = "CASH",
  GIFTCARD = "GIFTCARD",
}

export enum OrderFulfilmentStatusType {
  RECEIVED = "Received",
  VALIDATED = "Validated",
  RECEIVED_AT_SITE = "Received at Site",
  SENT_TO_KITCHEN = "Sent to kitchen",
  SENT_TO_SITE = "Sent to site",
  FINALISED = "Finalised",
  CANCELLED = "Cancelled",
  FAILED = "Order Failed",
}

export enum OrderFinalisationStatusType {
  ABANDONED = "ABANDONED",
  COMPLETED = "COMPLETED",
  CREATED = "CREATED",
  INPROGRESS = "INPROGRESS",
  FAILED = "FAILED",
  RECEIVED = "RECEIVED",
  DELIVERED = "DELIVERED",
  AWAITING_PICKUP = "AWAITING_PICKUP",
}

export interface CheckoutState {
  error?: ErrorResponse;
  clientTokenResponse: ClientTokenReponse;
  loading: boolean;
  paymentResponse: CheckoutResponse | null;
  paymentSuccess: boolean;
  getClientTokenLoading: boolean;
  isSavePayment: boolean;
  hasUnexpectedError: boolean;
}

export interface OrderCheckoutResponse {
  basket: CheckoutBasket;
  errors: [];
  channelId: number;
  orderTimes: CheckoutOrderTimes[];
  orderNumber: string;
  timeZoneInfo: StoresTimeZone;
  orderId: string;
  pickupTime: string;
  activeOrderUntil: string;
  timeSubmitted: string;
  tableNumber: string | null;
  orderFinalisationStatus: OrderFinalisationStatusType;
  orderFulfilmentStatus: OrderFulfilmentStatusType;
  orderProgressStatus: OrderProgressStatus;
  delivery?: DeliveryOrder;
  tagLookup?: TagLookup[];
}

export interface CheckoutBasket {
  coupons?: { code: string }[];
  tax?: number;
  salesTax: number;
  taxRate: number;
  totalExcl: number;
  totalIncl: number;
  totalBeforeDiscount: number;
  basketItems: CheckoutItems[] | BasketItem[];
  storeInvalidProducts: CheckoutItems[];
  discountAmount?: number;
  discountApplied?: boolean;
  surcharges: Surcharge[];
  basketValue: number;
  total: number;
  subtotal: number;
  validation?: {
    maximum: true;
    minimum: true;
    checkout: true;
  };
  tagLookup?: TagLookup[];
  offers?: unknown[]; // TODO define it if we are using it
}

export interface CheckoutItems {
  id: number;
  name: string;
  price: number;
  quantity: number;
  unitPrice: number;
  addModifiers?: Modifier[];
  extraModifiers?: Modifier[];
  removeModifiers?: Modifier[];
  addFillings?: Modifier[];
  parts?: CheckoutItems[];
  productDiscountsApplied?: ProductDiscountsApplied[];
  posPageNo?: number;
}

export interface CheckoutOrderTimes {
  day: number;
  date: string;
  timeSlots: string[];
}

export interface PaymentCheckoutResponse {
  type: PaymentType;
  amount: number;
}

export interface Address {
  building: string;
  streetAddress: string;
  city: string;
  state: string;
  postcode: string;
  latitude: number;
  longitude: number;
}

export interface StoreCheckoutResponse {
  id: string;
  name: string;
  phoneNumber: string;
  address: Address;
  timezoneInfo: StoresTimeZone;
}

export interface OrderLoyalty {
  actualDollarsBalance: number;
  actualPointsBalance: number;
  cardNumber: string;
  loyaltyRewardAmount: number;
  loyaltyRewardConversionLimit: number;
  accruedPoints: number;
  convertedDollars: number;
  afterCheckoutDollarsBalance: number;
  afterCheckoutPointsBalance: number;
}

export interface CheckoutResponse {
  loyalty: OrderLoyalty;
  order: OrderCheckoutResponse;
  orderCollectionType: CollectionType;
  payment: PaymentCheckoutResponse[];
  store: StoreCheckoutResponse;
}

export interface RecentAndCurrentOrders {
  recentOrders: CheckoutResponse[];
  currentOrders: CheckoutResponse[];
}

export interface PaymentPayload {
  id: string;
  customer: Customer;
  addCutlery: boolean;
  payment: Payment;
  clientPickUpTime: string;
  async?: boolean;
}

export interface Customer {
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber?: string;
}

export interface Payment {
  braintree?: Braintree;
  gygDollar?: GYGdollarPayment;
  coupon?: {
    code: string;
  };
}

export interface GYGdollarPayment {
  cardNumber: string;
  amount: number;
}

export interface Braintree {
  nonce: string;
  amount: string;
  paymentToken: string;
  checkoutType: number;
  bTAFT: string;
  storeCard: boolean;
}

export enum OrderProgressEnum {
  ABANDONED = "ABANDONED",
  COMPLETED = "COMPLETED",
  CREATED = "CREATED",
  INPROGRESS = "INPROGRESS",
  FAILED = "FAILED",
  RECEIVED = "RECEIVED",
  RECEIVEDATSITE = "RECEIVEDATSITE",
  AWAITING_PICKUP = "AWAITING_PICKUP",
  READY = "READY",
}

export interface OrderProgressStatus {
  orderProgressCode: OrderProgressEnum;
  orderProgressDisplayColour: string;
  orderProgressDisplayName: string;
}

export enum OrderFailType {
  TIMEOUT = "TIMEOUT",
  FAILED = "FAILED",
  INPROGRESS = "INPROGRESS",
}

export interface OrderFailModalProps {
  title: string;
  message: string;
  buttonName: string;
  buttonAction: () => void;
}
