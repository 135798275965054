import { CartItem, Modifier } from "../../redux_store/cart/models";
import { BasketItem } from "../../services/api/order/model";
import { productCustomizationList } from "../Products/customizationMapper";

/**
 * Creates updated cart item with new total price based on quantity and unitPrice of original cart item.
 * @param cartItemToUpdate
 * @param quantity
 * @returns
 */
export const getPriceForNewCartItemQuantity = (
  cartItemToUpdate: CartItem,
  quantity: number
): CartItem => {
  const miam = cartItemToUpdate.miamItem
    ? getPriceForNewCartItemQuantity(cartItemToUpdate.miamItem, quantity)
    : undefined;
  const newCartItem: CartItem = {
    ...cartItemToUpdate,
    quantity: quantity,
    price: cartItemToUpdate.unitPrice * quantity,
    miamItem: miam,
  };

  return newCartItem;
};

export const getTotalPriceFromCustomizations = (cartItem: CartItem): number => {
  let total = 0;
  productCustomizationList.forEach((customization) => {
    if (customization !== "removeModifier") {
      const modifiers = cartItem[customization as keyof CartItem];
      if (modifiers) {
        (modifiers as Modifier[]).forEach((modifier) => {
          total += modifier.price;
        });
      }
    }
  });
  return total;
};

/**
 * TO DO: refactor this to calculate corectly price for parts to display in cart button in menu view.
 * @param cartItems
 * @param total
 * @returns
 */
export function calculateItemUnitPrice(
  cartItems: CartItem[],
  total: number
): number {
  for (const item of cartItems) {
    // `item.parts[0].unitPrice` is differentiating between cartItems
    // that are mapped from basketItems (BE) and cartItems created when
    // adding to cart (FE).
    // Background for refactoring: BE is returning multiparts with
    // unitPrice $X and parts with unitPrice $0 when FE is creating
    // multipart cartItem with unitPrice $0 and parts that have unitPrice $X.
    // Calculating modifications is also different.
    if (item.parts && item.parts.length > 0 && item.parts[0].unitPrice) {
      // this will multiple overall total including parts' total times quantity
      // and it's working fine when adding to cart 'cuz multiparts
      // CANNOT be multiplied in menu and `item.quantity` is always 1
      // but it's returning multiplied overall total times quantity (should only
      // multiply parts' total and then add it to overall total) when navigating away
      // from the cart view 'cuz in cart view multiparts CAN be multiplied
      // UPDATE: first condition will calculate correctly MULTIPART cart items created by FE
      total = calculateItemUnitPrice(item.parts, total) * item.quantity;
    } else if (item.parts && item.parts.length) {
      // second condition will calculate MULTIPART cart items created by BE
      total += item.price;
    } else {
      // third condition will calculate correctly all cart items that are not multiparts
      let sub = getTotalPriceFromCustomizations(item);
      sub += item.unitPrice;
      total += sub * item.quantity;
    }
    // calculate MIAM item
    if (item.miamItem) {
      const miamPrice = calculateItemUnitPrice(item.miamItem.parts || [], 0);
      total += miamPrice * item.miamItem.quantity;
    }
  }
  return total;
}

/**
 * Creates updated cart item with new total local price of cart item based on its modifications, parts and quantity.
 * @param cartItem
 * @param quantity
 * @returns
 */
export const getCartItemWithUpdatedQuantity = (
  cartItem: CartItem,
  quantity: number
): CartItem => {
  const updateTotalPrice = calculateItemUnitPrice(
    [{ ...cartItem, quantity: quantity }],
    0
  );
  return {
    ...cartItem,
    quantity: quantity,
    price: updateTotalPrice,
  };
};

export const getTotalPriceFromCartItems = (cartItems: CartItem[]): number => {
  let total = 0;
  for (const item of cartItems) {
    total += item.price;
  }
  return total;
};

export const getTotalPriceFromBasketItems = (
  basketItems: BasketItem[]
): number => {
  let total = 0;
  for (const item of basketItems) {
    total += item.price;
  }
  return total;
};
