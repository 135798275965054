import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sortByDistance } from "../../modules/Store/distanceCalculator";
import {
  getStoreTags,
  StoreCoordinates,
  storesMapper,
} from "../../modules/Store/storeMapper";
import { getStoreById } from "../../modules/Store/utils";
import { ErrorResponse } from "../error/models";
import { AllActionsOf } from "../utils/Actions";
import {
  GetStoreOrderTimeslotsAndOffsetRequestProps,
  GetStoreOrderTimeslotsAndOffsetResponseProps,
  GetStoresSuccessProps,
  GooglePlaceDetailSearch,
  NearestStoreProps,
  SortStoreByDistanceProps,
  Store,
  StoreOpenTime,
  StoreSearchPayload,
  StoreSearchResult,
  StoreState,
} from "./models";

const initialState: StoreState = {
  selectedStoreId: null,
  selectedStore: null,
  stores: [],
  storeTags: [],
  getStoresLoading: false,
  searchResult: { goolePlaceSearchResult: [], fuzzySearchResult: [] },
  storeSearchLocation: null,
  showStoreInfo: null,
  storeOpenTime: {
    isOpen: true,
    tradingTimeInfo: "",
    closingSoon: false,
    openingSoon: false,
  },
  error: undefined,

  storeOrderTimeSlots: {
    storeOrderTimesLoading: false,
    storeOrderTimeSlotsError: undefined,
    storeOrderTimesTimestamp: 0,
    storeOrderTimes: [],
    storeOrderOffset: [],
    storeOrderTimeBasketValue: 0,
    storeAsapTime: null,
    storeTimeZoneInfo: null,
  },
};

const storeSlice = createSlice({
  name: "store",
  initialState: initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    storeSearch(_state, _action: PayloadAction<StoreSearchPayload>) {},
    storeSearchSuccess(state, action: PayloadAction<StoreSearchResult>) {
      state.searchResult = action.payload;
    },
    googlePlaceDetailSearch(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<GooglePlaceDetailSearch>
    ) {},
    googlePlaceDetailSearchSuccess(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<SortStoreByDistanceProps>
    ) {},
    setStoreSearchLocation(
      state,
      action: PayloadAction<StoreCoordinates | null>
    ) {
      state.storeSearchLocation = action.payload;
    },
    setStore(state, action: PayloadAction<Store>) {
      state.selectedStore = {
        ...action.payload,
      };
    },
    cleanStore(state) {
      state.selectedStore = null;
    },
    getStores(state) {
      state.getStoresLoading = true;
    },
    handleApiFailure(state, action: PayloadAction<ErrorResponse>) {
      state.selectedStoreId = null;
      state.getStoresLoading = false;
      state.error = action.payload;
    },
    getStoresSuccess(state, action: PayloadAction<GetStoresSuccessProps>) {
      state.stores = storesMapper(action.payload).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      state.getStoresLoading = false;
      state.storeTags = getStoreTags(state.stores);
      if (state.selectedStoreId) {
        state.selectedStore = getStoreById(state.selectedStoreId, state.stores);
        state.selectedStoreId = null;
      }
      state.error = undefined;
    },
    setStoreOrderTimeslotsAndOffset(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<GetStoreOrderTimeslotsAndOffsetRequestProps>
    ) {
      state.storeOrderTimeSlots.storeOrderTimesLoading = true;
    },
    setStoreOrderTimeslotsAndOffsetSuccess(
      state,
      action: PayloadAction<GetStoreOrderTimeslotsAndOffsetResponseProps>
    ) {
      state.storeOrderTimeSlots.storeOrderTimesLoading = false;
      state.storeOrderTimeSlots.storeOrderTimeSlotsError = undefined;
      state.storeOrderTimeSlots.storeOrderTimes = action.payload.orderTimes;
      state.storeOrderTimeSlots.storeOrderOffset = action.payload.orderOffset;
      state.storeOrderTimeSlots.storeOrderTimeBasketValue =
        action.payload.basketValue;
      state.storeOrderTimeSlots.storeTimeZoneInfo = action.payload.timeZoneInfo;
      state.storeOrderTimeSlots.storeAsapTime = action.payload.asapTime;
      state.storeOrderTimeSlots.storeOrderTimesTimestamp =
        action.payload.timestamp;
    },
    setStoreOrderTimeslotsAndOffsetFailure(
      state,
      action: PayloadAction<ErrorResponse>
    ) {
      state.storeOrderTimeSlots.storeOrderTimesLoading = false;
      state.storeOrderTimeSlots.storeOrderTimeSlotsError = action.payload;
    },
    sortStoresByDistance(
      state,
      action: PayloadAction<SortStoreByDistanceProps>
    ) {
      state.stores = sortByDistance(
        state.stores,
        action.payload,
        action.payload.version
      );
    },
    setNearestStoreAsSelected(state, action: PayloadAction<NearestStoreProps>) {
      if (
        state.stores.length > 0 &&
        action.payload.nearestStoreThresholdKm === 0
      ) {
        state.selectedStore = state.stores[0];
      } else if (
        (state.stores.length > 0 &&
          state.stores[0].distance <=
            action.payload.nearestStoreThresholdKm * 1000) ||
        action.payload.nearestStoreThresholdKm === 0
      ) {
        state.selectedStore = state.stores[0];
      }
    },
    setStoreInfo(state, action: PayloadAction<Store | null>) {
      state.showStoreInfo = action.payload;
    },
    selectStoreById(state, action: PayloadAction<{ storeId: string }>) {
      const storeId = Number(action.payload.storeId);
      if (!state.getStoresLoading) {
        state.selectedStore = getStoreById(storeId, state.stores);
      } else {
        state.selectedStoreId = storeId;
      }
    },
    setStoreOpenTime(state, action: PayloadAction<StoreOpenTime>) {
      state.storeOpenTime = action.payload;
    },
  },
});

const { actions, reducer } = storeSlice;
export type StoreActionsType = AllActionsOf<typeof actions>;
export { initialState, actions as storeActions, reducer as storeReducer };
