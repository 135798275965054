import * as Sentry from "@sentry/react";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";

import useInterval from "gyg_common/hooks/useInterval";
import { RootState } from "gyg_common/redux_store/rootReducer";
import { ScreenParams, Screens } from "navigation/const";
import { createPathWithParam } from "navigation/utils";

import lazyImport from "../modules/lazyRetry";

// Create Custom Sentry Route component
const SentryRoute = !__DEV__ ? Sentry.withSentryRouting(Route) : Route;

const DashboardScreen = lazyImport(
  import(
    /* webpackChunkName: "DashboardContainer" */ "views/containers/Dashboard/DashboardContainer"
  )
);
const OrderScreen = lazyImport(
  import(
    /* webpackChunkName: "OrderContainer" */ "views/containers/Order/OrderContainer"
  )
);
const MenuContainer = lazyImport(
  import(
    /* webpackChunkName: "MenuContainer" */ "views/containers/Menu/MenuContainer"
  )
);

const OrderStatusScreen = lazyImport(
  import(
    /* webpackChunkName: "OrderStatusContainer" */ "@/views/containers/OrderStatus/OrderStatusContainer"
  )
);

const CheckoutScreen = lazyImport(
  import(
    /* webpackChunkName: "GuestDetailContainer" */ "views/containers/GuestDetail/GuestDetailContainer"
  )
);
const PaymentScreen = lazyImport(
  import(
    /* webpackChunkName: "PaymentContainer" */ "views/containers/Payment/PaymentContainer"
  )
);
const RestaurantsContainer = lazyImport(
  import(
    /* webpackChunkName: "RestaurantsContainer" */ "views/containers/Stores/RestaurantsContainer"
  )
);
const LoginScreen = lazyImport(
  /* webpackChunkName: "LoginContainer" */ import(
    "views/containers/Auth/LoginContainer"
  )
);
const RegistrationScreen = lazyImport(
  import(
    /* webpackChunkName: "RegistrationContainer" */ "views/containers/Registration/RegistrationContainer"
  )
);
const UserProfileScreen = lazyImport(
  import(
    /* webpackChunkName: "UserProfileContainer" */ "views/containers/UserManagement/UserProfileContainer"
  )
);
const MyGomexScreen = lazyImport(
  import(
    /* webpackChunkName: "MyGomexContainer" */ "views/containers/Gomex/MyGomexContainer"
  )
);
const SocialScreen = lazyImport(
  import(
    /* webpackChunkName: "SocialContainer" */ "views/containers/Social/SocialContainer"
  )
);
const RecentOrdersScreen = lazyImport(
  import(
    /* webpackChunkName: "RecentOrdersContainer" */ "views/containers/RecentOrders/RecentOrdersContainer"
  )
);
const OrderDetailsScreen = lazyImport(
  import(
    /* webpackChunkName: "OrderDetailsContainer" */ "views/containers/OrderDetails/OrderDetailsContainer"
  )
);
const UpsellScreen = lazyImport(
  import(
    /* webpackChunkName: "UpsellContainer" */ "views/containers/Upsell/UpsellContainer"
  )
);
const NotFoundScreen = lazyImport(
  import(
    /* webpackChunkName: "NotFound" */ "views/components/shared/404/NotFound"
  )
);
const NotificationsScreen = lazyImport(
  import(
    /* webpackChunkName: "NotificationsContainer" */ "views/containers/Notifications/NotificationsContainer"
  )
);
const ForgotPasswordScreen = lazyImport(
  import(
    /* webpackChunkName: "ForgotPasswordContainer" */ "views/containers/ForgotPassword/ForgotPasswordContainer"
  )
);
const ResetPasswordScreen = lazyImport(
  import(
    /* webpackChunkName: "ResetPasswordContainer" */ "views/containers/ResetPassword/ResetPasswordContainer"
  )
);

function Routes() {
  const { hasPendingNavigation, navigationAction } = useSelector(
    (s: RootState) => s.branch
  );

  useInterval(
    () => {
      if (hasPendingNavigation && navigationAction) {
        navigationAction();
      }
    },
    // Delay in milliseconds or null to stop it
    hasPendingNavigation ? 1000 : null
  );

  return (
    <Suspense>
      <Switch>
        <SentryRoute
          path={Screens.Dashboard}
          exact
          component={DashboardScreen}
        />
        <SentryRoute path={Screens.Order} component={OrderScreen} />
        <SentryRoute path={Screens.Menu} component={MenuContainer} />
        <SentryRoute path={Screens.OrderStatus} component={OrderStatusScreen} />

        <SentryRoute path={Screens.Checkout} component={CheckoutScreen} />
        <SentryRoute path={Screens.Payment} component={PaymentScreen} />

        <SentryRoute
          path={Screens.OrderDetails}
          component={OrderDetailsScreen}
        />
        <SentryRoute
          path={createPathWithParam(Screens.Login, ScreenParams.AUTH)}
          component={LoginScreen}
        />
        <SentryRoute
          path={Screens.Registration}
          component={RegistrationScreen}
        />
        <SentryRoute
          path={createPathWithParam(Screens.Restaurants, ScreenParams.ID)}
          component={RestaurantsContainer}
        />
        <SentryRoute path={Screens.UserProfile} component={UserProfileScreen} />
        <SentryRoute path={Screens.MyGomex} component={MyGomexScreen} />
        <SentryRoute
          path={Screens.RecentOrders}
          component={RecentOrdersScreen}
        />
        <SentryRoute
          path={createPathWithParam(Screens.Social, ScreenParams.CODE)}
          component={SocialScreen}
        />
        <SentryRoute path={Screens.Upsell} component={UpsellScreen} />
        <SentryRoute
          path={Screens.Notifications}
          component={NotificationsScreen}
        />
        <SentryRoute
          path={Screens.ForgotPassword}
          component={ForgotPasswordScreen}
        />
        <SentryRoute
          path={Screens.ResetPassword}
          component={ResetPasswordScreen}
        />

        {/** Keep 404 as a last route */}
        <SentryRoute path='*' component={NotFoundScreen} />
      </Switch>
    </Suspense>
  );
}

export default withRouter(Routes);
