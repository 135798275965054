import React from "react";
import { Platform, StyleSheet, View } from "react-native";

import colours from "../../styles/colours";

const styles = StyleSheet.create({
  style: {
    borderBottomColor: colours.lightGrey,
    borderBottomWidth: Platform.OS != "web" ? 1 : StyleSheet.hairlineWidth,
  },
});

const Divider = () => {
  return <View style={styles.style} />;
};
export default Divider;
