import moment from "moment";

import config from "../config";
import { locales } from "../locales/locales.enum";

export const checkBirthdayString = (str: string, max: number): string => {
  if (str.charAt(0) !== "0" || str == "00") {
    let num = parseInt(str);
    if (
      isNaN(num) ||
      num <= 0 ||
      num > max ||
      num.toString().length > max.toString().length
    )
      num = 1;
    str =
      num > parseInt(max.toString().charAt(0)) && num.toString().length == 1
        ? "0" + num
        : num.toString();
  }
  return str;
};

export const birthdayBuilder = (
  initBirthday: string,
  nextString: string,
  region: string
): string => {
  if (initBirthday.length < nextString.length) {
    const expr = new RegExp(/\D\/$/);
    if (expr.test(nextString))
      nextString = nextString.substr(0, nextString.length - 3);
    const values = nextString.split("/").map(function (v) {
      return v.replace(/\D/g, "");
    });
    if (values[0])
      values[0] = checkBirthdayString(
        values[0],
        region === locales.AU ? 31 : 12
      );
    if (values[1])
      values[1] = checkBirthdayString(
        values[1],
        region === locales.AU ? 12 : 31
      );
    const output = values.map(function (v, i) {
      return v.length === 2
        ? i < 2
          ? v + "/"
          : v
        : v.length === 3 && i < 2
          ? v.substring(0, 2) + "/" + v.substring(2, v.length)
          : v;
    });
    return output.join("").substr(0, 14);
  } else {
    return initBirthday.slice(0, initBirthday.length - 1);
  }
};

export const convertDateMonthFirstToDayFirst = (dateStringValue) => {
  const values = dateStringValue.split("/").map(function (v) {
    return v.replace(/\D/g, "");
  });
  if (values.length === 3 && config.version !== locales.AU) {
    return [values[1], values[0], values[2]].join("/");
  }
  return dateStringValue;
};

export const dateFormatter = (dateValue: string): string => {
  if (dateValue == null || dateValue == "" || undefined) {
    return "";
  } else {
    return moment(dateValue).format(
      config.version === locales.US ? "MM/DD/YYYY" : "DD/MM/YYYY"
    );
  }
};

enum DialCode {
  AU = "+61",
  US = "+1",
}

export const parseMobileNumber = (
  mobileNumber: string,
  region: locales
): string => {
  // If the number already starts with '+', we assume it already includes a country code.
  if (mobileNumber.startsWith("+")) {
    return mobileNumber;
  }

  // Determine dial code based on region
  const dialCodeBasedOnRegion =
    region === locales.AU
      ? DialCode.AU // +61
      : DialCode.US; // +1

  // Handle Australian numbers: if they start with "0", remove the leading "0"
  if (region === locales.AU && mobileNumber.charAt(0) === "0") {
    mobileNumber = mobileNumber.substring(1);
  }

  // Return the dial code concatenated with the mobile number
  return dialCodeBasedOnRegion.concat(mobileNumber);
};

export const censorMobile = (mobileNumber: string): string => {
  const lastPart = mobileNumber.substring(7, mobileNumber.length);
  return "XXXX XXX " + lastPart;
};

export const extractPathFromUrl = (url: string): string => {
  const urlElements = url.split("://");
  if (urlElements.length < 2) {
    return "";
  }
  return urlElements[1];
};
