import { locales } from "../../locales/locales.enum";
import { CurrentUser } from "../../redux_store/auth/models";
import { ErrorResponse } from "../../redux_store/error/models";
import { CurrentSocialResponse } from "../../redux_store/social/models";
import { parseMobileNumber } from "../stringBuilders";
import { isInternationalNumber, validatePhone } from "../stringValidators";

export const invalidMobileNumberException = "InvalidMobileNumberException";

/**
 *
 * @param firstName
 * @param lastName
 * @param email
 * @param password
 * @param confirmPassword
 * @param mobile
 * @returns true if everything is filled, false if one of them is empty
 */
export const registrationBasicCheck = (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  confirmPassword: string,
  mobile: string
): boolean => {
  return (
    firstName.length > 0 &&
    lastName.length > 0 &&
    email.length > 0 &&
    password.length > 0 &&
    confirmPassword.length > 0 &&
    mobile.length > 0
  );
};

export const getMobileNumberWithDialCode = (
  mobileNumber: string,
  version: locales
): string => {
  const isInternationNumber = isInternationalNumber(mobileNumber);
  const isNumberValid = validatePhone(mobileNumber, version);
  const mobileNumberWithDialCode = isInternationNumber
    ? mobileNumber
    : parseMobileNumber(mobileNumber, isNumberValid.region || version);

  return mobileNumberWithDialCode;
};

export const mapSocialRegisterResponseToCurrentUser = (
  response: CurrentSocialResponse
): CurrentUser => {
  const user: CurrentUser = {
    firstName: response.firstName,
    lastName: response.lastName,
    phoneNumber: "",
    email: response.email,
    verifiedEmail: response.verifiedEmail,
    verifiedMobileNumber: response.verifiedMobileNumber,
    accessToken: response.accessToken || response.signupProgressToken,
    refreshToken: response.refreshToken || response.signupProgressToken,
    registrationComplete:
      response.registrationComplete ?? response.isExistingAccount
        ? "true"
        : undefined,
    expiry: response.expiry,
    userName: response.userName,
    referralLink: response.referralLink,
    cardNumber: response.cardNumber?.toString(),
  };
  return user;
};

export const isInvalidMobileNumberException = (
  verificationCodeErrorResponse: ErrorResponse
): boolean => {
  return verificationCodeErrorResponse?.title === invalidMobileNumberException;
};

export const getRegistrationError = (
  isVerifyModalShown: boolean,
  isPhoneVerified: boolean,
  verificationCodeErrorResponse: ErrorResponse,
  registrationErrorResponse: ErrorResponse
): ErrorResponse | undefined => {
  //show errors only if verify phone modal not shown
  if (!isVerifyModalShown) {
    //check if verification phone error could be shown, not show invalid mobile number as it is show under text input
    if (
      !isPhoneVerified &&
      verificationCodeErrorResponse &&
      !isInvalidMobileNumberException(verificationCodeErrorResponse)
    ) {
      return verificationCodeErrorResponse;
    } else {
      return registrationErrorResponse;
    }
  } else {
    return null;
  }
};
