import React from "react";
import { Platform, StyleSheet, Text, View } from "react-native";

import SecondaryButton from "gyg_common/components/Buttons/SecondaryButton";
import TertiaryButton from "gyg_common/components/Buttons/TertiaryButton";
import { BorderRadius, Spacing } from "gyg_common/components/styles/number";
import { Typography } from "gyg_common/components/styles/typography";
import { useMediaQuery } from "gyg_common/hooks/useMediaQuery";

import colours from "../styles/colours";

export enum ModalSize {
  default = "70%",
  big = "90%",
  small = "30%",
}

const contentStyles = (isWebDesktop: boolean, size: ModalSize) => {
  return StyleSheet.create({
    content: {
      backgroundColor: "white",
      padding: Spacing.Medium,
      borderRadius: BorderRadius.Medium,
      alignItems: "center",
      justifyContent: "center",
      marginBottom: Spacing.Regular,
      width: isWebDesktop ? size : ModalSize.big,
    },
  });
};

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "rgba(52, 52, 52, 0.8)",
    alignItems: "center",
    justifyContent: "center",
  },
  description: {
    ...Typography.bodyTwo,
    paddingTop: Spacing.Regular,
    textAlign: "center",
  },
  buttonsContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: Spacing.Regular,
    alignItems: "center",
  },
  changeAddressButtonContainer: { flex: 1, paddingRight: Spacing.Regular },
  confirmButtonContainer: { flex: 0.7 },
  singleButtonContainer: { flex: 1 },
});

interface ModalContentProps {
  title: string;
  description: string;
  actionButtonTitle: string;
  secondButtonTitle?: string;
  onActionTap: () => void;
  onSecondActionTap?: () => void;
  size?: ModalSize;
}

const ModalContent: React.FC<ModalContentProps> = ({
  title,
  description,
  actionButtonTitle,
  secondButtonTitle,
  onActionTap,
  onSecondActionTap,
  size = ModalSize.default,
}) => {
  const { isDesktopScreen } = useMediaQuery();
  const isWebDesktop = Platform.OS === "web" && isDesktopScreen;
  return (
    <View style={styles.container}>
      <View style={contentStyles(isWebDesktop, size).content}>
        <Text style={{ ...Typography.titleOne }}>{title.toUpperCase()}</Text>
        <Text style={styles.description}>{description}</Text>
        <View style={styles.buttonsContainer}>
          {secondButtonTitle && actionButtonTitle && (
            <View style={styles.changeAddressButtonContainer}>
              <TertiaryButton
                title={actionButtonTitle.toUpperCase()}
                onClick={onActionTap}
              />
            </View>
          )}
          {secondButtonTitle && onSecondActionTap && (
            <View style={styles.confirmButtonContainer}>
              <SecondaryButton
                buttonName={secondButtonTitle.toUpperCase()}
                onClick={onSecondActionTap}
              />
            </View>
          )}

          {!secondButtonTitle && actionButtonTitle && (
            <View style={styles.singleButtonContainer}>
              <SecondaryButton
                buttonName={actionButtonTitle.toUpperCase()}
                onClick={onActionTap}
                buttonColor={colours.yellow}
              />
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default ModalContent;
