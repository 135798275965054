import React from "react";
import { FlatList, View } from "react-native";

import {
  OptionDataItemProps,
  SimpleCustomisableProductRadioButton,
} from "../../../modules/Products/model";
import GradientLine from "../../GradientLine";
import RadioItem from "./RadioItem";

export interface RadioButtonGroupData<T> {
  element: T;
  disabled?: boolean;
}

export interface RadioButtonGroupProps<T> {
  renderItem: (el: T) => React.ReactElement;
  onItemSelection: (id: number | string) => void;
  data: RadioButtonGroupData<T>[];
  selectedId?: number | string;
  showGradientLine?: boolean;
}

/**
 * Custom radio button.
 * @param props data, item, showGradientLine, onItemSelection, selectedId
 * @returns list of radio buttons with customizable label.
 */
const RadioButtonGroup = <
  T extends OptionDataItemProps | SimpleCustomisableProductRadioButton,
>({
  data,
  renderItem,
  showGradientLine,
  onItemSelection,
  selectedId,
}: RadioButtonGroupProps<T>) => {
  const validateSelection = (id: number | string) => {
    onItemSelection(id);
  };

  return (
    <FlatList
      data={data}
      keyExtractor={(item) => `${item.element.id}`}
      renderItem={({ item, index }) => (
        <View key={item.element.id}>
          <RadioItem
            key={item.element.id}
            disabled={item.disabled}
            id={item.element.id}
            sentry-label={`RadioItem-${item.element.id}`}
            selected={item.element.id === selectedId}
            selectedOption={validateSelection}>
            {renderItem(item.element)}
          </RadioItem>
          {showGradientLine && index !== data.length - 1 && <GradientLine />}
        </View>
      )}
    />
  );
};

export default RadioButtonGroup;
