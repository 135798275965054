// PersistorUtils.ts

import { Persistor } from "redux-persist";

let PersistorInstance: Persistor;
export function setUpPersistorInstance(newPersistor: Persistor) {
  PersistorInstance = newPersistor;
}

/**
 * Clears the persisted state stored in Redux Persist.
 * @param persistor The Redux Persist persistor object.
 * @returns A Promise that resolves when the persisted state has been cleared.
 */
export const clearPersistedState = async (): Promise<void> => {
  if (PersistorInstance) {
    try {
      await PersistorInstance.purge();
      console.log("Persisted state has been cleared.");
    } catch (error) {
      console.error("Failed to clear persisted state:", error);
    }
  }
};
