import { ImageSourcePropType } from "react-native";

import * as CheckoutModels from "../../redux_store/checkout/model";
import cardImg from "../assets/icons/payment-icons/icon_payment_3.png";
import GYGdollarIcon from "../assets/icons/payment-icons/icon_payment_gyg_dollar.png";
import rewardsIcon from "../assets/icons/payment-icons/icon_payment_reward.png";

export interface PaymentIcon {
  id: CheckoutModels.PaymentType;
  url: ImageSourcePropType;
  copy: string;
}

// note: added ImageSourcePropType due to .png type mismatch
export const paymentTypeInfo: PaymentIcon[] = [
  {
    id: CheckoutModels.PaymentType.GYGDOLLAR,
    url: GYGdollarIcon as ImageSourcePropType,
    copy: "GYG Dollars",
  },
  {
    id: CheckoutModels.PaymentType.COUPON,
    url: rewardsIcon as ImageSourcePropType,
    copy: "Rewards",
  },
  {
    id: CheckoutModels.PaymentType.GIFTCARD,
    url: rewardsIcon as ImageSourcePropType,
    copy: "Rewards",
  },
  {
    id: CheckoutModels.PaymentType.CASH,
    url: cardImg as ImageSourcePropType,
    copy: "Cash",
  },
  {
    id: CheckoutModels.PaymentType.BRAINTREE,
    url: cardImg as ImageSourcePropType,
    copy: "Credit/Debit Card",
  },
];
