import branch from "branch-sdk";
import { batch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AnyAction, Dispatch } from "redux";
import { store } from "redux_store/configureReduxStore";

import {
  BranchConst,
  BranchReduxActions,
  BranchReduxModel,
  CartReduxAction,
  CartReduxModels,
  CheckoutAction,
  CheckoutModels,
  NavigationReduxAction,
  OrderModules,
  OrderReduxAction,
  OrderReduxModels,
  PromoReduxAction,
  RootState,
  SentryLoggerInstance,
  StoreReduxAction,
} from "gyg_common";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";
import { storeAsapTimeSelector } from "gyg_common/redux_store/store/selectors";
import { gb } from "gyg_common/utils/growthbook";
import { getCurrentLocationPermission } from "modules/location";
import { Screens } from "navigation/const";
import { clearAndNavigateToOrderStatusScreen } from "navigation/utils";

export const handleBranchDeeplink =
  (history: ReturnType<typeof useHistory>, dispatch: Dispatch<AnyAction>) =>
  (error: branch.BranchError, data: branch.SessionData | null) => {
    const state: RootState = store.getState();

    const { stores, selectedStore } = state.store;

    const storeAsapTime: Date = storeAsapTimeSelector(state);

    const { isAuthSuccess } = state.login;

    const { userConfirmedOrderSetup } = state.order;
    const guestOrders = state.guest.orders;
    if (error) {
      SentryLoggerInstance.sentryCaptureCustomError("Branch init error", error);
    }

    const deepLinkInfo = data?.data_parsed as Record<string, string>;

    const hasDelivery = gb.getFeatureValue(FeatureFlags.IN_APP_DELIVERY, false);

    if (deepLinkInfo?.path) {
      switch (deepLinkInfo.path) {
        case BranchConst.BranchPaths.Promo:
          const storeOfferId = deepLinkInfo.storeOfferId;

          if (storeOfferId) {
            dispatch(
              BranchReduxActions.setPendingAction(() => {
                // we use reduxStore here because this callback is outside React scope
                const state = store.getState();
                const { availablePromos, storeOffers } = state.promo;

                // dispatch display of promotions modal
                // only after the required data is loaded
                if (availablePromos.length > 0 && storeOffers.length > 0) {
                  batch(() => {
                    store.dispatch(
                      PromoReduxAction.setStoreOfferIdSelected({
                        storeOfferId,
                        clearCurrentPromoSelected: true,
                      })
                    );
                    store.dispatch(
                      PromoReduxAction.setShowPromotionsModal({
                        isPromotionsModalVisible: true,
                      })
                    );
                    // clear interval check
                    store.dispatch(BranchReduxActions.clearPendingAction());
                  });
                }
              })
            );
          }
          break;
        case BranchConst.BranchPaths.Restaurant:
          break;
        case BranchConst.BranchPaths.Menu:
          let storeId = deepLinkInfo.storeId;
          if (!storeId && selectedStore && !hasDelivery) {
            storeId = selectedStore.id.toString();
          }
          const menuBranchState: BranchReduxModel.BranchState = {
            section: deepLinkInfo.section as string,
            storeId: deepLinkInfo.storeId as string,
            categoryId: Number(deepLinkInfo.cat),
            tableServiceError: false,
          };

          store.dispatch(BranchReduxActions.setBranchState(menuBranchState));

          if (storeId) {
            store.dispatch(
              StoreReduxAction.selectStoreById({ storeId: storeId })
            );
            store.dispatch(OrderReduxAction.confirmOrderSetup(true));
            history.push(Screens.Menu);
          } else {
            getCurrentLocationPermission()
              .then((permission) => {
                if (permission === "granted" || hasDelivery) {
                  store.dispatch(NavigationReduxAction.setOpenOrderSetup(true));
                } else {
                  history.push(Screens.Restaurants);
                }
              })
              .catch(() => {
                if (hasDelivery) {
                  store.dispatch(NavigationReduxAction.setOpenOrderSetup(true));
                } else {
                  history.push(Screens.Restaurants);
                }
              });
          }
          break;
        case BranchConst.BranchPaths.Cart:
          // Add the product to branch state, so upon cart opening we can add the product
          const productItem = deepLinkInfo.productItem as string;
          if (productItem) {
            const branchCartItem: CartReduxModels.CartItem =
              JSON.parse(productItem);
            if (branchCartItem) {
              store.dispatch(
                CartReduxAction.addItemToCart({
                  cartItems: [branchCartItem],
                })
              );
              store.dispatch(CartReduxAction.setShouldUpdateOrder(true));
            }
          }

          // if there is a store id, confirm setup, and go to cart
          const cartStoreId = deepLinkInfo.storeId as string;
          if (cartStoreId) {
            const storeData = stores.find(
              (store) => store.id === parseInt(cartStoreId)
            );
            if (storeData) {
              store.dispatch(StoreReduxAction.setStore(storeData));
              store.dispatch(
                OrderReduxAction.setupOrder({
                  orderASAP: true,
                  orderTime: null,
                  orderCollectionType: OrderReduxModels.CollectionType.PICK_UP,
                })
              );
              store.dispatch(OrderReduxAction.confirmOrderSetup(true));
              // navigate to cart
              history.push(Screens.Order);
            }
          } else {
            if (userConfirmedOrderSetup) {
              history.push(Screens.Order);
            }
          }
          return;
        case BranchConst.BranchPaths.OrderSetup:
          const tableServiceStoreId = deepLinkInfo.storeId as string;
          const tableNumber = deepLinkInfo.tableNumber as string;

          if (tableServiceStoreId && tableNumber) {
            const storeData = stores.find(
              (store) => store.id === parseInt(tableServiceStoreId)
            );

            if (storeData) {
              const asapTime = storeAsapTime.getTime();

              store.dispatch(StoreReduxAction.setStore(storeData));
              store.dispatch(
                OrderReduxAction.setupOrder({
                  orderASAP: true,
                  orderTime: asapTime.valueOf(),
                  orderCollectionType:
                    OrderReduxModels.CollectionType.TABLE_SERVICE,
                  tableNumber: tableNumber,
                })
              );

              store.dispatch(
                BranchReduxActions.setBranchState({
                  storeId: tableServiceStoreId,
                  tableServiceError: false,
                })
              );
              store.dispatch(NavigationReduxAction.setOpenOrderSetup(true));
            } else {
              store.dispatch(
                BranchReduxActions.setBranchState({
                  tableServiceError: true,
                })
              );
            }
          }
          break;
        case BranchConst.BranchPaths.OrderStatus:
          const orderId = deepLinkInfo.orderId as string;
          if (orderId) {
            if (isAuthSuccess) {
              store.dispatch(BranchReduxActions.setRequestedOrderData());
              // requestedOrderData.current = true;
              store.dispatch(OrderReduxAction.getOrderById(orderId));
            } else {
              const order = guestOrders.find(
                (o: CheckoutModels.CheckoutResponse) =>
                  o.order.orderId === orderId
              );
              if (order) {
                store.dispatch(CheckoutAction.setPaymentResponse(order));
                store.dispatch(
                  OrderReduxAction.getOrderStatus({
                    orderId: orderId,
                  })
                );
                if (
                  OrderModules.OrderUtils.isOlderThanActiveOrderThreshold(order)
                ) {
                  history.push(Screens.OrderDetails);
                } else {
                  clearAndNavigateToOrderStatusScreen(history, dispatch);
                }
              }
            }
          }
          break;
        case BranchConst.BranchPaths.Notifications:
          history.push(Screens.Notifications);
          break;
        case BranchConst.BranchPaths.PersonalDetails:
          if (isAuthSuccess) {
            history.push(Screens.UserProfile);
          } else {
            history.push(Screens.Login, {
              loginNavigatesTo: Screens.UserProfile,
            });
          }
          break;
        case BranchConst.BranchPaths.RecentOrders:
          history.push(Screens.RecentOrders);
          break;
        default:
          break;
      }
    }
  };
