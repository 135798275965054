import moment from "moment-timezone";

import { MenuSection } from "../../redux_store/menu/models";
import { getDayOfMenuSectionTimePeriod } from "./utils";

export const CalculateMenuSectionEnabledByOrderTime = (
  menuSection: MenuSection[],
  orderDate: Date,
  timeZone: string | undefined
): number[] => {
  const orderTime = timeZone
    ? moment(orderDate).tz(timeZone).format("HH:mm")
    : moment(orderDate).format("HH:mm");
  const sectionsEnabled: number[] = [];

  for (let i = 0; i < menuSection.length; i++) {
    const dayMenuTimePeriod = getDayOfMenuSectionTimePeriod(
      menuSection[i],
      moment(orderDate).format("dddd").toLowerCase()
    );

    //check if store is open
    if (dayMenuTimePeriod) {
      dayMenuTimePeriod.map((period) => {
        if (
          orderTime >= period.openTime &&
          orderTime <= period.endTime &&
          sectionsEnabled.length === 0 //Only one section (Breakfast or Main-Menu) can be enabled at the same time
        ) {
          sectionsEnabled.push(i);
        }
      });
    }
  }

  //if store is closed, selects section that has trading hours
  if (sectionsEnabled.length === 0) {
    menuSection.map((section, index) => {
      if (section?.tradingHours?.length) {
        sectionsEnabled.push(index);
      }
    });
  }

  return sectionsEnabled;
};
