import React, { createRef, RefObject, useEffect } from "react";
import {
  Image,
  ImageSourcePropType,
  Platform,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";

import * as PlatformUtils from "../modules/platformUtils";
import clearIcon from "./assets/icons/icon_clear_small.png";
import searchIcon from "./assets/icons/icon_search.png";
import colours from "./styles/colours";
import { BorderRadius, Spacing } from "./styles/number";
import { Typography } from "./styles/typography";

const styles = (isFocused: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      borderWidth: 2,
      borderColor: isFocused ? colours.black : colours.lightGrey,
      borderRadius: BorderRadius.Medium,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      padding: Spacing.ExtraLight,
    },
    textInput: {
      flex: 1,
      marginLeft: Spacing.Narrow,
      ...Typography.bodyBold,
      lineHeight: 16,
      padding: 0,
    },
    placeHolderText: {
      flex: 1,
      marginLeft: Spacing.Narrow,
      ...Typography.body,
      lineHeight: 16,
      padding: 0,
    },
    searchIcon: {
      width: 24,
      height: 24,
      resizeMode: "cover",
      tintColor: isFocused ? colours.black : colours.uiGrey,
    },
    clearIcon: {
      width: 24,
      height: 24,
      resizeMode: "cover",
      tintColor: colours.uiGrey,
    },
  });

export interface SearchTextInputProps {
  value: string;
  onChangeText: (text: string) => void;
  isFocused: boolean;
  setFocused: (focus: boolean) => void;
  onClear: () => void;
  onSearch: () => void;
  placeHolder?: string;
}
export default function SearchTextInput(props: SearchTextInputProps) {
  const {
    setFocused,
    isFocused,
    onChangeText,
    value,
    onClear,
    onSearch,
    placeHolder,
  } = props;
  const inputRef: RefObject<TextInput> = createRef();
  useEffect(() => {
    if (!isFocused) {
      inputRef?.current?.blur();
    } else {
      inputRef?.current?.focus();
    }
  }, [isFocused, inputRef]);

  return (
    <View style={styles(isFocused).container}>
      <TouchableOpacity
        {...PlatformUtils.generateTestID(Platform.OS, `SearchStoreSearchBtn`)}
        onPress={onSearch}>
        <Image
          style={styles(isFocused).searchIcon}
          source={searchIcon as ImageSourcePropType}
        />
      </TouchableOpacity>
      <TextInput
        {...PlatformUtils.generateTestID(Platform.OS, `SearchStoreInput`)}
        ref={inputRef}
        value={value}
        onChangeText={onChangeText}
        style={
          value === ""
            ? styles(isFocused).placeHolderText
            : styles(isFocused).textInput
        }
        placeholderTextColor={colours.midGrey}
        onFocus={() => setFocused(true)}
        onBlur={() => (Platform.OS != "web" ? setFocused(false) : () => {})}
        placeholder={placeHolder}
      />
      {isFocused && (
        <TouchableOpacity
          hitSlop={{ top: 7, left: 7, right: 7, bottom: 7 }}
          {...PlatformUtils.generateTestID(Platform.OS, `SearchStoreClearBtn`)}
          onPress={onClear}>
          <Image
            style={styles(isFocused).clearIcon}
            source={clearIcon as ImageSourcePropType}
          />
        </TouchableOpacity>
      )}
    </View>
  );
}
