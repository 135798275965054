import { RootState } from "redux_store/rootReducer";
import { createSelector } from "reselect";

const TIME_MAX = new Date("2100 01 01");

export const storeAsapTimeSelector = createSelector(
  (state: RootState) => state.store.storeOrderTimeSlots,
  ({ storeAsapTime, storeTimeZoneInfo }) => {
    if (!storeAsapTime || !storeTimeZoneInfo) {
      return TIME_MAX;
    }

    return storeAsapTime;
  }
);
