import { locales } from "../../locales/locales.enum";
import { Store, StoreTag } from "../../redux_store/store/models";
import {
  StateAU,
  StateUS,
  StoreResponse,
} from "../../services/api/store/model";
import { StoreTagLabel } from "./models";
import { sortStringByAlphabetically } from "./storeFilter";

export interface StoreGroupByState {
  [key: string]: Store[];
}
export const STAY_TUNE_THRESHOLD = 3;
export const DEFAULT_STATE_ORDER_AU = [
  "NSW",
  "QLD",
  "VIC",
  "WA",
  "SA",
  "ACT",
  "NT",
];

export interface StoreCoordinates {
  longitude: number;
  latitude: number;
}
export const storesMapper = (stores: StoreResponse[]): Store[] => {
  return stores.map((store: StoreResponse) => {
    const storeTags: StoreTag[] = store.tags
      ? store.tags.map((tag): StoreTag => {
          return { tag: tag.tag.toLowerCase() } as StoreTag;
        })
      : [];
    return {
      id: store.id,
      name: store.name,
      address1: store.address1,
      address2: store.address2,
      city: store.city,
      postCode: store.postCode,
      state: store.state as StateAU | StateUS,
      longitude: store.longitude?.toString(),
      latitude: store.latitude?.toString(),
      tradingHours: store?.tradingHours ?? [],
      phone: store.phone,
      fax: store.fax,
      email: store.email,
      trafficVolume: store.trafficVolume,
      disableStoreOrder: store.disableStoreOrder,
      formattedStoreName: store.formattedStoreName,
      tags: storeTags,
      timeZoneInfo: store.timeZoneInfo,
      surcharges: store.surcharges,
    };
  });
};

export const groupByState = (stores: Store[]): StoreGroupByState => {
  const result: StoreGroupByState = {};
  stores.forEach((store) => {
    if (result[store.state]) {
      result[store.state].push(store);
    } else {
      result[store.state] = [store];
    }
  });
  return result;
};

/**
 * Sort State Accroding to Stores, APP Version and Location
 * @param props coordinates, version, states, stores
 * @returns list of sorted States
 */
export const sortStates = (
  coordinates: StoreCoordinates | null,
  version: locales,
  states: string[],
  stores: Store[]
): string[] => {
  if (coordinates) {
    const result = sortStringByAlphabetically(states);
    const index = states.findIndex((state) => state === stores[0].state);
    if (index != -1) {
      result.splice(index, 1);
    }
    if (stores[0]) {
      return [stores[0].state, ...result] as string[];
    } else {
      return result;
    }
  } else {
    if (version == locales.AU) {
      return states.sort((a, b) => {
        return (
          DEFAULT_STATE_ORDER_AU.lastIndexOf(a) -
          DEFAULT_STATE_ORDER_AU.lastIndexOf(b)
        );
      });
    } else {
      return sortStringByAlphabetically(states);
    }
  }
};

export const getStoreTags = (stores: Store[]): StoreTag[] => {
  const allTags: StoreTag[] = [{ tag: StoreTagLabel.OPEN_NOW }];
  stores.map((store: Store) => {
    store.tags.map((tag: StoreTag) => {
      if (
        allTags.find((existingTag) => existingTag.tag === tag.tag) === undefined
      ) {
        allTags.push(tag);
      }
    });
  });
  return allTags;
};
