import { AnalyticsState } from "../../redux_store/analytics/models";
import { AnalyticsType } from "./model";

export const storePayload = (
  toPickup: boolean,
  state: AnalyticsState
): AnalyticsType => {
  const defaultPayload = {
    product_id: state.productId,
    product_name: state.productName,
    product_quantity: state.productQuantity,
    store_name: state.storeName,
  };
  if (toPickup) {
    return {
      ...defaultPayload,
      store_id: state.storeId,
    };
  }
  return defaultPayload;
};
