import "./style.scss";

import React, { useRef, useState } from "react";

import backIcon from "gyg_common/components/assets/icons/icon_chevron_left.png";
import closeIcon from "gyg_common/components/assets/icons/icon_close.svg";
import IconButton from "gyg_common/components/IconButton";
import MiddleModal, {
  WebModalSize,
} from "gyg_common/components/modals/MiddleModal";
import OrderSetupContent, {
  OrderSetupContentForWardRef,
  OrderSetupProps,
  SetupScreen,
} from "gyg_common/components/OrderSetup/OrderSetupFlowContent";
import { ModalState } from "gyg_common/redux_store/order/models";

import DeliveryAddress from "./DeliveryAddress";
import DeliveryInfo from "./DeliveryInfo";
import DeliveryOrderSetup from "./DeliveryOrderSetup";
import DeliveryRestaurant from "./DeliveryRestaurant";
import OrderCollectionCategory from "./OrderCollectionCategory";
import PickupOrderSetup from "./PickupOrderSetup";
import PickupRestaurant from "./PickupRestaurant";
import PickupRestaurantConfirm from "./PickupRestaurantConfirm";

export const OrderSetup: React.FC<OrderSetupProps> = ({
  isVisible,
  store,
  onCloseModal,
  onSubmitDelivery,
  onSubmitPickup,
  // Start screen
}) => {
  const [CurrentScreen, setCurrentScreen] =
    useState<SetupScreen<ModalState> | null>(null);
  const orderSetupRef = useRef<OrderSetupContentForWardRef>(null);

  const onDismissModal = () => {
    if (orderSetupRef.current) {
      orderSetupRef.current?.onDismissModal();
    }
  };

  const onBackPress = () => {
    if (orderSetupRef.current) {
      orderSetupRef.current?.onBackPress();
    }
  };

  return (
    <MiddleModal
      onDismiss={onDismissModal}
      isVisible={isVisible!}
      testId='order-setup'
      webModalSize={WebModalSize.AUTO}
      scrollViewContainer={false}>
      <div className='order-setup'>
        <div className='order-setup__header'>
          <div>
            {CurrentScreen?.hasBack && (
              <IconButton
                testID={"CustomizableProductGoBackButton"}
                onPress={onBackPress}
                iconImage={backIcon}
                withShadow={false}
              />
            )}
          </div>

          <div className='order-setup__main_title'>{CurrentScreen?.title}</div>
          <IconButton
            testID='order-setup-close-icon'
            withShadow={false}
            onPress={onCloseModal}
            iconImage={closeIcon}
          />
        </div>
        <OrderSetupContent
          ref={orderSetupRef}
          store={store}
          OrderCollectionCategory={OrderCollectionCategory}
          DeliveryAddress={DeliveryAddress}
          DeliveryOrderSetup={DeliveryOrderSetup}
          DeliveryInfo={DeliveryInfo}
          DeliveryRestaurant={DeliveryRestaurant}
          PickupOrderSetup={PickupOrderSetup}
          PickupRestaurant={PickupRestaurant}
          PickupRestaurantConfirm={PickupRestaurantConfirm}
          isVisible={isVisible}
          onCloseModal={onCloseModal}
          onSubmitDelivery={onSubmitDelivery}
          onSubmitPickup={onSubmitPickup}
          setCurrentScreen={setCurrentScreen}
        />
      </div>
    </MiddleModal>
  );
};
