import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "gyg_common";
import FeatureFlag from "gyg_common/components/FeatureFlag";
import commonConfig from "gyg_common/config";

interface FeatureFlagContainerProps {
  children: React.ReactNode;
}

/**
 * Gets all data that are needed at any initial view's load.
 * For example: stores and user authentication.
 */
const FeatureFlagContainer: React.FC<FeatureFlagContainerProps> = (props) => {
  const { profile, location } = useSelector((state: RootState) => state.user);
  const { currentUser } = useSelector((state: RootState) => state.login);
  const { selectedStore } = useSelector((s: RootState) => s.store);
  return (
    <FeatureFlag
      id={profile?.id}
      sessionId={commonConfig.sessionId}
      email={currentUser?.email ?? profile?.email}
      phoneNumber={currentUser?.phoneNumber ?? profile?.phoneNumber}
      storeId={selectedStore?.id}
      latitude={location?.latitude}
      longitude={location?.longitude}>
      {props.children}
    </FeatureFlag>
  );
};

export default FeatureFlagContainer;
