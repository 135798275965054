import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";

import { StoreModules, StoreReduxModels } from "gyg_common";
import Divider from "gyg_common/components/BaseComponents/Divider";
import SecondaryButton from "gyg_common/components/Buttons/SecondaryButton";
import {
  StoreDeliveryInfo,
  StoreHead,
  StoreStatus,
} from "gyg_common/components/StoreDetails";
import { Spacing } from "gyg_common/components/styles/number";

import { DeliveryAddress } from "../../redux_store/order/models";
import styles from "./BasicStoreDetails.styles";

export interface SearchResult {
  id: string;
  closingTime: string;
  deliveryDuration: {
    min: string | number;
    max: string | number;
  };
  deliveryFee: number;
  address: DeliveryAddress;
  name: string;
  open: boolean;
  quoteId: string;
  distance: string;
}

export interface SearchResultWithStore extends SearchResult {
  store: StoreReduxModels.Store;
}

export interface DeliveryStoreDetailsProps {
  searchResult: SearchResultWithStore;
  onSelectStore: (store: StoreReduxModels.Store) => void;
  isLast?: boolean;
}

const DeliveryStoreDetails: React.FC<DeliveryStoreDetailsProps> = ({
  searchResult,
  onSelectStore,
  isLast,
}) => {
  const { store } = searchResult;
  const storeOpenStatus: StoreReduxModels.StoreOpenTime =
    StoreModules.StoreUtils.checkStoreOpenHours(
      store?.tradingHours ?? [],
      store.timeZoneInfo.storeTimeZone
    );
  const { t } = useTranslation();

  return (
    <View>
      <View style={[styles.details, { paddingHorizontal: Spacing.Regular }]}>
        <StoreHead store={store} showInfoBtn={false} />

        <View style={styles.detailsRow}>
          <View style={styles.storeInfo}>
            <StoreStatus {...storeOpenStatus} />
            <StoreDeliveryInfo basicInfo={false} searchResult={searchResult} />
          </View>
          <View style={styles.btnWrapper}>
            <SecondaryButton
              onClick={() => onSelectStore(store)}
              buttonName={t("OrderManagement:selectStore").toUpperCase()}
            />
          </View>
        </View>
      </View>
      {!isLast && <Divider />}
    </View>
  );
};

export default DeliveryStoreDetails;
