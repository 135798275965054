// navigateToOrderStatus.ts
import { History } from "history";
import { Dispatch } from "redux";

import { OrderReduxAction } from "gyg_common";

import { ScreenParams, Screens } from "./const";

/**
 * Creates path with optional parameter.
 * @param basePath defined screen path
 * @param parameter
 * @returns full route path
 */
export const createPathWithParam = (
  basePath: Screens,
  parameter: ScreenParams
): string => {
  return `${basePath}/:${parameter}?`;
};

export const clearAndNavigateToOrderStatusScreen = (
  history: History,
  dispatch: Dispatch,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: any
) => {
  dispatch(OrderReduxAction.clearOrderStatus());
  history.push(Screens.OrderStatus, {
    ...params,
  });
};
