import { CartItem, Modifier } from "../../redux_store/cart/models";
import {
  AddFavouriteRequest,
  FavouriteBasketItem,
  UpdateFavouriteRequest,
} from "../../redux_store/favourite/models";

export const getModifierIds = (modifiers: Modifier[] | undefined): number[] => {
  if (!modifiers) {
    return [];
  }
  return modifiers.map((modifier) => modifier.id);
};

const mapCartItemToFavouriteBasketItem = (
  cartItem: CartItem
): FavouriteBasketItem => {
  return {
    id: cartItem.productId,
    name: cartItem.name,
    miamGUID: cartItem.miamGUID,
    quantity: cartItem.quantity,
    addFillings: getModifierIds(cartItem.addFilling),
    addModifiers: getModifierIds(cartItem.addModifier),
    extraModifiers: getModifierIds(cartItem.extraModifier),
    removeModifiers: getModifierIds(cartItem.removeModifier),
  };
};

export const updateFavouritePayload = (
  favouriteId: number,
  label: string,
  cartItem: CartItem
): UpdateFavouriteRequest => {
  const basketItems = [
    {
      ...mapCartItemToFavouriteBasketItem(cartItem),
      parts: cartItem.parts?.map((part: CartItem) =>
        mapCartItemToFavouriteBasketItem(part)
      ),
    },
  ];

  if (cartItem.miamItem) {
    basketItems.push({
      ...mapCartItemToFavouriteBasketItem(cartItem.miamItem),
      parts: cartItem.miamItem?.parts?.map((part: CartItem) =>
        mapCartItemToFavouriteBasketItem(part)
      ),
    });
  }

  return {
    favouriteId,
    label,
    basketItems,
  };
};

export const addFavouritePayload = (
  cartItem: CartItem,
  name?: string
): AddFavouriteRequest => {
  const basketItems = [
    {
      ...mapCartItemToFavouriteBasketItem(cartItem),
      parts: cartItem.parts?.map((part: CartItem) =>
        mapCartItemToFavouriteBasketItem(part)
      ),
    },
  ];

  if (cartItem.miamItem) {
    basketItems.push({
      ...mapCartItemToFavouriteBasketItem(cartItem.miamItem),
      parts: cartItem.miamItem?.parts?.map((part: CartItem) =>
        mapCartItemToFavouriteBasketItem(part)
      ),
    });
  }

  return {
    label: name ?? cartItem.labelForFavourite ?? cartItem.name,
    basketItems,
  };
};
