import React from "react";
import {
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from "react-native";

import { generateTestID } from "../../modules/platformUtils";
import { OptionDataItemProps } from "../../modules/Products/model";
import { Checkbox } from "../Checkbox";
import GradientLine from "../GradientLine";
import { Spacing } from "../styles/number";

interface Props {
  element: OptionDataItemProps;
  validateSelection: (id: number | string, selected: boolean) => void;
  renderItem: (el: OptionDataItemProps) => React.ReactElement;
  disabled: boolean;
  selected: boolean;
  isRemoveSection?: boolean;
  showGradientLine?: boolean;
}

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    flex: 1,
  },
  checkbox: {
    margin: Spacing.Light,
  },
});

const GroupItem: React.FC<Props> = ({
  validateSelection,
  element,
  selected,
  disabled,
  renderItem,
  isRemoveSection,
  showGradientLine,
}) => {
  const onPress = () => {
    validateSelection(element.id, selected);
  };

  return (
    <View>
      <TouchableWithoutFeedback
        {...generateTestID(Platform.OS, `${element.id}CheckboxItem`)}
        sentry-label={`Checkbox-${element.id}${
          element.name ? `-${element.name}` : ""
        }`}
        disabled={disabled}
        onPress={onPress}>
        <View style={styles.itemContainer}>
          <View style={styles.label}>{renderItem(element)}</View>
          <View style={styles.checkbox}>
            <Checkbox
              disabled={disabled}
              selected={selected}
              customImage={
                isRemoveSection
                  ? require("../assets/icons/icon_close.png")
                  : undefined
              }
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
      {showGradientLine && <GradientLine />}
    </View>
  );
};

export default React.memo(GroupItem);
