import React from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";

import errorImage from "gyg_common/components/assets/images/illustration_error.png";
import ModalWithButton from "gyg_common/components/modals/ModalWithButton";

interface AbandonOrderModalProps {
  isModalVisible: boolean;
  isDelivery: boolean;
  abandonOrder: () => void;
  keepCart: () => void;
  onModalClose: () => void;
}

export const enableAbandonOrderModal = false;

export const AbandonOrderModal: React.FC<AbandonOrderModalProps> = ({
  isModalVisible,
  isDelivery,
  abandonOrder,
  keepCart,
  onModalClose,
}) => {
  const { t } = useTranslation();

  const getOrderTypeText = (delivery: boolean) => {
    return delivery
      ? t("OrderManagement:delivery").toUpperCase()
      : t("OrderManagement:pickup").toUpperCase();
  };

  return (
    <ModalWithButton
      isVisible={isModalVisible}
      title={t("CheckoutPayment:abandonTitle")}
      message={t("CheckoutPayment:abandonDeliveryLabel", {
        orderType: getOrderTypeText(isDelivery),
        newOrderType: getOrderTypeText(!isDelivery),
      })}
      primaryButton={{
        name: t("CheckoutPayment:abandonDelButton").toUpperCase(),
        action: abandonOrder,
      }}
      secondaryButton={{
        name: t("CheckoutPayment:continueButton"),
        action: keepCart,
      }}
      onModalClose={() => onModalClose()}
      image={errorImage as ImageSourcePropType}
    />
  );
};
