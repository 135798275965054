import { AnalyticsInstance } from "../../analytics";
import { locales } from "../../locales/locales.enum";
import orderManagementAU from "../../locales/OrderManagement/au.json";
import orderManagementUS from "../../locales/OrderManagement/us.json";
import * as AnalyticsConst from "../../modules/Analytics/const";
import { UpsellSource } from "../../modules/Upsell/model";
import { CartItem } from "../../redux_store/cart/models";
import { FavouriteBasketItem } from "../../redux_store/favourite/models";
import { MenuStructure } from "../../redux_store/menu/models";
import { getModifierIds } from "../Favourite/favouritePayload";
import { mapFavouriteBasketItemToCart } from "../Favourite/getFavouriteProducts";

const mapPartToFavourite = (items: CartItem[]): FavouriteBasketItem[] => {
  const favouriteBasketItem: FavouriteBasketItem[] = [];
  for (const item of items) {
    favouriteBasketItem.push({
      id: item.productId,
      name: item.name,
      quantity: item.quantity,
      price: item.price || 0,
      unitPrice: item.unitPrice || 0,
      addFillings: getModifierIds(item.addFilling),
      addModifiers: getModifierIds(item.addModifier),
      removeModifiers: getModifierIds(item.removeModifier),
      extraModifiers: getModifierIds(item.extraModifier),
      extraFilling: getModifierIds(item.extraFilling),
      defaultModifier: getModifierIds(item.defaultModifier),
    });
  }
  return favouriteBasketItem;
};

/**
 * Reusing mapFavouriteBasketItemToCart to get the item's latest price
 * from menustucture
 * @param items
 * @param menuStructure
 * @returns CartItem
 */
export const updateCartItemOnMenuChange = (
  items: CartItem[],
  menuStructure: MenuStructure
): CartItem[] => {
  /**
   * TODO: Evaluate if this needs to be improved and lessen data massaging.
   * Currently it converts current cart item to favourite basket item,
   * then mapping again from favourite basket item to cart item.
   */

  const cartItemFromMenu: CartItem[] = [];
  for (const item of items) {
    const favouriteBasketItem: FavouriteBasketItem = {
      id: item.productId,
      name: item.name,
      quantity: item.quantity,
      addFillings: getModifierIds(item.addFilling),
      addModifiers: getModifierIds(item.addModifier),
      removeModifiers: getModifierIds(item.removeModifier),
      extraModifiers: getModifierIds(item.extraModifier),
      extraFilling: getModifierIds(item.extraFilling),
      defaultModifier: getModifierIds(item.defaultModifier),
      parts: item.parts ? mapPartToFavourite(item.parts) : undefined,
    };

    cartItemFromMenu.push(
      mapFavouriteBasketItemToCart(favouriteBasketItem, menuStructure).item
    );
  }

  return cartItemFromMenu;
};

/**
 * Tracks each cartItem when user press ADD TO YOUR ORDER
 */
interface LogAddedCartItems {
  cartItems: CartItem[];
  categoryName?: string;
  applyAll?: boolean;
  isUpsell?: boolean;
}

export const logAddedCartItems = ({
  cartItems,
  categoryName,
  applyAll,
  isUpsell,
}: LogAddedCartItems) => {
  cartItems.forEach((cartItem: CartItem, index: number) => {
    const analyticsObj = {
      product_id_upsell: cartItem.productId.toString(),
      product_name_upsell: cartItem.name,
      product_category_upsell: index === 0 ? categoryName : "", // index == 1 is MIAM item
      product_quantity_upsell: cartItem.quantity,
      did_apply_all: !!applyAll,
      is_upsell: !!isUpsell,
    };

    AnalyticsInstance.trackEvent(AnalyticsConst.Events.AddToCart, analyticsObj);
  });
};

interface MapUpsellForSourceParams {
  cartItems: CartItem[];
  isUpsell: boolean;
  isPreCart: boolean;
}
export const mapUpsellForSource = ({
  cartItems,
  isPreCart,
  isUpsell,
}: MapUpsellForSourceParams) => {
  return cartItems.map((cartItem) => {
    if (isUpsell) {
      return {
        ...cartItem,
        upsellSource: isPreCart ? UpsellSource.PreCart : UpsellSource.InCart,
      };
    }

    return cartItem;
  });
};

interface MapButtonTitleParams {
  hasCommonSection: boolean;
  selectedMealId?: number;
  locale: locales;
  isSelectionValid: boolean;
}
export const mapButtonTitle = ({
  hasCommonSection,
  selectedMealId,
  locale,
  isSelectionValid,
}: MapButtonTitleParams) => {
  const localeData =
    locale === locales.AU ? orderManagementAU : orderManagementUS;

  if (!hasCommonSection) return localeData.addProductToCart;

  if (hasCommonSection && selectedMealId === undefined) {
    return localeData.selectMealOrItem;
  }

  if (isSelectionValid) {
    return localeData.addProductToCart;
  }

  return localeData.makeRequiredSelections;
};
