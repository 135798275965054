import React from "react";
import { Image, Platform, Pressable, Text, View } from "react-native";

import * as PlatformUtils from "../../../modules/platformUtils";
import { shadowTextstyle } from "../../styles/shared";
import Spinner from "../Spinner";
import { ButtonProps, styles } from "./PrimaryYellowButton.styles";
import SvgXml from "./SvgXml";

const PrimaryYellowButton: React.FC<ButtonProps> = (props) => {
  return (
    <View style={styles(props).borderContainer}>
      <Pressable
        {...PlatformUtils.generateTestID(
          Platform.OS,
          props.testId ?? "primary-yellow-button"
        )}
        disabled={props.disable || props.loading}
        onPress={props.onClick}
        style={({ hovered, pressed }) =>
          styles({ ...props, hovered, pressed }).container
        }>
        <View style={styles(props).buttonContainer}>
          {props.loading ? (
            <Spinner />
          ) : (
            <>
              {props.icon && (
                <View style={styles(props).icon}>
                  {Platform.OS == "web" || props.defaultImageType ? (
                    <Image
                      source={props.icon}
                      style={styles(props).iconStyle}
                      resizeMode='contain'
                      tintColor={props.iconColor}
                    />
                  ) : (
                    <SvgXml
                      xml={props.icon as string}
                      width={props.iconWidth ?? "80"}
                      height='30'
                    />
                  )}
                </View>
              )}
              {props.buttonName && (
                <Text
                  numberOfLines={1}
                  style={[styles(props).centerText, shadowTextstyle(1).shadow]}>
                  {props.buttonName}
                </Text>
              )}
              {props.rightIcon && (
                <View style={styles(props).rightIcon}>
                  {Platform.OS == "web" || props.defaultImageType ? (
                    <Image
                      source={props.rightIcon}
                      style={styles(props).rightIconStyle}
                      resizeMode='contain'
                    />
                  ) : (
                    <SvgXml
                      xml={props.rightIcon as string}
                      width={props.iconWidth ?? "80"}
                      height='30'
                    />
                  )}
                </View>
              )}
            </>
          )}
        </View>
      </Pressable>
    </View>
  );
};

export default PrimaryYellowButton;
