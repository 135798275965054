import React, { useCallback } from "react";
import {
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TextProps,
  TextStyle,
  View,
  ViewProps,
  ViewStyle,
} from "react-native";

import colours from "./styles/colours";
import { Typography } from "./styles/typography";

const LABEL_FORMATTER_VALUES = [1, 2, 3, 4] as const;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    flexDirection: "row",
    marginHorizontal: 4,
  },
  label: {
    textAlignVertical: "center",
    textAlign: "center",
    ...Typography.bodyBold,
    marginLeft: Platform.OS == "web" ? 0 : 0.5,
  },
});

export enum BadgeSizes {
  smallest = 6,
  extraSmall = 10,
  small = 14,
  medium = 16,
  default = 20,
  large = 24,
}

type LabelFormatterValues = (typeof LABEL_FORMATTER_VALUES)[number];

export interface BadgeProps extends ViewProps {
  label?: string;
  size?: BadgeSizes | number;
  labelColor?: string;
  backgroundColor?: string;
  containerStyle?: StyleProp<ViewStyle>;
  labelStyle?: TextStyle;
  labelFormatterLimit?: LabelFormatterValues;
  labelProps?: TextProps;
}

function Badge(props: BadgeProps) {
  const {
    label,
    size = BadgeSizes.default,
    labelColor = colours.black,
    backgroundColor = colours.yellow,
    containerStyle,
    labelStyle,
    labelFormatterLimit = 1,
    labelProps,
    ...rest
  } = props;

  const viewSize = size;
  const textSize = viewSize - 6;
  const hasLabel = label !== undefined && label !== null;

  const getLabel = useCallback(() => {
    if (labelFormatterLimit && label) {
      if (LABEL_FORMATTER_VALUES.includes(labelFormatterLimit)) {
        const maxNumber = 10 ** labelFormatterLimit - 1;

        return parseInt(label) > maxNumber ? `${maxNumber}+` : label;
      }
    }

    return label;
  }, [labelFormatterLimit, label]);

  return (
    <View
      {...rest}
      style={[
        styles.container,
        {
          backgroundColor,
          height: viewSize,
          borderRadius: viewSize / 2,
          minWidth: viewSize,
          paddingHorizontal: hasLabel ? 4 : 0,
        },
        containerStyle,
      ]}>
      {hasLabel && (
        <Text
          numberOfLines={1}
          allowFontScaling={false}
          {...labelProps}
          style={[
            styles.label,
            {
              fontSize: textSize,
              lineHeight: textSize + 4,
              color: labelColor,
            },
            labelStyle,
          ]}>
          {getLabel()}
        </Text>
      )}
    </View>
  );
}

export default React.memo(Badge);
