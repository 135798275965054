import "./style.scss";

import React, { useEffect, useRef } from "react";

import { useFlyingBurritoAsset, useLottieWeb } from "gyg_common/hooks";

export const FlyingBurritoLoader: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { lottie } = useLottieWeb();
  const { isAssetLoading, flyingBurrito } = useFlyingBurritoAsset();

  useEffect(() => {
    if (isAssetLoading || !lottie) {
      return;
    }

    if (wrapperRef.current) {
      lottie.loadAnimation({
        container: wrapperRef.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: flyingBurrito,
      });
    }
  }, [flyingBurrito, isAssetLoading, lottie]);

  return (
    <div
      ref={wrapperRef}
      data-testid='flyingBurrito'
      className='loader__show-more-burrito'
      id={`flying-burrito-${
        wrapperRef.current?.id || Math.floor(Math.random() * 99999)
      }`}
    />
  );
};
