import { Map, Marker as MapMarker } from "mapkit-react";
import React from "react";
import { ImageSourcePropType, StyleSheet, View } from "react-native";

import config from "../../config";
import colours from "../styles/colours";

const defaultZoom = 17;

export interface StaticMapProps {
  lat: number;
  lng: number;
  icon: ImageSourcePropType;
}

const styles = StyleSheet.create({
  map: {
    width: "100%",
    height: 180,
    alignItems: "center",
    justifyContent: "center",
  },
});

const StaticMap: React.FC<StaticMapProps> = ({ lat, lng }) => {
  const latitudeDelta = 360 / Math.pow(2, defaultZoom);
  const longitudeDelta =
    360 / (Math.pow(2, defaultZoom) * Math.cos((lat * Math.PI) / 180));
  return (
    <View style={styles.map}>
      <Map
        token={config.mapsJsToken}
        initialRegion={{
          centerLatitude: lat,
          centerLongitude: lng,
          latitudeDelta: latitudeDelta,
          longitudeDelta: longitudeDelta,
        }}
        showsPointsOfInterest={false}
        isZoomEnabled={false}
        isScrollEnabled={false}
        isRotationEnabled={false}>
        <MapMarker
          glyphImage={{
            1: require("gyg_common/components/assets/images/logo_black_big.png"),
          }}
          glyphColor={colours.black}
          color={colours.yellow}
          latitude={lat}
          longitude={lng}
          selected={false}
        />
      </Map>
    </View>
  );
};

export default StaticMap;
