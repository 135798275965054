import { useMediaQuery } from "hooks/useMediaQuery";
import moment, { Moment } from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, Text, View } from "react-native";

import config from "config";
import {
  ComponentsModules,
  MenuModels,
  OrderModules,
  OrderReduxModels,
  PlatformUtils,
  StoreModules,
  StoreReduxModels,
} from "gyg_common";
import SecondaryButton from "gyg_common/components/Buttons/SecondaryButton";
import TertiaryButton from "gyg_common/components/Buttons/TertiaryButton";
import {
  ServicesList,
  StoreAddress,
  StoreHead,
  StoreStatus,
} from "gyg_common/components/StoreDetails";
import { ActiveTabType } from "gyg_common/components/ToggleSelect";
import {
  OrderOffset,
  OrderTime,
  StoresTimeZone,
} from "gyg_common/services/api/store/model";
import { isStoreOpenThisWeek } from "gyg_common/utils/order";

import {
  DropdownMenu,
  DropdownMenuItem,
} from "../../shared/DropdownMenu/DropdownMenu";
import { OrderSetupTableService } from "../OrderSetupTableService";
import { OrderSetupPickup } from "./OrderSetupPickup";
import styles from "./OrderSetupSheetContent.styles";

export interface OrderSetupSheetContentProps {
  cartTotalPrice: number;
  storeOrderOffset: OrderOffset[];
  storeOrderTimes: OrderTime[];
  menuLoading: boolean;
  menuStructure?: MenuModels.MenuStructure;
  selectedStore: StoreReduxModels.Store | null;
  orderASAP: boolean;
  orderCollectionType: OrderReduxModels.CollectionType;
  orderTime: number | null;
  storeOpenTime: StoreReduxModels.StoreOpenTime;
  menuOpenTime: StoreReduxModels.StoreMenu;
  onChangeRestaurantClick: () => void;
  onChangeOrderCollectionType: (
    orderSetup: OrderReduxModels.OrderCollectionTypeProps
  ) => void;
  navigateToStoreDetails: () => void;
  setData: (data: OrderReduxModels.SetupOrderProps) => void;
  handleLink: (platform: string) => void;
  asapAlwaysEnabled?: boolean;
  storeAsapTime: Date;
  storeTimeZoneInfo: StoresTimeZone;
}

export const OrderSetupSheetContent: React.FC<OrderSetupSheetContentProps> = ({
  cartTotalPrice,
  storeOrderOffset,
  storeOrderTimes,
  menuLoading,
  menuStructure,
  selectedStore,
  orderCollectionType,
  orderASAP,
  orderTime,
  storeOpenTime,
  menuOpenTime,
  onChangeRestaurantClick,
  onChangeOrderCollectionType,
  navigateToStoreDetails,
  setData,
  handleLink,
  asapAlwaysEnabled,
  storeAsapTime,
  storeTimeZoneInfo,
}) => {
  const { t } = useTranslation();
  const { isTabletScreen } = useMediaQuery();

  const [orderTypeSetting, setOrderTypeSetting] =
    useState<OrderReduxModels.CollectionType>(orderCollectionType);
  const [orderAsapSetting, setOrderAsapSetting] = useState(
    orderASAP ? ActiveTabType.LEFT : ActiveTabType.RIGHT
  );
  const [validOrderDays, setValidOrderDays] = useState<
    ComponentsModules.DropdownPickerModels.PickerItemProps<Moment>[]
  >([]);
  const [validOrderHoursAM, setValidOrderHoursAM] = useState<
    ComponentsModules.DropdownPickerModels.PickerItemProps<number>[]
  >([]);
  const [validOrderHoursPM, setValidOrderHoursPM] = useState<
    ComponentsModules.DropdownPickerModels.PickerItemProps<number>[]
  >([]);
  const [validOrderMinutes, setValidOrderMinutes] = useState<
    ComponentsModules.DropdownPickerModels.PickerItemProps<number>[]
  >([]);
  const [isAutoSetAMPM, setAutoSetAMPM] = useState<boolean>(false);

  const isAM = (amPmSetting: ActiveTabType) => {
    return amPmSetting === ActiveTabType.LEFT;
  };

  const valueToFindInDropdown: OrderReduxModels.CollectionType =
    orderTypeSetting === OrderReduxModels.CollectionType.DRIVE_THRU
      ? OrderReduxModels.CollectionType.PICK_UP
      : orderTypeSetting;
  const isTableServiceAvailable = StoreModules.StoreUtils.checkIfStoreHasTag(
    StoreModules.StoreModels.StoreTagLabel.TABLE_SERVICE,
    selectedStore?.tags
  );
  const orderTypeDropDownItems =
    OrderModules.OrderUtils.createOrderTypeDropdownItems(
      config.version,
      isTableServiceAvailable,
      Platform.OS,
      storeOpenTime.isOpen
    );

  /** State for select a time form */
  const [orderDay, setOrderDay] = useState<Moment>(moment());
  const [orderHour, setOrderHour] = useState(0);
  const [orderMinutes, setOrderMinutes] = useState(0);
  const [orderAmPm, setOrderAmPm] = useState(ActiveTabType.LEFT);
  const [openThisWeek, setOpenThisWeek] = useState(true);
  const [asapTime, setAsapTime] = useState<Date>(new Date());

  // calculate the valid hour and minute slots for a given day
  const validHoursForDay = OrderModules.OrderUtils.getValidOrderTimeslots(
    orderDay,
    storeOrderTimes,
    asapTime,
    menuStructure?.store.timeZoneInfo.storeTimeZone
  );
  const amPmTimeslots =
    OrderModules.OrderUtils.groupAmPmTimeslots(validHoursForDay);
  const validHoursAM = Object.keys(amPmTimeslots.am);
  const validHoursPM = Object.keys(amPmTimeslots.pm);

  useEffect(() => {
    if (selectedStore && orderAsapSetting === ActiveTabType.RIGHT) {
      setAsapTime(storeAsapTime);
    }
  }, [selectedStore, storeAsapTime, orderAsapSetting]);

  useEffect(() => {
    setOpenThisWeek(isStoreOpenThisWeek(selectedStore?.tradingHours ?? []));
  }, [selectedStore?.tradingHours]);

  /** Set AM/PM based on state */
  useEffect(() => {
    setOrderAsapSetting(
      menuOpenTime.isMenuOpen && orderASAP
        ? ActiveTabType.LEFT
        : ActiveTabType.RIGHT
    );
  }, [menuOpenTime, orderASAP]);

  /** Calculate the days that are valid for scheduled order pickups */
  useEffect(() => {
    if (menuStructure) {
      const validDays = OrderModules.OrderUtils.getValidOrderSetupDays(
        storeOrderTimes
      ) as ComponentsModules.DropdownPickerModels.PickerItemProps<Moment>[];

      setValidOrderDays(validDays);
      setOrderDay(
        orderTime
          ? moment(orderTime).tz(menuStructure.store.timeZoneInfo.storeTimeZone)
          : validDays.length
            ? validDays[0].value
            : moment().tz(menuStructure.store.timeZoneInfo.storeTimeZone)
      );
    }
  }, [menuStructure, orderTime, storeOrderTimes]);

  /**
   * Calculate both AM and PM hours based on order day
   */
  useEffect(() => {
    if (menuStructure) {
      const hoursForAM = OrderModules.OrderUtils.orderDisplayedHours(
        validHoursAM
      ).map((hour) => {
        return { label: hour, value: Number(hour) };
      });
      const hoursForPM = OrderModules.OrderUtils.orderDisplayedHours(
        validHoursPM
      ).map((hour) => {
        return { label: hour, value: Number(hour) };
      });

      setValidOrderHoursAM(hoursForAM);
      setValidOrderHoursPM(hoursForPM);

      setOrderHour(
        validHoursAM.length ? Number(validHoursAM[0]) : Number(validHoursPM[0])
      );

      //reset auto setting AM PM flag if day/menu is changed
      setAutoSetAMPM(false);
    }
    // TODO: To check and revist later
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [validOrderDays, orderDay, menuStructure]);

  // calculate the valid minutes to populate in minute picker and update when day, hour  or AM/PM changes
  useEffect(() => {
    if (orderDay && orderHour && menuStructure) {
      const validMinutes = isAM(orderAmPm)
        ? amPmTimeslots.am[orderHour] || []
        : amPmTimeslots.pm[orderHour] || [];

      setValidOrderMinutes(
        validMinutes.map((minute) => {
          return {
            label: minute < 10 ? `0${minute.toString()}` : minute.toString(),
            value: minute,
          };
        })
      );

      if (validMinutes.length > 0) {
        setOrderMinutes(validMinutes[0]);
      }
    }
    // TODO: To check and revist later
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    orderDay,
    orderHour,
    orderAmPm,
    menuStructure,
    orderTime,
    menuOpenTime.isMenuOpen,
  ]);

  /**
   * Runs only once (isAutoSetAMPM)
   * Auto set AM PM if user has not confirmed order setup
   */
  useEffect(() => {
    if (!isAutoSetAMPM) {
      if (!validOrderHoursAM.length) {
        setOrderAmPm(ActiveTabType.RIGHT);
      } else {
        setOrderAmPm(ActiveTabType.LEFT);
      }
      setAutoSetAMPM(true);
    }
  }, [isAutoSetAMPM, validOrderHoursAM]);

  /**
   * Auto set order setup time based on orderTime in redux state
   * Will call only if user confirmed order setup
   */
  useEffect(() => {
    if (orderTime && menuStructure) {
      const orderSetupHour = moment(orderTime)
        .tz(menuStructure.store.timeZoneInfo.storeTimeZone)
        .get("hour");

      const isAm =
        moment(orderTime)
          .tz(menuStructure.store.timeZoneInfo.storeTimeZone)
          .format("a") === "am";

      const orderSetupHour12Hour =
        orderSetupHour && orderSetupHour > 12
          ? orderSetupHour - 12
          : orderSetupHour;

      setOrderHour(orderSetupHour12Hour);

      setOrderMinutes(
        moment(orderTime)
          .tz(menuStructure.store.timeZoneInfo.storeTimeZone)
          .get("minute")
      );

      setOrderAmPm(isAm ? ActiveTabType.LEFT : ActiveTabType.RIGHT);
    }
  }, [orderTime, menuStructure]);

  // update the form validation and form data for parent component
  useEffect(() => {
    const orderAsapSet = orderAsapSetting === ActiveTabType.LEFT;
    if (selectedStore) {
      if (orderAsapSet) {
        const time = storeAsapTime;
        setAsapTime(time);
        setData({
          orderCollectionType: orderTypeSetting,
          orderTime: time.valueOf(),
          orderASAP: orderAsapSet,
        });
      } else {
        const hour =
          isAM(orderAmPm) || orderHour === 12 ? orderHour : orderHour + 12;

        const timestamp = moment()
          .tz(selectedStore.timeZoneInfo.storeTimeZone)
          .set("hour", hour)
          .set("minute", orderMinutes)
          .set("second", 0)
          .set("date", orderDay.get("date"))
          .set("month", orderDay.get("month"))
          .toDate();

        setData({
          orderCollectionType: orderTypeSetting,
          orderTime: timestamp.getTime(),
          orderASAP: orderAsapSet,
        });
      }
    }
  }, [
    orderTypeSetting,
    orderDay,
    orderHour,
    orderMinutes,
    orderAmPm,
    orderASAP,
    selectedStore,
    setData,
    cartTotalPrice,
    storeOrderOffset,
    storeOrderTimes,
    storeAsapTime,
  ]);

  /**
   *  Item selected handlers
   */
  const handleOrderType = (
    selectedOrderType: DropdownMenuItem<OrderReduxModels.CollectionType>
  ) => {
    setOrderTypeSetting(selectedOrderType.value);
    onChangeOrderCollectionType({
      orderCollectionType: selectedOrderType.value,
    });
  };

  /**
   * Checks if there is valid hours available during either am or pm
   * If no valid hours, do nothing
   * @param orderAmPmData
   */
  const orderAmPmHandler = (orderAmPmData: ActiveTabType) => {
    if (
      ((isAM(orderAmPmData) && validOrderHoursAM.length) ||
        (!isAM(orderAmPmData) && validOrderHoursPM.length)) &&
      validOrderMinutes.length
    ) {
      setOrderHour(
        isAM(orderAmPmData)
          ? validOrderHoursAM[0].value
          : validOrderHoursPM[0].value
      );
      setOrderMinutes(validOrderMinutes[0].value);
      setOrderAmPm(orderAmPmData);
    }
  };

  return (
    <View
      style={
        isTabletScreen
          ? styles.orderSetupContentContainer
          : styles.mobileContainer
      }>
      <View
        style={[
          styles.storeDetailsContent,
          !isTabletScreen && { marginBottom: 16 },
        ]}>
        <Text style={styles.title}>
          {t("OrderManagement:orderingFromLabel")}
        </Text>
        {selectedStore && <StoreHead store={selectedStore} />}
        {selectedStore && (
          <View style={{ marginVertical: 8 }}>
            <StoreAddress
              address1={selectedStore.address1}
              address2={selectedStore.address2}
              city={selectedStore.city}
              postCode={selectedStore.postCode}
              state={selectedStore.state}
            />
          </View>
        )}
        <StoreStatus {...storeOpenTime} />
        {selectedStore && <ServicesList tags={selectedStore.tags} />}
        <View style={styles.infoButtonsWrapper}>
          <View
            style={styles.changeRestaurantButtonWrapper}
            {...PlatformUtils.generateTestID(
              Platform.OS,
              "OrderSetupChangeRestaurantsButtonWrapper"
            )}>
            <SecondaryButton
              buttonName={t("OrderManagement:changeRestaurantButton")}
              onClick={() => onChangeRestaurantClick()}
            />
          </View>
          <View
            style={styles.moreInfoButtonWrapper}
            {...PlatformUtils.generateTestID(
              Platform.OS,
              "OrderSetupMoreInfoButtonWrapper"
            )}>
            <TertiaryButton
              title={t("OrderManagement:moreInfoButton")}
              onClick={navigateToStoreDetails}
            />
          </View>
        </View>
      </View>

      {!isTabletScreen && <div className='grey-divider' />}

      <View
        style={
          isTabletScreen
            ? styles.orderSetupFormWrapper
            : styles.mobileSetupFormWrapper
        }>
        <Text
          style={
            menuLoading ||
            openThisWeek ||
            orderTypeSetting === OrderReduxModels.CollectionType.TABLE_SERVICE
              ? styles.orderType
              : styles.orderTypeError
          }>
          {t("OrderManagement:orderType")}
        </Text>
        <DropdownMenu
          items={orderTypeDropDownItems}
          selectedItem={
            orderTypeDropDownItems.find(
              (item) => item.value === valueToFindInDropdown
            ) ?? orderTypeDropDownItems[0]
          }
          onItemSelected={handleOrderType}
          isError={
            !menuLoading &&
            !openThisWeek &&
            orderTypeSetting !== OrderReduxModels.CollectionType.TABLE_SERVICE
          }
        />

        {orderTypeSetting === OrderReduxModels.CollectionType.TABLE_SERVICE ? (
          <OrderSetupTableService handleLink={handleLink} />
        ) : (
          !menuLoading && (
            <>
              {openThisWeek ? (
                <OrderSetupPickup
                  showScheduleForLater={
                    !!(storeOrderOffset.length && storeOrderTimes.length)
                  }
                  timeZone={
                    selectedStore?.timeZoneInfo.storeTimeZone ??
                    OrderModules.OrderConstants.DEFAULT_TIMEZONE
                  }
                  disableASAP={OrderModules.OrderUtils.hasNoAvailablePickupSlotsToday(
                    asapTime,
                    asapAlwaysEnabled,
                    storeTimeZoneInfo.storeTimeZone
                  )}
                  isStoreOpen={menuOpenTime.isMenuOpen}
                  isStoreOpenThisWeek={openThisWeek}
                  asapTime={asapTime}
                  orderAsapSetting={orderAsapSetting}
                  validOrderDays={validOrderDays}
                  validOrderHours={
                    isAM(orderAmPm) ? validOrderHoursAM : validOrderHoursPM
                  }
                  validOrderMinutes={validOrderMinutes}
                  orderDay={orderDay}
                  orderHour={orderHour}
                  orderMinutes={orderMinutes}
                  orderAmPm={orderAmPm}
                  setOrderDay={setOrderDay}
                  setOrderHour={setOrderHour}
                  setOrderMinutes={setOrderMinutes}
                  setOrderAmPm={orderAmPmHandler}
                  setOrderAsapSetting={setOrderAsapSetting}
                />
              ) : (
                <p
                  data-testid='RestaurantClosedMessage'
                  className='order-setup-no-delivery__error-message'>
                  {t("OrderManagement:restaurantClosedMessage")}
                </p>
              )}
            </>
          )
        )}
      </View>
    </View>
  );
};
