// import { PlatformUtils } from "index";
import React from "react";
import {
  Image,
  ImageSourcePropType,
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { generateTestID } from "../../modules/platformUtils";
import iconAlertPng from "../assets/icons/icon_alert.png";
import iconAlertSvg from "../assets/icons/icon_alert.svg";
import tickImgPng from "../assets/icons/icon_alert_tick.png";
import tickImgSvg from "../assets/icons/icon_alert_tick.svg";
import closeImgPng from "../assets/icons/icon_close.png";
import closeImgSvg from "../assets/icons/icon_close.svg";
import iconHeart from "../assets/icons/icon_love.png";
import { Typography } from "../styles/typography";
import { dynamicStyles, stylesApp, stylesWeb } from "./styles";

export interface MessageToastProps {
  iconType?: "alert" | "heart" | "check";
  title: string;
  extraButtonHeight?: boolean;
  isShowClose?: boolean;
  onClose?: () => void;
}

const MessageToastWeb: React.FC<MessageToastProps> = ({
  title,
  iconType = "alert",
  isShowClose,
  onClose,
}) => {
  const getIcon = () => {
    switch (iconType) {
      case "check":
        return tickImgSvg;
      case "heart":
        return iconHeart;
      case "alert":
        return iconAlertSvg;
      default:
        return iconAlertSvg;
    }
  };

  const icon = getIcon();

  return (
    <div className={"message-toast__container main-wrapper"}>
      <SafeAreaView style={stylesWeb.container}>
        <View style={stylesWeb.wrapper}>
          <Image style={stylesWeb.icon} source={icon as ImageSourcePropType} />
          <View style={stylesWeb.text}>
            <Text style={Typography.titleThree}>{title}</Text>
          </View>

          {isShowClose && (
            <TouchableOpacity
              {...generateTestID(Platform.OS, "ToastCloseButton")}
              style={{ alignSelf: "center" }}
              onPress={onClose}>
              <Image
                source={closeImgSvg as ImageSourcePropType}
                style={stylesWeb.closeImg}
              />
            </TouchableOpacity>
          )}
        </View>
      </SafeAreaView>
    </div>
  );
};

const MessageToastApp: React.FC<MessageToastProps> = ({
  title,
  extraButtonHeight,
  isShowClose,
  onClose,
  iconType = "alert",
}) => {
  const getIcon = (): ImageSourcePropType => {
    switch (iconType) {
      case "check":
        return tickImgPng as ImageSourcePropType;
      case "heart":
        return iconHeart as ImageSourcePropType;
      case "alert":
        return iconAlertPng as ImageSourcePropType;
      default:
        return iconAlertPng as ImageSourcePropType;
    }
  };

  const icon = getIcon();

  return (
    <SafeAreaView style={dynamicStyles(extraButtonHeight).container}>
      <View style={stylesApp.wrapper}>
        <Image style={stylesApp.icon} source={icon} />
        <View style={stylesApp.text}>
          <Text style={Typography.titleThree}>{title}</Text>
        </View>
        {isShowClose && (
          <TouchableOpacity
            {...generateTestID(Platform.OS, "ToastCloseButton")}
            onPress={onClose}>
            <Image
              source={closeImgPng as ImageSourcePropType}
              style={stylesApp.closeImg}
            />
          </TouchableOpacity>
        )}
      </View>
    </SafeAreaView>
  );
};

export const MessageToast: React.FC<MessageToastProps> = (props) => {
  if (Platform.OS === "web") {
    return <MessageToastWeb {...props} />;
  } else {
    return <MessageToastApp {...props} />;
  }
};

export default MessageToast;
