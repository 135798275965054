import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { Dispatch } from "redux";

import config from "../../../config";
import { AnalyticsAction } from "../../../redux_store/analytics/analytics.slice";
import { authActions as AuthReduxAction } from "../../../redux_store/auth/auth.slice";
import * as AuthStateModels from "../../../redux_store/auth/models";
import { CheckoutAction } from "../../../redux_store/checkout/checkout.slice";
import { errorActions } from "../../../redux_store/error/error.slice";
import { favouriteActions } from "../../../redux_store/favourite/favourite.slice";
import { orderActions } from "../../../redux_store/order/order.slice";
import { socialActions as SocialActions } from "../../../redux_store/social/social.slice";
import { userActions as UserReduxAction } from "../../../redux_store/user/user.slice";
import { SentryLoggerInstance } from "../../../sentry";
import * as ApiService from "../auth/";

const clearUserDataAndLogout = (dispatch: Dispatch) => {
  dispatch(AnalyticsAction.setAuthTokenExpired(true));
  dispatch(AuthReduxAction.logoutCurrentDevice());
  dispatch(AuthReduxAction.loggedUserToLogin());
  dispatch(errorActions.clearError());
  dispatch(CheckoutAction.resetPayment());
  dispatch(orderActions.clearOrderError());
  dispatch(favouriteActions.resetStatus());
};

export const registerUnauthorizedInterceptor = (dispatch: Dispatch) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refreshAuthLogic = async (failedRequest: any) => {
    if (!config.refreshToken) {
      //exist if there is no refresh token (user is not logged in), fixes issue on fresh app launch which automatically moves user to login screen
      return;
    }
    const payload: AuthStateModels.RefreshTokenPayload = {
      refreshToken: config.refreshToken,
      grantType: AuthStateModels.GrantType.REFRESH,
    };
    try {
      dispatch(AuthReduxAction.loading());
      const tokenRefreshResponse = await ApiService.getNewToken(payload);

      //Check if new access token and refresh token are returned, logout if not
      if (
        tokenRefreshResponse.accessToken.length > 0 &&
        tokenRefreshResponse.refreshToken.length > 0
      ) {
        failedRequest.config.headers.Authorization =
          tokenRefreshResponse.accessToken;

        config.accessToken = tokenRefreshResponse.accessToken;
        config.refreshToken = tokenRefreshResponse.refreshToken;

        dispatch(AuthReduxAction.refreshTokenSuccess(tokenRefreshResponse));
        dispatch(UserReduxAction.getUserProfile());

        if (!tokenRefreshResponse.verifiedMobileNumber) {
          dispatch(SocialActions.setSocialRegister(true));
          dispatch(UserReduxAction.setShowMobileVerifyModal(true));
        }
      } else {
        clearUserDataAndLogout(dispatch);
      }
    } catch (error) {
      SentryLoggerInstance.sentryRefreshTokenError(error);
      clearUserDataAndLogout(dispatch);

      throw error;
    }

    return Promise.resolve();
  };

  createAuthRefreshInterceptor(axios, refreshAuthLogic);

  axios.interceptors.request.use((request) => {
    if (request.headers.Authorization !== undefined) {
      request.headers.Authorization = config.accessToken;
    }
    return request;
  });
};
