import React from "react";
import {
  Image,
  ImageSourcePropType,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";

import arrowRigthIcon from "../../../components/assets/icons/services/right_icon.png";
import Divider from "../../../components/BaseComponents/Divider";
import colours from "../../../components/styles/colours";
import { Spacing } from "../../../components/styles/number";
import { Typography } from "../../../components/styles/typography";
import { DeliveryAddress } from "../../../redux_store/order/models";

const styles = StyleSheet.create({
  text: {
    ...Typography.body,
    color: colours.black,
    paddingVertical: Spacing.Light,
    flex: 1,
  },
  arrowIcon: {
    height: Spacing.Light,
    width: Spacing.Medium,
    marginRight: Spacing.Thin,
  },
  container: { flexDirection: "row", alignItems: "center" },
});

interface DeliveryAddressViewProps {
  recentAddress: DeliveryAddress;
  onPress: (address: DeliveryAddress) => void;
  hideDivider?: boolean;
}

const RecentAddressView = ({
  recentAddress,
  onPress,
  hideDivider,
}: DeliveryAddressViewProps) => {
  const { fullAddress } = recentAddress;

  const onPressAction = () => {
    onPress(recentAddress);
  };

  return (
    <Pressable onPress={onPressAction}>
      <View style={styles.container}>
        <Text style={styles.text}>{fullAddress}</Text>
        <Image
          resizeMode='contain'
          source={arrowRigthIcon as ImageSourcePropType}
          style={styles.arrowIcon}
        />
      </View>
      {!hideDivider && <Divider />}
    </Pressable>
  );
};

export default RecentAddressView;
