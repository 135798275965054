import { CartReduxModels, MenuModels, ProductModuleModel } from "index";
import { uniqBy } from "lodash";

import { RadioButtonGroupData } from "../../components/Buttons/Radio/RadioButtonGroup";
import { locales } from "../../locales/locales.enum";
import orderManagementAU from "../../locales/OrderManagement/au.json";
import orderManagementUS from "../../locales/OrderManagement/us.json";
import { BundleState, CartItem, Modifier } from "../../redux_store/cart/models";
import {
  Category,
  CategoryTagData,
  CategoryTags,
  CommonSection,
  InternalTagType,
  MenuSection,
  ModifierLookup,
  MultiPart,
  MultipartSection,
  Product,
  TagLookup,
  TagType,
} from "../../redux_store/menu/models";
import { SentryLoggerInstance } from "../../sentry";
import { getCartItemWithUpdatedQuantity } from "../Cart/itemsCalculator";
import {
  getProductSizeAndTitle,
  getTheLowestPriceInCategories,
} from "../Menu/utils";
import { ControlType, EditMode, ProductRoute, SectionTitle } from "./const";
import {
  getCustomisationMapper,
  getCustomisationOptionMapper,
  HOT_DRINKS_MODIFIERS,
  productCustomizationKeyMapper,
  productCustomizationList,
  RETRIEVE_MODE,
} from "./customizationMapper";
import {
  CustomizableOptionState,
  CustomizeOptionsDataProps,
  MealCustomizableOptionState,
  OptionDataProps,
  ProductDetailProps,
  ReloadOptionStates,
  SimpleCustomisableProductRadioButton,
  ToggleState,
} from "./model";

/**
 * Creates object of products' sizes for RadioButtonGroup from size tags in the category.
 * @param category of sides
 * @returns `RadioButtonGroupData` object
 */
export function getSimpleCustomisableProductOptions(
  category: Category
): RadioButtonGroupData<SimpleCustomisableProductRadioButton>[] {
  // finds identifier objects, returns only tags that exist in the products
  const identifiers = category.products.map((product: Product) => {
    let identifier: TagLookup = null;
    product.tags?.map((tag: string) => {
      category.tagLookup?.map((tagLookup: TagLookup) => {
        if (tagLookup.tagId === tag) {
          identifier = tagLookup;
        } else {
          return;
        }
      });
    });
    return identifier;
  });

  const radioButtonGroup: RadioButtonGroupData<SimpleCustomisableProductRadioButton>[] =
    identifiers.map((identifier: TagLookup) => {
      if (identifier) {
        // looks for product that has particular identifier tag
        const product = category.products?.find((productItem: Product) => {
          return productItem.tags?.find(
            (tag: string) => tag === identifier.tagId
          );
        });

        const radioButtonProductObj: RadioButtonGroupData<SimpleCustomisableProductRadioButton> =
          {
            element: {
              label: identifier.value,
              id: product.id,
              price: product?.price || 0,
              nutritionalInfo: product.nutritionalInfo,
            },
          };

        return radioButtonProductObj;
      } else {
        return null;
      }
    });

  return radioButtonGroup.filter((element) => element !== null);
}

export const getModifierOverrideLookupFromIds = (
  category: Category | MultipartSection,
  productId?: number
) => {
  /**
   * Filters and returns modifierOverrideLookup for specific product ID
   */
  if (category?.modifierOverrideLookup?.length > 0) {
    return category?.modifierOverrideLookup?.filter(
      (modifier) => modifier.productId === productId
    );
  }
  return [];
};

/**
 * Filters modifierLookup based on modifiers' id.
 * @param ids modifier id
 * @param category category
 * @returns modifierLookup with selected modifiers
 */
export const getModifierLookupsFromIds = (
  productId: number,
  ids: number[] | undefined,
  category: Category | MultipartSection
): ModifierLookup[] => {
  const result: ModifierLookup[] = [];

  if (ids?.length === 0) {
    return result;
  }

  const modifierOverrideLookup = getModifierOverrideLookupFromIds(
    category,
    productId
  );
  const modifierLookup = category?.modifierLookup ?? [];

  /**
   * If `id` is not found in modifierOverrideLookup, logic still needs to look at modifierLookup for the fallback
   * Combining `modifierOverrideLookup` and `modifierLookup` will be easier for the `find` functionality as it
   * returns the first index.
   */
  const combinedModifierLookups = [
    ...modifierOverrideLookup,
    ...modifierLookup,
  ];

  ids?.forEach((id) => {
    const option = combinedModifierLookups?.find((n) => n.id === id);
    if (option) {
      result.push(option);
    }
  });

  return result;
};

export const getProductRoute = (
  templateId: string
): ProductRoute | undefined => {
  switch (templateId) {
    case "FF-Burrito":
      return ProductRoute.ComplexCustomisable;
    case "FF-Bowl":
      return ProductRoute.ComplexCustomisable;
    case "FF-Kids-Burritos":
      return ProductRoute.ComplexCustomisable;
    case "FF-Kids-Nachos":
      return ProductRoute.ComplexCustomisable;
    case "Kids-Meal-Template":
      return ProductRoute.ComplexCustomisableWithMeal;
    case "GUID-CT":
      return ProductRoute.ComplexCustomisable;
    case "Hot-Drinks-Template":
      return ProductRoute.HotDrinks;
    case "Fries-Template":
      return ProductRoute.SimpleCustomisable;
    case "Tacos-Bundle-Template":
      return ProductRoute.Tacos;
    case "FF-Template":
      return ProductRoute.Bundles;
    case "Drinks-Template":
      return ProductRoute.NonCustomization;
    case "No-Customisation-Template":
      return ProductRoute.NonCustomization;
    case "MIAM Template":
      return ProductRoute.MIAM;
    case "FF-Kids-Template":
      return ProductRoute.KidsBundle;
  }
};

export const findTemplateRoute = (
  category: Category
): ProductRoute | undefined => {
  const templateId =
    category.templateId ||
    category.tagLookup?.find((tag) => tag.type === TagType.TemplateID)?.value;

  if (templateId) {
    return getProductRoute(templateId);
  }
};

/**
 * Create cart item from selected product option in `SimpleCustomisableProduct`,
 * or simple cartItem for MIAM option.
 * @param category
 * @param productId
 * @param quantity
 * @param isFromMIAM indicates if created cart item belongs to MIAM's Side or Drink
 * @param modifiersId
 * @returns
 */
export function createCartItem<CartItem>(
  category: Category,
  productId: number,
  quantity: number,
  isFromMIAM: boolean,
  modifiersId?: number[]
): CartItem | undefined {
  const selectedProduct: Product = category.products?.find(
    (product: Product) => product.id === productId
  );

  // checks if modifiers exists for selected product
  const areModifiersApplicable = !selectedProduct?.removeModifier?.some(
    (modiId: number) =>
      modifiersId &&
      modifiersId.some((removedModiId) => removedModiId !== modiId)
  );

  let customisations: Modifier[] = [];
  if (areModifiersApplicable) {
    customisations = getModifierLookupsFromIds(
      productId,
      modifiersId,
      category
    ).map((modifier: ModifierLookup) => {
      const newM = {
        id: modifier.id,
        name: modifier.name,
        price: modifier.price,
      };
      return newM;
    });
  }

  if (selectedProduct) {
    const newCartItem = {
      productId: selectedProduct.id,
      posPlu: selectedProduct.posPlu,
      name: selectedProduct.name,
      quantity: quantity,
      unitPrice: selectedProduct.price,
      price: selectedProduct.price * quantity,
      removeModifier: customisations,
      allowModify: !!findTemplateRoute(category),
      labelForFavourite: isFromMIAM ? selectedProduct.name : category.name,
    } as unknown as CartItem;

    return newCartItem;
  } else {
    return;
  }
}

/**
 * Maps modifier info into toggle object.
 * @param modifierLookup
 * @param removeModifier existing modifiers
 * @param selectedtModifiersId selected modifiers
 * @returns array of toggle object
 */
export const productCustomisationMapper = (
  modifierLookup: ModifierLookup[],
  removeModifier: number[],
  selectedtModifiersId?: number[]
): ToggleState[] => {
  return modifierLookup
    .filter((modifierObj: ModifierLookup) =>
      removeModifier.find((modifierId: number) => modifierId === modifierObj.id)
    )
    .map((modifier: ModifierLookup) => {
      const value =
        selectedtModifiersId?.some((id: number) => id === modifier.id) || false;
      const newCustomisation: ToggleState = {
        label: modifier.name,
        id: modifier.id,
        value: !value,
      };
      return newCustomisation;
    });
};

/**
 * Creates toggle objects.
 * @param modifiers ids of existing modifiers
 * @param category category
 * @param toggleSelection existing toggle state
 * @param selectedModifiersId ids of selected modifiers
 */
export const setToggleData = (
  category: Category,
  toggleSelection: ToggleState[],
  modifiers?: number[],
  selectedModifiersId?: number[]
): ToggleState[] => {
  if (modifiers?.length && category.modifierLookup?.length) {
    const newToggleContent = productCustomisationMapper(
      category.modifierLookup,
      modifiers,
      selectedModifiersId
    );

    // updates toggle objects only if new customisation is applicable
    if (
      toggleSelection ||
      toggleSelection.length !== newToggleContent.length ||
      toggleSelection.some((el, i) => el.id !== newToggleContent[i].id)
    ) {
      return newToggleContent;
    }
  }
};

const findFillingForSelectedSize = (
  categoryTagData: CategoryTagData,
  categoryTagType: string,
  category: Category,
  customizeOptions?: CustomizableOptionState | undefined
): Product | undefined => {
  let product: Product = undefined;
  if (customizeOptions && categoryTagType == TagType.Filling) {
    const sizeValue = customizeOptions[TagType.Size]?.[0];
    if (sizeValue) {
      const sizeTag = category.tagLookup.find((tag) => tag.value === sizeValue);
      const fillingTag = category.tagLookup?.find(
        (tag) => tag.value === categoryTagData.value
      );
      product = category.products?.find(
        (p) =>
          p.tags?.includes(fillingTag.tagId) && p.tags?.includes(sizeTag.tagId)
      );
    }
  }
  return product;
};

export const generateIdentifierData = (
  category: Category,
  locale: locales,
  customizeOptions?: CustomizableOptionState | undefined,
  commonSection?: CommonSection,
  selectedMealId?: number | undefined
): CustomizeOptionsDataProps[] => {
  const options: CustomizeOptionsDataProps[] = [];
  const customisationMapper = getCustomisationMapper(locale);
  const localeData =
    locale === locales.AU ? orderManagementAU : orderManagementUS;

  if (commonSection) {
    const customisationMapperData = customisationMapper[InternalTagType.MIAM];

    options.push({
      id: InternalTagType.MIAM,
      title: customisationMapperData.title,
      subTitle: localeData.required,
      data: [],
      commonSection,
      isRequired: true,
    });

    if (selectedMealId === undefined) {
      // Early return `options` if MIAM is available and no selectedMealId yet
      return options;
    }
  }

  category.categoryTags?.forEach((categoryTag: CategoryTags) => {
    const customisationMapperData = customisationMapper[categoryTag.type];
    if (customisationMapperData) {
      const item: CustomizeOptionsDataProps = {
        title:
          categoryTag.data[0].tagAttribute ?? customisationMapperData.title,
        subTitle: localeData.required,
        id: categoryTag.type,
        displayOrder: customisationMapperData.displayOrder,
        isRequired: true,
        data: categoryTag.data
          .map((n: CategoryTagData) => {
            const product = findFillingForSelectedSize(
              n,
              categoryTag.type,
              category,
              customizeOptions
            );
            const priceList = categoryTag.data.map(
              (categoryTagItem) => categoryTagItem.price
            );
            const data: OptionDataProps = {
              element: {
                id: n.value,
                price: customisationMapperData.displayPrice
                  ? n.price - Math.min(...priceList)
                  : undefined,
                name: n.value,
                nutritionalInfo: customisationMapperData.displayNutritionalInfo
                  ? product?.nutritionalInfo ?? n.nutritionalInfo
                  : undefined,
                imageTopDownUrl: customisationMapperData.displayImage
                  ? n.imageTopDownUrl
                  : undefined,
                imageAngleUrl: customisationMapperData.displayImage
                  ? n.imageAngleUrl
                  : undefined,
                withoutImage: !customisationMapperData.displayImage,
                displayOrder: n.displayOrder,
              },
              disabled: false,
            };
            return data;
          })
          .sort(
            (a: OptionDataProps, b: OptionDataProps) =>
              a.element.displayOrder - b.element.displayOrder
          ),
      };
      options.push(item);
    }
  });
  return options.sort((a, b) => a.displayOrder - b.displayOrder);
};

/**
 * generate RadioButtonGroup OptionDataProps from combined tagLookups to render combinedSelection UI
 * @param tagLookups
 * @returns OptionDataProps[]
 */
export const generateOptionPropsFromTagLookups = (
  tagLookups: TagLookup[]
): OptionDataProps[] => {
  return tagLookups.map((n) => {
    return {
      element: {
        id: n.tagId,
        name: n.value,
        withoutImage: true,
      },
      disabled: false,
    };
  });
};

export const findLookUpByType = (
  category: Category,
  modifierSourceData: ModifierLookup,
  type: TagType
): TagLookup => {
  return category.tagLookup?.find(
    (tagLookup: TagLookup) =>
      modifierSourceData?.tags?.find((tag) => tag === tagLookup.tagId) &&
      tagLookup.type === type
  );
};

export const prepareModifiersGroupTitle = (
  mapData: {
    subTitle?: string;
    maximumSelection?: number;
  },
  category: Category,
  modifierSourceData: ModifierLookup[],
  modifierData: OptionDataProps[]
): {
  subTitle: string;
  maxSelection: number;
} => {
  const maxSelectionLookup = findLookUpByType(
    category,
    modifierSourceData[0],
    TagType.MaxSelection
  );
  // default FE values
  let subTitle = mapData.subTitle;
  let maxSelection = mapData.maximumSelection;
  if (maxSelectionLookup?.attributes?.selection.max) {
    // configuration for modifier max limit found
    subTitle = subTitle?.replace(
      `${mapData.maximumSelection}`,
      maxSelectionLookup.attributes.selection.max
    );
    maxSelection = Number(maxSelectionLookup.attributes.selection.max);
  } else if (modifierData.length < mapData.maximumSelection || 0) {
    // configuration for less modifiers than default FE value
    subTitle = subTitle?.replace(
      `${mapData.maximumSelection}`,
      `${modifierData.length}`
    );
    maxSelection = modifierData.length;
  }
  return { subTitle, maxSelection };
};

/**
 * Generates customisation options sections based on various modifiers (addModifier, extraModifier, addFilling, extraFilling, removeModifier)
 * @param list
 * @param product
 * @param category
 * @param locale
 * @param withoutImage
 * @returns CustomizeOptionsDataProps
 */
const generateCustomisationSections = (
  list: string[],
  product: Product,
  category: Category,
  locale: locales
) => {
  const options: CustomizeOptionsDataProps[] = [];
  const customisationOptionMapper = getCustomisationOptionMapper(locale);

  list.forEach((p) => {
    const customisationOptions = product[p as keyof Product];
    if (customisationOptions) {
      const mapData = customisationOptionMapper[p];
      const optionIndex = options.findIndex((o) => o.title === mapData.title);
      if (optionIndex == -1) {
        // TODO: Extract in a separate util function
        const modifierSourceData = getModifierLookupsFromIds(
          product.id,
          customisationOptions as number[],
          category
        );
        const modifierData = modifierSourceData
          .map((n: ModifierLookup) => {
            const data: OptionDataProps = {
              element: {
                id: n.id,
                name: n.name,
                price:
                  mapData.key === productCustomizationKeyMapper.removeModifier
                    ? 0
                    : n.price,
                nutritionalInfo: n.nutritionalInfo,
                imageTopDownUrl: n.imageTopDownUrl,
                imageAngleUrl: n.imageAngleUrl,
                controlType: category.tagLookup?.find(
                  (tagLookup: TagLookup) =>
                    n.tags?.find((tag) => tag === tagLookup.tagId) &&
                    tagLookup.type === TagType.ControlType
                )?.value as ControlType,
                displayOrder: n.displayOrder,
              },
              disabled: false,
            };
            return data;
          })
          .sort(
            (a: OptionDataProps, b: OptionDataProps) =>
              a.element.displayOrder - b.element.displayOrder
          );

        /**
         * Subtitle is currently hard-coded depending on its maximumSelection
         * e.g = extraModifier
         * maximumSelection is 6, then subtitle is "Add up to 6 extras"
         */
        const { subTitle, maxSelection } = prepareModifiersGroupTitle(
          mapData,
          category,
          modifierSourceData,
          modifierData
        );

        const option: CustomizeOptionsDataProps = {
          title: mapData.title,
          subTitle: subTitle,
          id: mapData.key,
          displayOrder: mapData.displayOrder,
          isRequired: false,
          data: modifierData,
          isMultiple: mapData.isMultiple,
          maximumSelection: maxSelection,
          footerTitle: mapData.footerTitle,
        };
        options.push(option);
      } else {
        options[optionIndex].data = [
          ...options[optionIndex].data,
          ...getModifierLookupsFromIds(
            product.id,
            customisationOptions as number[],
            category
          )
            .map((n: ModifierLookup) => {
              const data: OptionDataProps = {
                element: {
                  id: n.id,
                  name: n.name,
                  price:
                    mapData.key === productCustomizationKeyMapper.removeModifier
                      ? 0
                      : n.price,
                  nutritionalInfo: n.nutritionalInfo,
                  imageTopDownUrl: n.imageTopDownUrl,
                  imageAngleUrl: n.imageAngleUrl,
                  displayOrder: n.displayOrder,
                },
                disabled: false,
              };
              return data;
            })
            .sort(
              (a: OptionDataProps, b: OptionDataProps) =>
                a.element.displayOrder - b.element.displayOrder
            ),
        ];
      }
    }
  });

  return options;
};

/**
 * generate UI component props from modifierGroups and combinedModifierGroups in selected product
 * @param product
 * @param locale
 * @returns CustomizeOptionsDataProps[]
 */
export const generateHotDrinkCustomisationOptionsData = (
  product: Product,
  locale: locales,
  category: Category
): CustomizeOptionsDataProps[] => {
  /**
   * TODO: Revisit hard-coded `withoutImage` with Ricky
   */

  let options: CustomizeOptionsDataProps[] = [];

  for (const name in product.modifierGroups) {
    const modifierGroup = product.modifierGroups[name];
    const min = parseInt(
      modifierGroup.tagLookup.attributes?.selection?.min ?? "0"
    );
    const max = parseInt(
      modifierGroup.tagLookup.attributes?.selection?.max ?? "1"
    );
    options.push({
      title: name.toUpperCase(),
      subTitle:
        parseInt(modifierGroup.tagLookup.attributes?.selection?.min) > 0
          ? locale === locales.AU
            ? orderManagementAU.required
            : orderManagementUS.required
          : undefined,
      id: name,
      isRequired:
        parseInt(modifierGroup.tagLookup.attributes?.selection?.min) > 0
          ? true
          : false,
      displayOrder: modifierGroup.modifierLookups[0].displayOrder,
      data: modifierGroup.modifierLookups
        .map((n: ModifierLookup) => {
          const data: OptionDataProps = {
            element: {
              id: n.id,
              name: n.name,
              price: n.price,
              nutritionalInfo: n.nutritionalInfo,
              withoutImage: true,
              displayOrder: n.displayOrder,
            },
            disabled: false,
          };
          return data;
        })
        .sort(
          (a: OptionDataProps, b: OptionDataProps) =>
            a.element.displayOrder - b.element.displayOrder
        ),
      isMultiple: min === 0 && max === 1 ? true : max > 1 ? true : false,
      maximumSelection: max,
      footerTitle: `${name.toUpperCase()}`,
    });
  }
  for (const key in product.combinedModifierGroups) {
    const combinedData = product.combinedModifierGroups[key];
    options.push({
      title: key.toUpperCase(),
      id: key,
      displayOrder: combinedData.modifierLookups[0].displayOrder,
      data: [],
      isRequired: false,
      combinedData: {
        combinedModifierQuantity: generateOptionPropsFromTagLookups(
          uniqBy(combinedData.combinedModifierQuantity, "tagId")
        ),
        modifierLookups: combinedData.modifierLookups,
        combinedModifierTypes: generateOptionPropsFromTagLookups(
          uniqBy(combinedData.combinedModifierTypes, "tagId")
        ),
      },
    });
  }

  options = options.sort((a, b) => a.displayOrder - b.displayOrder);

  const customisedSections = generateCustomisationSections(
    HOT_DRINKS_MODIFIERS,
    product,
    category,
    locale
  ).map((data) => {
    return {
      ...data,
      data: data.data.map((item) => {
        return {
          ...item,
          element: {
            ...item.element,
            withoutImage: true,
          },
        };
      }),
    };
  });

  options = [...options, ...customisedSections];

  return options;
};

export const generateCustomisationOptionsData = (
  product: Product,
  locale: locales,
  category: Category
): CustomizeOptionsDataProps[] => {
  const options: CustomizeOptionsDataProps[] = generateCustomisationSections(
    productCustomizationList,
    product,
    category,
    locale
  );

  const productRoute = getProductRoute(category.templateId);
  if (productRoute === ProductRoute.ComplexCustomisableWithMeal) {
    const mealData = category?.multiPart[0]?.multiPartSection;
    options.push({
      title:
        locale === locales.AU
          ? orderManagementAU.bundleMultipartCustomizeSectionTitle
          : orderManagementUS.bundleMultipartCustomizeSectionTitle,
      id: "customiseMeal",
      isRequired: false,
    });
    mealData?.forEach((m) => {
      options.push({
        title: m.name,
        id: m.name,
        data: [],
        multipartSection: m,
        isRequired: true,
      });
    });
  }

  return options.sort((a, b) => a.displayOrder - b.displayOrder);
};

interface GenerateMakeMealIdentifierDataParams {
  category: Category;
  categoryImageTopDownUrl?: string;
  categoryImageAngleUrl?: string;
  locale: locales;
  itemOnlyFromPrice?: number;
}
export const generateMakeMealIdentifierData = ({
  category,
  categoryImageTopDownUrl,
  categoryImageAngleUrl,
  locale,
  itemOnlyFromPrice,
}: GenerateMakeMealIdentifierDataParams): OptionDataProps<number>[] => {
  const localeData =
    locale === locales.AU ? orderManagementAU : orderManagementUS;

  const options: OptionDataProps<number>[] = [];

  // These configures data of MIAM Medium and MIAM Large that comes from BE response
  category?.multiPart?.forEach((n) => {
    let price = 0;
    n.multiPartSection.forEach((m) => {
      const priceList = m.products.map((mProduct) => mProduct.price);
      if (m.categories?.length) {
        priceList.push(parseFloat(getTheLowestPriceInCategories(m.categories)));
      }
      price += Math.min(...priceList);
    });
    options.push({
      element: {
        id: n.id,
        name: n.name,
        description: n.description,
        price: price,
        imageTopDownUrl: n.imageTopDownUrl,
        imageAngleUrl: n.imageAngleUrl,
        displayOrder: n.displayOrder + 1, // increment by one, since item only is first to display
      },
      disabled: false,
    });
  });

  /**
   * These configures data of Item Only option.
   * Currently hard-coded in FE because this isn't included in the commonSections
   * returned by BE.
   *
   * categoryImageTopDownUrl and categoryImageAngleUrl are images from the
   * main Category depending what the user selected.
   */
  options.push({
    element: {
      id: 0,
      name: localeData.selectMiamItemOnly,
      price: itemOnlyFromPrice || 0,
      imageTopDownUrl: categoryImageTopDownUrl,
      imageAngleUrl: categoryImageAngleUrl,
      displayOrder: 1,
      isItemOnly: true,
    },
    disabled: false,
  });

  return options.sort(
    (a: OptionDataProps, b: OptionDataProps) =>
      a.element.displayOrder - b.element.displayOrder
  );
};

export const generateMakeMealOptionData = (
  multiPartSection: MultipartSection
): OptionDataProps[] => {
  const options: OptionDataProps[] = [];
  const priceList = multiPartSection.products.map((n) => n.price);
  if (multiPartSection.categories?.length) {
    priceList.push(
      parseFloat(getTheLowestPriceInCategories(multiPartSection.categories))
    );
  }
  const minPrice = Math.min(...priceList);

  multiPartSection.products?.forEach((n) => {
    const productSizeAndTitle = getProductSizeAndTitle(n);
    options.push({
      element: {
        id: n.id,
        name: productSizeAndTitle.title || n.name,
        price: n.price - minPrice,
        nutritionalInfo: n.nutritionalInfo,
        imageTopDownUrl: n.imageTopDownUrl,
        imageAngleUrl: n.imageAngleUrl,
        displayOrder: n.displayOrder,
        size: productSizeAndTitle.size || undefined,
      },
      disabled: false,
    });
  });
  multiPartSection.categories?.forEach((n) => {
    const minPriceInCategory = Math.min(
      ...n.products.map((categoryProductItem) => categoryProductItem.price)
    );
    const price = minPriceInCategory - minPrice;
    options.push({
      element: {
        id: n.id,
        name: n.name,
        imageAngleUrl: n.imageAngleUrl,
        imageTopDownUrl: n.imageTopDownUrl,
        price: price > 0 ? price : 0,
        displayOrder: n.displayOrder,
      },
      disabled: false,
      categoryElement: n,
    });
  });
  return options.sort(
    (a: OptionDataProps, b: OptionDataProps) =>
      a.element.displayOrder - b.element.displayOrder
  );
};

export const getAutoSelectedMealOption = (
  mealOptionData: OptionDataProps[]
): OptionDataProps | undefined => {
  if (mealOptionData.length === 1 && !mealOptionData[0].disabled) {
    return mealOptionData[0];
  }
};

export const getPreselectedMiamOptionsId = (
  mealOptionData: ProductModuleModel.OptionDataProps[],
  preSelectedMiamParts: CartItem[]
): (string | number)[] => {
  const ids: (string | number)[] = [];
  preSelectedMiamParts.forEach((miamPart) => {
    for (const meal of mealOptionData) {
      let selectedMiamPartId: number | string = undefined;
      if (meal.categoryElement) {
        selectedMiamPartId = meal.categoryElement?.products?.find(
          (product) => product.id === miamPart.productId
        )?.id;
      } else {
        if (meal.element.id === miamPart.productId) {
          selectedMiamPartId = meal.element.id;
        }
      }
      if (selectedMiamPartId) {
        ids.push(selectedMiamPartId);
        break;
      }
    }
  });
  return ids;
};

export const getMinPriceInList = (items: (Category | Product)[]) => {
  const priceList = items.map((n) => {
    if (n.price) return n.price;
    return 0;
  });
  return Math.min(...priceList);
};

export const generateCustomizableOptionState = (
  category: Category
): CustomizableOptionState => {
  const initialState: CustomizableOptionState = {};
  category.categoryTags?.forEach((categoryTag: CategoryTags) => {
    initialState[categoryTag.type] = [];
  });
  return initialState;
};

export const generateMealOptionState = (
  multiPartSections: MultipartSection[]
): CustomizableOptionState => {
  const initialState: CustomizableOptionState = {};
  multiPartSections.forEach((m: MultipartSection) => {
    initialState[m.name] = [];
  });
  return initialState;
};

const mapMultipartForOptionData = (
  multiparts: MultiPart[]
): OptionDataProps[] => {
  const priceList = multiparts.map((m) => m.price);
  return multiparts
    .map((multipart): OptionDataProps => {
      return {
        element: {
          id: multipart.id,
          price: multipart.price - Math.min(...priceList),
          name: multipart.name,
          nutritionalInfo: null,
          imageAngleUrl: multipart.imageAngleUrl,
          imageTopDownUrl: multipart.imageTopDownUrl,
          withoutImage: false,
          displayOrder: multipart.displayOrder,
        },
        disabled: false,
      };
    })
    .sort((a, b) => a.element.displayOrder - b.element.displayOrder);
};

export const generateTacoOptionsData = (
  category: Category,
  locale: locales,
  selectedMultiPart: MultiPart | undefined,
  commonSection?: CommonSection,
  mealMultiPart?: MultiPart,
  selectedMealId?: number | undefined
): CustomizeOptionsDataProps[] => {
  const localeData =
    locale === locales.AU ? orderManagementAU : orderManagementUS;
  const customisationMapper = getCustomisationMapper(locale);
  const options: CustomizeOptionsDataProps[] = [];

  if (commonSection) {
    const customisationMapperData = customisationMapper[InternalTagType.MIAM];

    options.push({
      id: InternalTagType.MIAM,
      title: customisationMapperData.title,
      subTitle: localeData.required,
      data: [],
      commonSection,
      isRequired: true,
    });

    if (selectedMealId === undefined) {
      // Early return `options` if MIAM is available and no selectedMealId yet
      return options;
    }
  }

  const titleForNumberOfProduct = `${
    localeData.selectTacoSection
  } ${category.name.toUpperCase()}`;

  options.push({
    title: titleForNumberOfProduct,
    subTitle: localeData.required,
    id: category.name,
    displayOrder: 1,
    isRequired: true,
    data: mapMultipartForOptionData(category?.multiPart ?? []),
  });

  if (selectedMultiPart) {
    const titleForCustomizeProduct = `${
      locale === locales.AU
        ? orderManagementAU.customizeTacoSection
        : orderManagementUS.customizeTacoSection
    } ${category.name.toUpperCase()}`;

    options.push({
      title: titleForCustomizeProduct,
      id: selectedMultiPart.name,
      data: [],
      multiPart: selectedMultiPart,
      isRequired: true,
    });
    if (mealMultiPart) {
      mealMultiPart.multiPartSection.forEach((m) => {
        options.push({
          title: m.name,
          id: m.name,
          data: [],
          multipartSection: m,
          isRequired: true,
        });
      });
    }
  }
  return options;
};

/**
 * Extracts reverted toggle from remove modifier and
 * adds it to last required modifier section in data.
 * @param options customise options data
 */
const extractRevertedToggleModifierToRequiredSection = (
  options: CustomizeOptionsDataProps[]
): CustomizeOptionsDataProps[] => {
  const toggleModifiers = options
    ?.find((data) => data.id === SectionTitle.RemoveModifier)
    ?.data.filter((d) => d.element.controlType === ControlType.InvertedToggle);
  if (toggleModifiers && toggleModifiers.length > 0) {
    // take it out of 'remove ingridients'
    options.forEach((section) => {
      if (section.id === SectionTitle.RemoveModifier) {
        section.data.forEach((modi, index) => {
          if (modi.element.controlType === ControlType.InvertedToggle) {
            section.data.splice(index, 1);
          }
        });
      }
    });
    // add it to last section that's required
    const indexOfLastRequiredSection =
      options.filter((section) => section.isRequired).length - 1;
    options.forEach((section, index) => {
      if (section.isRequired && indexOfLastRequiredSection === index) {
        section.revertedToggleData = toggleModifiers;
      }
    });
  }
  return options;
};

export const generateOptionsData = (
  category: Category,
  locale: locales,
  product: Product | undefined,
  commonSection?: CommonSection,
  multiPart?: MultiPart,
  customizeOptions?: CustomizableOptionState | undefined,
  selectedMealId?: number | undefined
): CustomizeOptionsDataProps[] => {
  let options: CustomizeOptionsDataProps[] = generateIdentifierData(
    category,
    locale,
    customizeOptions,
    commonSection,
    selectedMealId
  );
  if (product) {
    const productRoute = getProductRoute(category.templateId);
    if (productRoute === ProductRoute.HotDrinks) {
      options = options.concat(
        generateHotDrinkCustomisationOptionsData(product, locale, category)
      );
    } else {
      options = options.concat(
        generateCustomisationOptionsData(product, locale, category)
      );
    }
    options = extractRevertedToggleModifierToRequiredSection(options);
    if (multiPart) {
      multiPart.multiPartSection.forEach((m) => {
        options.push({
          title: m.name,
          id: m.name,
          data: [],
          multipartSection: m,
          isRequired: true,
        });
      });
    }
  }
  return options;
};

/**
 * get ModifierLookup from combined tags (e.g. Sugar + Quantity)
 * @param modifierLookups
 * @param typeId
 * @param quantityId
 * @returns ModifierLookup | undefined
 */
export const getModifierFromCombinedIds = (
  modifierLookups: ModifierLookup[],
  typeId?: string | number,
  quantityId?: string | number
): ModifierLookup | undefined => {
  if (typeId && quantityId && modifierLookups) {
    return modifierLookups?.find((n: ModifierLookup) => {
      return (
        n.tags?.lastIndexOf(typeId as string) != -1 &&
        n.tags?.lastIndexOf(quantityId as string) != -1
      );
    });
  }
  return undefined;
};

const findSuitableProduct = (
  customizableOptionState: CustomizableOptionState,
  category: Category
): Product | undefined => {
  const tags = category.categoryTags
    ?.filter(
      (tag) =>
        customizableOptionState[tag.type] &&
        customizableOptionState[tag.type].length > 0
    )
    ?.map((tag) => String(customizableOptionState[tag.type][0]));
  const tagLookupIds = category.tagLookup
    ?.filter((tagLookup) => tags?.includes(tagLookup.value))
    ?.map((tag) => tag.tagId);
  const allCustomization = (product: Product) => {
    return tagLookupIds && product.tags
      ? tagLookupIds.every((tagLookupId) => product.tags.includes(tagLookupId))
      : false;
  };
  const anyCustomization = (product: Product) => {
    return tagLookupIds && product.tags
      ? tagLookupIds?.find((tagLookupId) => product.tags.includes(tagLookupId))
      : "";
  };

  const product =
    category.products?.find(allCustomization) ??
    category.products?.find(anyCustomization);
  if (product) {
    return product;
  } else {
    return undefined;
  }
};

export const getSelectedProduct = (
  state: CustomizableOptionState,
  category: Category
): Product | undefined => {
  let path = "";
  const categoryTags = category.categoryTags ? [...category.categoryTags] : [];
  categoryTags
    ?.sort((a, b) => {
      if (a.type > b.type) {
        return 1;
      } else {
        return -1;
      }
    })
    ?.forEach((tag) => {
      if (state[tag.type]) {
        path += `${tag.type}:${state[tag.type]};`;
      } else {
        return undefined;
      }
    });
  const id = category.categoryTagMapper?.[path] ?? undefined;
  const product = category.products?.find((p) => p.id === id);
  if (product) {
    return product;
  } else {
    return undefined;
  }
};

/**
 * generate CartItem for Bundles
 * @param bundleItems
 * @param requiredLength check whether all the required multiParts have been selected
 * @param category
 * @param quantity
 * @returns CartItem
 */
export const getBundleCartItem = (
  bundleItems: BundleState,
  requiredLength: number,
  category: MultiPart,
  quantity: number
): CartItem[] | undefined => {
  if (Object.keys(bundleItems).length != requiredLength) {
    return undefined;
  }
  for (const i in bundleItems) {
    if (!bundleItems[i]) {
      return undefined;
    }
  }
  const parts: CartItem[] = Object.values(bundleItems);
  return [
    {
      productId: category.id,
      parts: parts,
      quantity: quantity,
      price: category.price || 0,
      unitPrice: category.price || 0,
      name: category.name,
      menuFlowId: category.menuFlowId,
      allowModify: !!getProductRoute(category.templateId),
      labelForFavourite: category.name,
    },
  ];
};

export const generateMIAMcartItem = (
  multiPart: MultiPart,
  mealCustomizableOptions: MealCustomizableOptionState,
  mealRemoveModifierOptions: MealCustomizableOptionState,
  quantity: number,
  allowModify: boolean,
  requiredLength: number,
  MIAMItems?: BundleState
): CartItem | undefined => {
  const cartItem: CartItem = {
    productId: multiPart.id,
    menuFlowId: multiPart.menuFlowId,
    quantity: quantity,
    price: multiPart.price || 0,
    unitPrice: multiPart.price || 0,
    name: multiPart.name,
    allowModify: allowModify,
    parts: [],
    labelForFavourite: multiPart.name,
  };
  if (MIAMItems) {
    const customisableParts: CartItem[] = Object.values(MIAMItems);
    cartItem.parts = cartItem.parts.concat(customisableParts);

    const price = Object.values(MIAMItems).reduce(
      (currentTotal, item) => currentTotal + item.price,
      0
    );

    cartItem.price = price;
    cartItem.unitPrice = price;
  } else {
    for (const i in multiPart.multiPartSection) {
      const mps = multiPart.multiPartSection[i];
      const productId = mealCustomizableOptions[mps.name];
      const removeModifier = mps?.modifierLookup?.find(
        (n) => n.id === mealRemoveModifierOptions[mps.name]
      );
      const product = mps?.products?.find((p) => p.id === productId);
      if (product) {
        cartItem.parts.push({
          productId: product.id,
          quantity: 1,
          price: product.price || 0,
          unitPrice: product.price || 0,
          name: product.name,
          removeModifier: removeModifier ? [removeModifier] : [],
          labelForFavourite: product.name,
        });
      } else {
        if (!MIAMItems) {
          return undefined;
        }
      }
    }
  }

  // meal's parts should match required length
  if (cartItem.parts.length !== requiredLength) {
    return undefined;
  } else {
    return cartItem;
  }
};

export const getTacoCartItem = (
  tacoItems: BundleState,
  tacoRequiredLength: number,
  category: MultiPart | null | undefined,
  quantity: number,
  parentCategoryTemplateId?: string,
  multiPart?: MultiPart,
  mealCustomizableOptions?: MealCustomizableOptionState,
  mealRemoveModifierOptions?: MealCustomizableOptionState,
  MIAMItems?: BundleState,
  MIAMrequiredLength?: number
): CartItem[] | undefined => {
  if (!category) {
    return undefined;
  }
  if (Object.keys(tacoItems).length !== tacoRequiredLength) {
    return undefined;
  }
  for (const i in tacoItems) {
    if (!tacoItems[i]) {
      return undefined;
    }
  }

  const tacoItem: CartItem = {
    productId: category.id,
    parts: Object.values(tacoItems),
    quantity: quantity,
    price: 0,
    unitPrice: 0,
    name: category.name,
    menuFlowId: category.menuFlowId,
    allowModify: !!getProductRoute(parentCategoryTemplateId),
    labelForFavourite: category.name,
  };

  if (mealCustomizableOptions && multiPart && mealRemoveModifierOptions) {
    const MIAMcartItem = generateMIAMcartItem(
      multiPart,
      mealCustomizableOptions,
      mealRemoveModifierOptions,
      quantity,
      !!getProductRoute(parentCategoryTemplateId),
      MIAMrequiredLength,
      MIAMItems
    );
    tacoItem.miamItem = MIAMcartItem;

    if (!MIAMcartItem) {
      return undefined;
    }

    tacoItem.miamItem = MIAMcartItem;
  }
  return [tacoItem];
};

/**
 * Updates local state of meal options with products from MIAMItem.
 */
export const preselectMiamParts = (
  customisableSectionData: ProductModuleModel.CustomizeOptionsDataProps[],
  setMealCustomizeOptions: React.Dispatch<
    React.SetStateAction<ProductModuleModel.MealCustomizableOptionState>
  >,
  setMealRemoveModifierOptions: React.Dispatch<
    React.SetStateAction<ProductModuleModel.MealCustomizableOptionState>
  >,
  setPreSelectedMiamParts: React.Dispatch<
    React.SetStateAction<CartItem[] | undefined>
  >,
  MIAMItem?: CartReduxModels.BundleState,
  MIAMParts?: CartItem[],
  scrollToNext?: (crollIndex: number) => void
) => {
  if (MIAMItem && Object.keys(MIAMItem).length > 0) {
    const miamParts: CartItem[] = [];
    Object.entries(MIAMItem).forEach(
      (
        [key, value]: [string, CartReduxModels.CartItem],
        miamItemIndex: number
      ) => {
        miamParts.push(value);
        const sectionIndex = Number(key);
        const optionSection = customisableSectionData[sectionIndex];
        if (optionSection) {
          setMealCustomizeOptions(
            (state: ProductModuleModel.MealCustomizableOptionState) => {
              return {
                ...state,
                [optionSection.id]: value.productId,
              };
            }
          );

          // handles modifiers only for Sides
          if (miamItemIndex === 0) {
            const getRemoveModiId = value.removeModifier?.length
              ? value.removeModifier[0].id
              : undefined;
            if (getRemoveModiId) {
              setMealRemoveModifierOptions({
                [optionSection.id]: getRemoveModiId,
              });
            } else {
              setMealRemoveModifierOptions({});
            }
          }
          if (scrollToNext) scrollToNext(sectionIndex);
        }
      }
    );
    setPreSelectedMiamParts(miamParts);
  } else {
    setPreSelectedMiamParts(MIAMParts);
  }
};

/**
 *
 * @param CustomizeOptions
 * @param selectedProduct
 * @param category
 * @returns { [key: string]: ModifierLookup[] }
 */
const generateCustomisationObject = (
  CustomizeOptions: CustomizableOptionState,
  selectedProduct: Product,
  category: Category
) => {
  const customisation: { [key: string]: ModifierLookup[] } = {};

  for (const key in productCustomizationKeyMapper) {
    let ids = CustomizeOptions[productCustomizationKeyMapper[key]] as
      | number[]
      | undefined;
    // checks if modifier type exists in the selected products
    if (selectedProduct[key as keyof Product] as number[]) {
      ids = ids?.filter(
        (id) =>
          (selectedProduct[key as keyof Product] as number[])?.lastIndexOf(
            id
          ) != -1
      );
    } else {
      ids = [];
    }
    customisation[key] = getModifierLookupsFromIds(
      selectedProduct.id,
      ids,
      category
    );
  }

  return customisation;
};

export const getCustomizableCartItem = (
  CustomizeOptions: CustomizableOptionState,
  category: Category,
  quantity: number
): CartItem | undefined => {
  const selectedProduct = getSelectedProduct(CustomizeOptions, category);
  if (!selectedProduct) {
    return undefined;
  }
  const productRoute = getProductRoute(category.templateId);
  let customisation: { [key: string]: ModifierLookup[] } = {};
  if (productRoute === ProductRoute.HotDrinks) {
    for (const i in selectedProduct.requiredList) {
      if (
        CustomizeOptions[selectedProduct.requiredList[i]] &&
        CustomizeOptions[selectedProduct.requiredList[i]].length === 0
      ) {
        return undefined;
      }
    }

    customisation = generateCustomisationObject(
      CustomizeOptions,
      selectedProduct,
      category
    );

    for (const key in CustomizeOptions) {
      let ids = CustomizeOptions[key] as number[];
      ids = ids.filter(
        (id) => selectedProduct.addModifier?.lastIndexOf(id) != -1
      );
      customisation.addModifier = [
        ...customisation.addModifier,
        ...getModifierLookupsFromIds(selectedProduct.id, ids, category),
      ];
    }
  } else {
    customisation = generateCustomisationObject(
      CustomizeOptions,
      selectedProduct,
      category
    );
  }

  const cartItem: CartItem = {
    productId: selectedProduct.id,
    posPlu: selectedProduct.posPlu,
    ...customisation,
    quantity: quantity,
    price: selectedProduct.price * quantity,
    unitPrice: selectedProduct.price,
    name: selectedProduct.name,
    allowModify: !!productRoute,
    labelForFavourite: category.name,
  };
  return getCartItemWithUpdatedQuantity(cartItem, quantity);
};

export const getCartItem = (
  CustomizeOptions: CustomizableOptionState,
  category: Category,
  quantity: number,
  multiPart?: MultiPart,
  mealCustomizableOptions?: MealCustomizableOptionState,
  mealRemoveModifierOptions?: MealCustomizableOptionState,
  requiredLength?: number,
  MIAMItems?: BundleState
): CartItem[] | undefined => {
  if (mealCustomizableOptions && multiPart && mealRemoveModifierOptions) {
    const productRoute = getProductRoute(category.templateId);
    const MIAMcartItem = generateMIAMcartItem(
      multiPart,
      mealCustomizableOptions,
      mealRemoveModifierOptions,
      quantity,
      !!findTemplateRoute(category),
      requiredLength,
      MIAMItems
    );

    if (!MIAMcartItem) {
      return undefined;
    }

    const isLittleGBundle = category.name.includes("Little G");
    const mainItem = getCustomizableCartItem(
      CustomizeOptions,
      category,
      isLittleGBundle ? 1 : quantity
    );
    if (mainItem) {
      if (productRoute === ProductRoute.ComplexCustomisableWithMeal) {
        const newParts = [...MIAMcartItem.parts, mainItem];
        return [{ ...MIAMcartItem, parts: newParts }];
      } else {
        return [{ ...mainItem, miamItem: MIAMcartItem }];
      }
    } else {
      return undefined;
    }
  } else {
    const item = getCustomizableCartItem(CustomizeOptions, category, quantity);
    if (item) {
      return [item];
    } else {
      return undefined;
    }
  }
};

export const removeLastComma = (text: string): string => {
  text = text.replace(/,\s*$/, "");
  return text;
};

export const fomartCustomizationText = (customizationText: string): string => {
  if (customizationText) {
    customizationText = removeLastComma(customizationText);
    customizationText += ".";
    customizationText = customizationText.trim();
    return customizationText;
  }
  return customizationText;
};

export const addModifierLookUpNameToCustomizationTxt = (
  txtToModify: string,
  customisationCollection: ModifierLookup[],
  prefix?: string
) => {
  let newTxt = txtToModify;
  customisationCollection.forEach((n) => {
    if (prefix) {
      newTxt += ` ${prefix} ${n.name},`;
    } else {
      newTxt += ` ${n.name},`;
    }
  });
  return newTxt;
};

export const getHotDrinksCustomizationText = (
  category: Category,
  CustomizeOptions: CustomizableOptionState
): string => {
  let customizationText = category.description;
  category.categoryTags?.forEach((tag) => {
    if (CustomizeOptions[tag.type] && CustomizeOptions[tag.type].length > 0) {
      customizationText += ` ${CustomizeOptions[tag.type][0]},`;
    }
  });

  const product =
    findSuitableProduct(CustomizeOptions, category) ||
    (category.products ? category.products[0] : undefined);

  if (product === undefined) {
    SentryLoggerInstance.sentryCaptureCustomError(
      "[Common] common/modules/Products/utils.ts: Product not available",
      { category, CustomizeOptions }
    );
  }

  for (const key in CustomizeOptions) {
    const customisation = getModifierLookupsFromIds(
      product?.id ?? -1,
      CustomizeOptions[key] as number[],
      category
    );
    customizationText = addModifierLookUpNameToCustomizationTxt(
      customizationText,
      customisation
    );
  }
  customizationText = fomartCustomizationText(customizationText);
  return customizationText;
};

export const getCustomizationText = (
  category: Category,
  customizeOptions: CustomizableOptionState
): string => {
  const product: Product | undefined =
    findSuitableProduct(customizeOptions, category) ||
    (category.products ? category.products[0] : undefined);
  let customizationText = "";
  const defaultModifier = getModifierLookupsFromIds(
    product?.id ?? -1,
    product?.defaultModifier ?? [],
    category
  );
  defaultModifier.forEach((n) => {
    customizationText += ` ${n.name},`;
  });
  category.categoryTags?.forEach((tag) => {
    if (customizeOptions[tag.type] && customizeOptions[tag.type].length > 0) {
      customizationText += ` ${customizeOptions[tag.type][0]},`;
    }
  });
  customizationText = fomartCustomizationText(customizationText);
  return customizationText;
};

/**
 * Creates meal info to display in product details
 * and checks if meal is valid.
 * @param selectedMealId
 * @param mealCustomizeOptions
 * @param commonSection
 * @returns
 */
export const getMealText = (
  selectedMealId: number | undefined,
  mealCustomizeOptions: MealCustomizableOptionState,
  commonSection?: CommonSection
): { mealOptions: string; mealValid: boolean } => {
  let resultMealOptions;
  let mealValid;
  const multiPart = commonSection?.categories[0]?.multiPart?.find(
    (n) => n.id === selectedMealId
  );
  if (multiPart) {
    mealValid = true;
    let mealOptions = "";
    for (const i in multiPart.multiPartSection) {
      const mps = multiPart.multiPartSection[i];
      let product = mps?.products?.find(
        (p) => p.id === mealCustomizeOptions[mps.name]
      );
      if (!product) {
        mps?.categories?.forEach((category) => {
          const element = category.products?.find(
            (p) => p.id === mealCustomizeOptions[mps.name]
          );
          if (element) {
            product = element;
          }
        });
      }
      if (product) {
        if (parseInt(i) == 0) {
          mealOptions += `${product.name},`;
        } else {
          mealOptions += ` ${product.name},`;
        }
      } else {
        mealValid = false;
      }
    }
    mealOptions = removeLastComma(mealOptions);
    resultMealOptions = mealOptions || undefined;
  }
  return {
    mealOptions: resultMealOptions,
    mealValid,
  };
};

/**
 * Returns props for customizable product.
 * @param category
 * @param CustomizeOptions
 * @param quantity
 * @returns
 */
export const getCustomizableProductInfoProps = (
  category: Category,
  customizeOptions: CustomizableOptionState,
  quantity: number,
  mealCustomizeOptions: MealCustomizableOptionState,
  selectedMealId?: number,
  commonSection?: CommonSection
): ProductDetailProps => {
  const priceList = category.products?.map((n) => n.price) ?? [0];
  const productRoute = getProductRoute(category.templateId);
  let customizationText = "";
  if (productRoute === ProductRoute.HotDrinks) {
    customizationText = getHotDrinksCustomizationText(
      category,
      customizeOptions
    );
  } else {
    customizationText = getCustomizationText(category, customizeOptions);
  }

  let price = Math.min(...priceList);
  if (productRoute === ProductRoute.ComplexCustomisableWithMeal) {
    category?.multiPart?.[0]?.multiPartSection?.forEach((m) => {
      const subPriceList = m.products.map((n) => n.price);
      price += Math.min(...subPriceList);
    });
  }
  const result: ProductDetailProps = {
    name: category.name,
    quantity: quantity,
    price: price,
    customizationText: customizationText,
    imageAngleUrl: category.imageAngleUrl,
  };
  const extraFillingIngredients = getModifierLookupsFromIds(
    selectedMealId,
    (customizeOptions.extraFilling as number[]) || [],
    category
  );
  if (extraFillingIngredients?.length > 0) {
    let extraFillingOptions = "";
    extraFillingIngredients.forEach((n, i) => {
      if (i != 0) extraFillingOptions += " ";
      extraFillingOptions += n.name;
      if (i != extraFillingIngredients.length - 1) extraFillingOptions += ",";
    });
    result.extraFillingOptions = extraFillingOptions;
  }
  const extrasIngredients = getModifierLookupsFromIds(
    selectedMealId,
    (customizeOptions.extras as number[]) || [],
    category
  );
  if (extrasIngredients?.length > 0) {
    let extraOptions = "";
    extrasIngredients.forEach((n, i) => {
      if (i != 0) extraOptions += " ";
      extraOptions += n.name;
      if (i != extrasIngredients.length - 1) extraOptions += ",";
    });
    result.extraOptions = extraOptions;
  }
  const removeIngredients = getModifierLookupsFromIds(
    selectedMealId,
    (customizeOptions.removeIngredient as number[]) || [],
    category
  );
  if (removeIngredients?.length > 0) {
    let removeOptions = "";
    removeIngredients.forEach((n, i) => {
      if (i == 0) {
        removeOptions += `No ${n.name},`;
      } else {
        removeOptions += ` No ${n.name},`;
      }
    });
    removeOptions = removeLastComma(removeOptions);
    result.removeOptions = removeOptions;
  }
  if (selectedMealId && commonSection) {
    const mealTextOptions = getMealText(
      selectedMealId,
      mealCustomizeOptions,
      commonSection
    );
    result.mealOptions = mealTextOptions.mealOptions;
    result.mealValid = mealTextOptions.mealValid;
  }
  if (productRoute === ProductRoute.ComplexCustomisableWithMeal) {
    const mealMultipart = category?.multiPart[0];
    let mealOptions = "";
    for (const i in mealMultipart.multiPartSection) {
      const mps = mealMultipart.multiPartSection[i];
      const product = mps?.products?.find(
        (p) => p.id === mealCustomizeOptions[mps.name]
      );
      if (product) {
        if (parseInt(i) == 0) {
          mealOptions += `${product.name},`;
        } else {
          mealOptions += ` ${product.name},`;
        }
      } else {
        result.mealValid = false;
      }
    }
    mealOptions = removeLastComma(mealOptions);
    result.mealOptions = mealOptions || undefined;
  }
  return result;
};

export const getMultipartSectionCategoryFromCartItem = (
  cartItem: CartItem,
  catgories: Category[]
): Category | undefined => {
  return catgories?.find((catgory) => {
    return catgory.products?.find(
      (product) => product.id === cartItem.productId
    );
  });
};

/**
 * Extracts modifiers' id from modifier object returned from the cart.
 * @param data array of modifier object
 * @returns array of modifiers' id
 */
export const getIdsFromModifier = (data: Modifier[]): number[] => {
  if (!data) {
    return [];
  }
  return data.map((m) => m.id);
};

export const reloadHotDrinkCustomizableOptionStateFromCartItem = (
  cartItem: CartItem,
  category: Category
): CustomizableOptionState => {
  const result: CustomizableOptionState = {};
  cartItem.addModifier?.forEach((modifier) => {
    let modiTags;
    if (modifier.tags) {
      modiTags = modifier.tags;
    } else {
      modiTags = category.modifierLookup?.find(
        (modiL: ModifierLookup) => modiL.id === modifier.id
      ).tags;
    }
    modiTags?.forEach((tagId) => {
      const tag = category.tagLookup?.find((t) => t.tagId === tagId);
      if (
        tag.type === TagType.CoffeeModifierGroup ||
        tag.type === TagType.CombinedModifierGroup
      ) {
        if (result[tag.value]) {
          result[tag.value] = [...result[tag.value], modifier.id];
        } else {
          result[tag.value] = [modifier.id];
        }
      }
    });
  });
  return result;
};

export const mapModifiersFromCartItemToTemplate = (
  cartItemModifiers: Modifier[],
  product: Product,
  modifierKey: string,
  fillingKey: string
): { mappedModis: number[]; mappedFillings: number[] } => {
  const mappedModis: number[] = [];
  const mappedFillings: number[] = [];

  const productModi = product[modifierKey as keyof Product] as number[];
  const productFilling = product[fillingKey as keyof Product] as number[];

  cartItemModifiers.forEach((cartItemModi) => {
    const modiMatch = productModi?.find(
      (productAddModi) => productAddModi === cartItemModi.id
    );
    const fillingMatch = productFilling?.find(
      (productAddFilling) => productAddFilling === cartItemModi.id
    );
    if (modiMatch) {
      mappedModis.push(modiMatch);
    }
    if (fillingMatch) {
      mappedFillings.push(fillingMatch);
    }
  });

  return {
    mappedModis,
    mappedFillings,
  };
};

/**
 * Mapps cart item's add or extra modifiers and fillings
 * @param mode Either Add Modifier/Filling or Extra Modifier/Filling
 * @param cartItemModis The effective modifiers processed based on cart item
 * @param product The product
 * @param key any one of the productCustomizationKeyMapper keys (add/extra filling/modifier)
 * @param acc the accumulated result that is a CustomizableOptionState object
 * @returns `CustomizableOptionState`
 */
export const getAddOrExtraModifiersAndFillings = (
  mode: string,
  product: Product,
  key: string,
  acc: CustomizableOptionState,
  cartItemModis?: Modifier[]
) => {
  let modifierKey;
  let fillingKey;

  if (!cartItemModis) {
    return acc;
  } else if (mode === RETRIEVE_MODE.ADD) {
    modifierKey = "addModifier";
    fillingKey = "addFilling";
  } else if (mode === RETRIEVE_MODE.EXTRAS) {
    modifierKey = "extraModifier";
    fillingKey = "extraFilling";
  }

  const mappedAddModifiers = mapModifiersFromCartItemToTemplate(
    cartItemModis,
    product,
    modifierKey,
    fillingKey
  );

  let prevModifiers = [];
  let prevFillings = [];

  if (acc[productCustomizationKeyMapper[key]]) {
    prevModifiers = [...acc[productCustomizationKeyMapper[modifierKey]]];
    prevFillings = [...acc[productCustomizationKeyMapper[fillingKey]]];
  }

  acc[productCustomizationKeyMapper.addModifier] = [
    ...prevModifiers,
    ...mappedAddModifiers.mappedModis,
  ];
  acc[productCustomizationKeyMapper.addFilling] = [
    ...prevFillings,
    ...mappedAddModifiers.mappedFillings,
  ];

  return acc;
};

/**
 * Mapps cart item's modifiers into product's modifiers and fillings
 * @example addModifier -> addModifier
 * addFilling -> addFilling
 * extraModifier -> extraModifier
 * extraFilling -> extraFilling
 * removeModifier -> removeModifier
 * defaultFilling -> defaultFilling
 * @param cartItem
 * @param product
 * @returns `CustomizableOptionState`
 */
export const reloadCustomizableStateFromMapper = (
  cartItem: CartItem,
  product: Product
): CustomizableOptionState => {
  let result: CustomizableOptionState = {};
  for (const key in productCustomizationKeyMapper) {
    const cartItemModis = cartItem[key as keyof CartItem] as
      | Modifier[]
      | undefined;

    if (key === "addModifier" || key === "addFilling") {
      result = getAddOrExtraModifiersAndFillings(
        RETRIEVE_MODE.ADD,
        product,
        key,
        result,
        cartItemModis
      );
    } else if (key === "extraModifier" || key === "extraFilling") {
      result = getAddOrExtraModifiersAndFillings(
        RETRIEVE_MODE.EXTRAS,
        product,
        key,
        result,
        cartItemModis
      );
    } else {
      // removeModifier and defaultFilling
      if (result[productCustomizationKeyMapper[key]]) {
        result[productCustomizationKeyMapper[key]] = [
          ...result[productCustomizationKeyMapper[key]],
          ...getIdsFromModifier(cartItem[key as keyof CartItem] as Modifier[]),
        ];
      } else {
        result[productCustomizationKeyMapper[key]] = (
          cartItem[key as keyof CartItem] as Modifier[]
        )?.map((m) => m.id);
      }
    }
  }
  return result;
};

export const reloadCustomizableOptionWithMealStateFromCartItem = (
  cartItem: CartItem,
  category: Category
): ReloadOptionStates => {
  const result: ReloadOptionStates = {
    customizableOptionState: {},
    mealCustomizeOptions: {},
    mealRemoveModifierOptions: {},
  };
  for (const i in cartItem.parts || []) {
    const currentCartItem = cartItem.parts[i];
    const partCategory = getMultipartSectionCategoryFromCartItem(
      currentCartItem,
      (category?.multiPart?.[0]?.multiPartSection as unknown as Category[]) ??
        []
    );
    if (partCategory) {
      const product = partCategory.products?.find(
        (productItem) => productItem.id === currentCartItem.productId
      );
      if (!product) {
        continue;
      }
      if (currentCartItem.removeModifier?.[0]) {
        result.mealRemoveModifierOptions[partCategory.name] =
          currentCartItem.removeModifier[0].id;
      }
      result.mealCustomizeOptions[partCategory.name] = product.id;
    } else {
      // assume this is the main product because meal options only appear in multiPartSection for Little G meal
      const product = category.products?.find(
        (productItem) => productItem.id === currentCartItem.productId
      );
      if (!product) {
        continue;
      }
      product.tags?.forEach((tagId) => {
        const tag = category.tagLookup?.find((t) => t.tagId === tagId);
        if (tag) {
          result.customizableOptionState[tag.type] = [tag.value];
        }
      });
      result.customizableOptionState = {
        ...result.customizableOptionState,
        ...reloadCustomizableStateFromMapper(currentCartItem, product),
      };
    }
  }
  return result;
};

export const reloadCustomizableOptionStateFromCartItem = (
  cartItem: CartItem,
  category: Category
): ReloadOptionStates => {
  const result: ReloadOptionStates = {
    customizableOptionState: {},
    mealCustomizeOptions: {},
    mealRemoveModifierOptions: {},
  };
  const productRoute = getProductRoute(category.templateId);
  if (productRoute === ProductRoute.ComplexCustomisableWithMeal) {
    return reloadCustomizableOptionWithMealStateFromCartItem(
      cartItem,
      category
    );
  }
  const product = category.products?.find(
    (productItem) => productItem.id === cartItem.productId
  );
  if (!product) {
    return result;
  }
  product.tags?.forEach((tagId) => {
    const tag = category.tagLookup?.find((t) => t.tagId === tagId);
    if (tag) {
      result.customizableOptionState[tag.type] = [tag.value];
    }
  });

  if (productRoute === ProductRoute.HotDrinks) {
    result.customizableOptionState = {
      ...result.customizableOptionState,
      ...reloadHotDrinkCustomizableOptionStateFromCartItem(cartItem, category),
    };
  } else {
    result.customizableOptionState = {
      ...result.customizableOptionState,
      ...reloadCustomizableStateFromMapper(cartItem, product),
    };
  }

  return result;
};

export interface MultipartSelectionInfo {
  name: string;
  customizationText: string;
  removeText: string;
  imageUrl?: string;
}

/**
 * get basic info for MultipartItem by CartItem
 * @param category
 * @param cartItem
 * @returns MultipartSelectionInfo
 * @name - display the title for the MultipartItem card
 * @imageUrl - image to display in MultipartItem, using product.imageUrl for NonCustomization, category.imageTopDownUrl for the rest of templates
 * @customizationText - display the first line of customization text
 * @removeText - display the second line of customization text
 */
export const getMultipartSelectionInfoFromCartItem = (
  category: Category,
  cartItem: CartItem
): MultipartSelectionInfo => {
  const product = category.products?.find(
    (productItem) => productItem.id === cartItem.productId
  );
  const productRoute = getProductRoute(category.templateId as string);
  if (productRoute === ProductRoute.NonCustomization) {
    const data = getProductSizeAndTitle(product);
    return {
      name: data.title,
      customizationText: data.size,
      removeText: "",
      imageUrl: product.imageTopDownUrl,
    };
  }
  let customizationText = "";
  let removeText = "";
  const defaultModifier = getModifierLookupsFromIds(
    cartItem.productId,
    product?.defaultModifier ?? [],
    category
  );
  defaultModifier.forEach((n) => {
    customizationText += ` ${n.name},`;
  });
  // adds tags in customisation text beside for simple customisable template
  if (
    getProductRoute(category.templateId) !== ProductRoute.SimpleCustomisable
  ) {
    product?.tags?.forEach((tagId) => {
      const tag = category.tagLookup?.find((t) => t.tagId === tagId);
      if (tag) {
        customizationText += ` ${tag.value},`;
      }
    });
  } else {
    customizationText += "";
  }

  for (const key in productCustomizationKeyMapper) {
    // exclude removeIngredient as it will render in different place
    const customisation = getModifierLookupsFromIds(
      cartItem.productId,
      getIdsFromModifier(cartItem[key as keyof CartItem] as Modifier[]),
      category
    );
    if (productCustomizationKeyMapper[key] != "removeIngredient") {
      customizationText = addModifierLookUpNameToCustomizationTxt(
        customizationText,
        customisation
      );
    } else {
      removeText = addModifierLookUpNameToCustomizationTxt(
        removeText,
        customisation,
        "No"
      );
    }
  }
  customizationText = fomartCustomizationText(customizationText);
  removeText = fomartCustomizationText(removeText);

  return {
    name: category.name,
    customizationText: customizationText,
    removeText: removeText,
    imageUrl: category.imageTopDownUrl,
  };
};

export const flattenNonCustomizableProductFromMultipartSection = (
  category: Category
): (Category & Product)[] => {
  const data = category.products.map((product) => {
    return {
      ...product,
      ...{ tagLookup: category.tagLookup },
      templateId: category.templateId,
      imageTopDownUrl: category.imageTopDownUrl,
    };
  });
  return data as unknown as (Category & Product)[];
};

export interface ApplyAllValidObjects {
  index: number;
  product: Product;
}
/**
 * according to bundleItem and check the any other section contain ths same productId to apply same customization
 * @param category
 * @param bundleItem
 * @param excludeIndexs
 * @returns an Array of index that could apply the same customization
 */
export const applyAllValidObjects = (
  multiPart: MultiPart,
  bundleItem: CartItem | undefined,
  excludeIndexs: string[]
): ApplyAllValidObjects[] => {
  const result: ApplyAllValidObjects[] = [];
  if (!bundleItem?.posPlu) {
    return [];
  }
  for (const i in multiPart.multiPartSection) {
    multiPart.multiPartSection?.[i].categories.forEach((n) => {
      n.products?.forEach((product) => {
        if (
          product.posPlu === bundleItem.posPlu &&
          excludeIndexs.lastIndexOf(i) == -1
        ) {
          result.push({ index: parseInt(i), product: product });
        }
      });
    });
    multiPart.multiPartSection?.[i].products.forEach((p) => {
      if (
        p.posPlu === bundleItem.posPlu &&
        excludeIndexs.lastIndexOf(i) == -1
      ) {
        result.push({ index: parseInt(i), product: p });
      }
    });
  }
  return result;
};

export const getExcludedIndexs = (
  bundleItems: BundleState,
  currentIndex: number
): string[] => {
  const result: string[] = [currentIndex.toString()];
  for (const i in bundleItems) {
    if (bundleItems[i]) {
      result.push(i);
    }
  }
  return result;
};

/**
 * Returns the category of a product
 * @param productId
 * @param menuSection
 * @returns
 */
export const getProductCategory = (
  productId: number,
  menuSection: MenuSection | undefined
): string => {
  if (menuSection) {
    for (const subSection of menuSection?.subSections) {
      if (subSection.categories) {
        for (const category of subSection.categories) {
          if (category.products) {
            for (const product of category?.products) {
              if (product.id === productId) {
                return subSection.title;
              }
            }
          } else if (category?.multiPart) {
            for (const multiPart of category?.multiPart) {
              if (multiPart.id === productId) {
                return subSection.title;
              }
            }
          }
        }
      }
      if (subSection.products) {
        for (const product of subSection.products) {
          if (product.id === productId) {
            return subSection.title;
          }
        }
      }
    }
  }
};

export const getSelectedMiamOption = (
  sectionId: string,
  selectedId: number,
  mealMultipart?: MenuModels.MultiPart | undefined,
  preSelectedMiamParts?: CartReduxModels.CartItem[] | undefined
): CartItem | undefined => {
  const multipartSection = mealMultipart?.multiPartSection?.find(
    (s) => s.name === sectionId
  );

  let item: MenuModels.Product | undefined;
  let menuCategory: MenuModels.Category | undefined =
    multipartSection as unknown as MenuModels.Category;

  item = multipartSection?.products?.find((p) => p.id === selectedId);

  if (!item && multipartSection?.categories?.length) {
    for (const category of multipartSection.categories) {
      item = category.products?.find((p) => p.id === selectedId);
      if (item) {
        menuCategory = category;
        break;
      }
    }
  }
  if (menuCategory && item) {
    let optionCartItem: CartReduxModels.CartItem | undefined = createCartItem(
      menuCategory,
      item.id,
      1,
      true
    );
    if (optionCartItem) {
      const productId = optionCartItem.productId;
      const selectedItem = preSelectedMiamParts?.find(
        (cardItem) => cardItem.productId === productId
      );
      if (selectedItem) optionCartItem = selectedItem;
      return optionCartItem;
    }
  }
  return;
};

/**
 * returns the subsection id of category id
 * @param id category/multipart/product
 * @param menuSection
 * @returns
 */
export const getCategorySubsection = (
  id: number,
  menuSection: MenuSection
): number => {
  if (menuSection) {
    for (const subSection of menuSection?.subSections) {
      if (subSection.categories) {
        for (const category of subSection.categories) {
          if (category.id === id) {
            return subSection.id;
          }
        }
      } else if (subSection.multiParts) {
        for (const multipart of subSection.multiParts) {
          if (multipart.id === id) {
            return subSection.id;
          }
        }
      } else if (subSection.products) {
        for (const product of subSection.products) {
          if (product.id === id) {
            return subSection.id;
          }
        }
      }
    }
  }
};

export const getMultipartSectionImage = (
  multiPartSection: MultipartSection,
  selectedImage?: string
): string | undefined => {
  const newSectionImage = selectedImage
    ? selectedImage // image from selected category
    : multiPartSection.categories?.length
      ? multiPartSection.categories[0].imageTopDownUrl // image from first available category
      : multiPartSection.products?.length
        ? multiPartSection.products[0].imageTopDownUrl // image from first available product
        : undefined;
  return newSectionImage;
};

/**
 * Checks if selected product exists in selected multipart.
 * Applies when restoring MIAM selection in template.
 * @param section MIAM section
 * @param selectedProductId selected product id
 * @param selectedMIAMcategory selected MIAM object
 * @returns true or false
 */
export const checkIfSelectionExists = (
  section: CustomizeOptionsDataProps,
  selectedProductId: number,
  selectedMIAMcategory?: MultiPart
): boolean => {
  const sectionTarget = selectedMIAMcategory?.multiPartSection?.find(
    (multipartSection) => multipartSection.name === section.id
  );
  if (sectionTarget) {
    const category = sectionTarget.categories?.find(
      (sectionCategory) => sectionCategory.id === selectedProductId
    );
    const product = sectionTarget.products?.find(
      (sectionProduct) => sectionProduct.id === selectedProductId
    );
    return !!(category || product);
  } else {
    return false;
  }
};

/**
 * Sets nutritional info value based on selected product
 * or first nutritional info available in the catagory.
 * @param selectedProduct
 * @param radioButtonData
 * @returns
 */
export const getNutritionalInfo = (
  radioButtonData: RadioButtonGroupData<SimpleCustomisableProductRadioButton>[],
  selectedProduct?: SimpleCustomisableProductRadioButton
): string => {
  const data = radioButtonData
    .map((d) => d.element.nutritionalInfo)
    .filter((d) => !!d);
  const nutritionalInfo = selectedProduct?.nutritionalInfo
    ? selectedProduct?.nutritionalInfo
    : data.length
      ? data[0]
      : "";

  return nutritionalInfo;
};

/**
 * Get control type modifiers for a cart item from a menu multipart section.
 * @param multiPartSection
 * @param cartItem
 * @returns array of control type modifiers.
 */
export const getControlTypeModifiers = (
  multiPartSection: MultipartSection,
  cartItem: CartItem | undefined
): ModifierLookup[] | undefined => {
  if (
    multiPartSection.modifierLookup !== undefined &&
    multiPartSection.modifierLookup?.length > 0
  ) {
    const product = multiPartSection.products?.find(
      (n) => n.id === cartItem?.productId
    );
    const controlTypeTag = product?.tagLookup?.find(
      (tag) => tag.type === TagType.ControlType
    );
    if (controlTypeTag) {
      return multiPartSection.modifierLookup?.filter(
        (modifier) =>
          modifier.tags?.includes(controlTypeTag.tagId) &&
          product?.removeModifier?.includes(modifier.id)
      );
    }
  }
};

/**
 * Checks if the array contains modifier.
 * @param modifiers
 * @param modifier
 * @returns true if modifier exists, false otherwise.
 */
export const containsModifier = (
  modifiers: Modifier[],
  modifier: Modifier
): boolean => {
  return modifiers?.find((m) => m.id === modifier.id) !== undefined;
};

/**
 * Checks if cart item contains invalid modifier/s.
 * @param cartItem
 * @returns true if invalid modifier/s exists, false otherwise.
 */
export const containsInvalidModifier = (cartItem: CartItem) => {
  const invalidModifiers =
    cartItem.addModifier?.filter((item) => item?.isValid === false) ?? [];
  const extraFillings =
    cartItem.extraFilling?.filter((item) => item?.isValid === false) ?? [];
  return invalidModifiers.length > 0 || extraFillings.length > 0;
};

interface GetPreSelectedMealId {
  editMode: EditMode;
  cartItem?: CartItem;
}
export const getPreSelectedMealId = ({
  editMode,
  cartItem,
}: GetPreSelectedMealId) => {
  if (editMode === EditMode.ADD_CART_ITEM && !cartItem) {
    return undefined;
  }

  return cartItem?.miamItem?.productId ?? 0;
};

// Validations for Adding/Saving Products to Cart
interface ValidateMiamSelectionParams {
  selectedMealId?: number | undefined;
  isRequired: boolean;
}
export const validateMiamSelection = ({
  selectedMealId,
  isRequired,
}: ValidateMiamSelectionParams): boolean => {
  const isValid = isRequired ? selectedMealId !== undefined : true;

  return isValid;
};

interface ValidateTacoMultipartSelectionParams {
  selectedMultiPart: (string | number)[];
  isRequired: boolean;
}
export const validateTacoMultipartSelection = ({
  selectedMultiPart,
  isRequired,
}: ValidateTacoMultipartSelectionParams): boolean => {
  const isValid = isRequired ? selectedMultiPart.length > 0 : true;

  return isValid;
};

interface ValidateCustomisedTacoSelectionParams {
  tacoItem: BundleState;
  requiredTacoItemLength: number;
  isRequired: boolean;
}
export const validateCustomisedTacoSelection = ({
  tacoItem,
  requiredTacoItemLength,
  isRequired,
}: ValidateCustomisedTacoSelectionParams): boolean => {
  const isValid = isRequired
    ? Object.values(tacoItem).length >= requiredTacoItemLength
    : true;

  return isValid;
};

interface ValidateCustomSelectionParams {
  id: string;
  customizeOptions: CustomizableOptionState;
  mealCustomizeOptions: MealCustomizableOptionState;
  isRequired: boolean;
}
export const validateCustomSelection = ({
  id,
  customizeOptions,
  mealCustomizeOptions,
  isRequired,
}: ValidateCustomSelectionParams) => {
  // Taco
  const hasSelection =
    !!customizeOptions[id]?.length || !!mealCustomizeOptions[id];
  const isValid = isRequired ? hasSelection : true;

  return isValid;
};

export const findMultipart = (multiparts: MultiPart[], id: number | null) => {
  return multiparts?.find((multipart) => multipart.id === id);
};

export const mainProductName = (isMiam: boolean, cartItem: CartItem) => {
  return isMiam && cartItem.name
    ? cartItem.name.replace(/ *\([^)]*\) */g, "") + " Meal"
    : cartItem?.name;
};

export const associatedOffersExist = (cartItem: CartItem) =>
  (cartItem?.associatedOffers && cartItem?.associatedOffers?.length > 0) ??
  false;

export const discountsExist = (cartItem: CartItem) =>
  (cartItem?.productDiscountsApplied &&
    cartItem?.productDiscountsApplied?.length > 0 &&
    cartItem.displayText?.length) ??
  false;
