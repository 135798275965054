import { groupBy, remove } from "lodash";

import config from "config";
import { locales } from "gyg_common";

import {
  Category,
  CategoryTagMapper,
  CategoryTags,
  CombinedModifierGroups,
  CommonSection,
  MenuSection,
  MenuStructure,
  ModifierGroups,
  ModifierLookup,
  MultiPart,
  MultipartSection,
  Product,
  TagLookup,
  TagType,
} from "../../redux_store/menu/models";
import { SentryLoggerInstance } from "../../sentry/";
import {
  Category as CategoryResponse,
  CommonSection as CommonSectionResponse,
  MenuStructureResponse,
  MultiPart as MultiPartResponse,
} from "../../services/api/menu/model";
import { ProductRoute } from "../Products/const";
import { getModifierLookupsFromIds, getProductRoute } from "../Products/utils";

export interface CategoryTagsObject {
  categoryTags: CategoryTags[];
  categoryTagMapper: CategoryTagMapper;
}

export const addHotDrinkTags = (category: Category): Category => {
  for (const i in category.products) {
    const modifierGroups: ModifierGroups = {};
    const combinedModifierGroups: CombinedModifierGroups = {};
    const requiredList: string[] = [];
    const product = category.products[i];

    const modifiers = getModifierLookupsFromIds(
      product.id,
      product.addModifier,
      category
    );
    modifiers.forEach((modifier) => {
      if (modifier.tags) {
        const tagLookups = category.tagLookup?.filter(
          (t) => modifier.tags.lastIndexOf(t.tagId) != -1
        );
        tagLookups.forEach((tagLookup) => {
          if (parseInt(tagLookup?.attributes?.selection?.min) > 0) {
            requiredList.push(tagLookup.value);
          }
        });
        const groupedLookups = groupBy(tagLookups, "type");
        const modifierGroup = groupedLookups[TagType.CoffeeModifierGroup];
        if (modifierGroup) {
          if (modifierGroups[modifierGroup[0].value]) {
            modifierGroups[modifierGroup[0].value].modifierLookups = [
              ...modifierGroups[modifierGroup[0].value].modifierLookups,
              modifier,
            ];
          } else {
            modifierGroups[modifierGroup[0].value] = {
              tagLookup: modifierGroup[0],
              modifierLookups: [modifier],
            };
          }
        }
        const combinedModifierGroup =
          groupedLookups[TagType.CombinedModifierGroup];
        const combinedModifierQuantity =
          groupedLookups[TagType.CombinedModifierQuantity];
        const combinedModifierType =
          groupedLookups[TagType.CombinedModifierType];
        if (
          combinedModifierGroup &&
          combinedModifierQuantity &&
          combinedModifierType
        ) {
          const currentCombinedModifierGroups =
            combinedModifierGroups[combinedModifierGroup[0].value];
          if (currentCombinedModifierGroups) {
            combinedModifierGroups[combinedModifierGroup[0].value] = {
              combinedModifierQuantity: [
                ...currentCombinedModifierGroups.combinedModifierQuantity,
                ...combinedModifierQuantity,
              ],
              combinedModifierTypes: [
                ...currentCombinedModifierGroups.combinedModifierTypes,
                ...combinedModifierType,
              ],
              modifierLookups: [
                ...currentCombinedModifierGroups.modifierLookups,
                modifier,
              ],
            };
          } else {
            combinedModifierGroups[combinedModifierGroup[0].value] = {
              combinedModifierQuantity: combinedModifierQuantity,
              combinedModifierTypes: combinedModifierType,
              modifierLookups: [modifier],
            };
          }
        }
      }
    });
    category.products[i] = {
      ...product,
      requiredList: requiredList,
      modifierGroups: modifierGroups,
      combinedModifierGroups: combinedModifierGroups,
    };
  }
  return category;
};

export const getCategoryTagsData = (
  products: Product[],
  category: CategoryResponse
): CategoryTagsObject => {
  const categoryTags: CategoryTags[] = [];
  const categoryTagMapper: CategoryTagMapper = {};
  if (products) {
    products.forEach((product: Product) => {
      if (product.tags) {
        let path = "";
        const productTags: TagLookup[] = [];
        product.tags?.forEach((t: string) => {
          const tag = category.tagLookup?.find((n) => n.tagId === t);
          if (tag) {
            productTags.push(tag);
            const tagIndex = categoryTags.findIndex((c) => {
              return c.type === tag.type;
            });
            let imageAngleUrl;
            let imageTopDownUrl;
            let displayOrder;
            let tagAttribute;
            // sets main filling information in categoryTags based on defaultFilling
            // and it's details in modifierLookup,
            // if product doesn't have defaultFilling will fallback to extraFilling
            if (tag.type === TagType.Filling) {
              if (product.defaultFilling) {
                const mainFilling: ModifierLookup | undefined =
                  category.modifierLookup?.find(
                    (n) => n.id === product.defaultFilling[0]
                  );
                imageTopDownUrl = mainFilling?.imageTopDownUrl;
                imageAngleUrl = mainFilling?.imageAngleUrl;
                displayOrder = mainFilling?.displayOrder;
              } else if (product.extraFilling) {
                const mainFilling: ModifierLookup | undefined =
                  category.modifierLookup?.find(
                    (n) => n.id === product.extraFilling[0]
                  );
                imageTopDownUrl = mainFilling?.imageTopDownUrl;
                imageAngleUrl = mainFilling?.imageAngleUrl;
                displayOrder = mainFilling?.displayOrder;
              }
              tagAttribute = tag.tagAttribute?.fillingTitle;
            } else {
              displayOrder = tag.tagDisplayOrder;
            }

            if (tagIndex != -1) {
              const tagDataIndex = categoryTags[tagIndex].data.findIndex(
                (d) => {
                  return d.value === tag.value;
                }
              );
              if (tagDataIndex === -1) {
                categoryTags[tagIndex].data.push({
                  value: tag.value,
                  price: product.price,
                  nutritionalInfo: product.nutritionalInfo,
                  imageTopDownUrl: imageTopDownUrl,
                  imageAngleUrl: imageAngleUrl,
                  displayOrder: displayOrder,
                  tagAttribute: tagAttribute,
                });
              }
            } else {
              categoryTags.push({
                type: tag.type,
                data: [
                  {
                    value: tag.value,
                    price: product.price,
                    nutritionalInfo: product.nutritionalInfo,
                    imageTopDownUrl: imageTopDownUrl,
                    imageAngleUrl: imageAngleUrl,
                    displayOrder: displayOrder,
                    tagAttribute: tag.tagAttribute?.fillingTitle,
                  },
                ],
              });
            }
          }
        });
        productTags
          .sort((a, b) => {
            if (a.type > b.type) {
              return 1;
            } else {
              return -1;
            }
          })
          .forEach((n) => {
            path += `${n.type}:${n.value};`;
          });
        categoryTagMapper[path] = product.id;
      }
    });
  }
  return { categoryTags, categoryTagMapper };
};

export const AddCategoryTags = (category: CategoryResponse): Category => {
  const categoryTagsObject = getCategoryTagsData(category.products, category);
  const templateIdTag = category.tagLookup?.find((tagObj: TagLookup) =>
    category.tags?.find(
      (tag: string) =>
        tag === tagObj.tagId && tagObj.type === TagType.TemplateID
    )
  );
  const categoryTypeTag = category.tagLookup?.find((tagObj: TagLookup) =>
    category.tags?.find(
      (tag: string) =>
        tag === tagObj.tagId && tagObj.type === TagType.CategoryType
    )
  );
  const categoryWithSortedProducts = {
    ...category,
    products: category.products?.sort(
      (a, b) => a.displayOrder - b.displayOrder
    ),
  };
  return {
    ...(categoryWithSortedProducts as unknown as Category),
    categoryTags: categoryTagsObject.categoryTags,
    categoryTagMapper: categoryTagsObject.categoryTagMapper,
    templateId: templateIdTag?.value,
    categoryType: categoryTypeTag?.value,
  };
};

export const multipartSectionMapper = (
  multiPart: MultiPartResponse
): MultipartSection[] => {
  const result: MultipartSection[] = [];
  for (const multiPartSectionIndex in multiPart.multiPartSection) {
    const categories: Category[] = [];
    const multiPartSection = multiPart.multiPartSection[multiPartSectionIndex];
    const priceList = multiPartSection.products.map((n) => n.price);
    const price = Math.min(...priceList);
    const productTypeTags = multiPartSection.tagLookup.filter(
      (m) => m.type === TagType.ProductType
    );
    const templateIdTags = multiPartSection.tagLookup.filter(
      (m) => m.type === TagType.TemplateID
    );
    //group products by productTypeTags tag, and put them into Virtual Category, leave the rest products and treat them as NonCustomizable Product
    productTypeTags.forEach((tag, index) => {
      // remove products that don't have tags or don't have particular type tag,
      // returned array will create products[] of virtual category
      let products = remove(multiPartSection.products, (p) => {
        if (!p.tags) {
          return false;
        }
        if (p.tags?.lastIndexOf(tag.tagId) != -1) {
          return true;
        } else {
          return false;
        }
      });

      // return if no products match the type tag
      if (products.length <= 0) {
        return;
      }

      // assumes that template id should be the same for all grouped products
      const templateIdTag = templateIdTags.find(
        (t) => products[0].tags?.lastIndexOf(t.tagId) != -1
      );

      products = products.map((p) => {
        const templateIdindex = p.tags?.lastIndexOf(templateIdTag?.tagId);
        if (templateIdTag && templateIdindex != -1) {
          p.tags?.splice(templateIdindex, 1);
        }
        const productTypeIndex = p.tags?.lastIndexOf(tag?.tagId);
        if (tag && productTypeIndex != -1) {
          p.tags?.splice(productTypeIndex, 1);
        }
        p.imageAngleUrl = p.imageAngleUrl || undefined;
        p.imageTopDownUrl = p.imageTopDownUrl || undefined;
        return p;
      });
      // create a virtual category to navigate to each template
      const category: CategoryResponse = {
        products: products,
        id: index,
        name: tag.value,
        imageTopDownUrl: products[0].imageTopDownUrl || undefined,
        description: products[0].description || undefined,
        imageAngleUrl: products[0].imageAngleUrl || undefined,
        tags: [templateIdTag?.tagId],
        displayOrder: index,
        tagLookup: multiPartSection.tagLookup,
        modifierLookup: multiPartSection.modifierLookup,
      };
      // customisable MIAM option supports hot drinks, complex customisation template
      let categoryExpected = AddCategoryTags(category);
      categoryExpected.modifierOverrideLookup =
        multiPartSection.modifierOverrideLookup;
      const productRoute = getProductRoute(categoryExpected.templateId);
      if (productRoute === ProductRoute.HotDrinks) {
        categoryExpected = addHotDrinkTags(categoryExpected);
      }
      categories.push(categoryExpected);
    });
    if (categories.length > 0 || multiPartSection.products.length > 0) {
      result.push({
        price: price,
        categories: categories,
        name: multiPartSection.name,
        imageTopDownUrl: multiPartSection.imageTopDownUrl || undefined,
        imageAngleUrl: multiPartSection.imageAngleUrl || undefined,
        products: multiPartSection.products
          .map((p: Product) => {
            p.tagLookup = multiPartSection.tagLookup;
            return p;
          })
          .sort((a, b) => a.displayOrder - b.displayOrder),
        modifierLookup: multiPartSection.modifierLookup,
        displayOrder: multiPartSection.displayOrder,
      });
    }
  }
  return result;
};

export const mapComplexCustomisationWithMeal = (
  category: Category
): Category[] => {
  const categories: Category[] = [];
  if (category?.multiPart) {
    category?.multiPart.forEach((multiPart) => {
      const sortMultiPartSection = multiPart.multiPartSection.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );
      const mainCategory = sortMultiPartSection.splice(0, 1)[0];

      // lowest product price + lowest side price + lowest drink price
      let multipartPrice = 0;
      const mainCategoryPriceList = mainCategory.products.map((n) => n.price);
      const minCategoryPrice = Math.min(...mainCategoryPriceList);
      multipartPrice += minCategoryPrice;
      multiPart.multiPartSection.forEach((section) => {
        const priceList = section.products.map((n) => n.price);
        const minSectionPrice = Math.min(...priceList);
        multipartPrice += minSectionPrice;
      });

      const multipartWithPrice = {
        ...multiPart,
        price: multipartPrice,
      };

      const virtualCategory: Category = {
        id: multiPart.id,
        name: multiPart.name,
        tags: category.tags,
        description: multiPart.description,
        imageAngleUrl: multiPart.imageAngleUrl,
        imageTopDownUrl: multiPart.imageTopDownUrl,
        products: mainCategory.products,
        tagLookup: [...category.tagLookup, ...mainCategory.tagLookup],
        modifierLookup: mainCategory.modifierLookup,
        modifierOverrideLookup: mainCategory.modifierOverrideLookup || [],
        displayOrder:
          // TODO: Revisit this when menu for AU is fixed to have unique display order for all items under category
          config.version === locales.US
            ? category.displayOrder
            : multiPart.displayOrder,
        multiPart: [multipartWithPrice],
      };
      categories.push(
        AddCategoryTags(virtualCategory as unknown as CategoryResponse)
      );
    });
  }

  return categories.sort((a, b) => a.displayOrder - b.displayOrder);
};

export const flattenMultiParts = (category: Category): MultiPart[] => {
  const multiParts: MultiPart[] = [];
  category?.multiPart.forEach((m) => {
    const multiPartSections = multipartSectionMapper(m as MultiPartResponse);
    const priceList = multiPartSections.map((n) => n.price);
    multiParts.push({
      id: m.id,
      name: m.name,
      menuFlowId: m.menuFlowId,
      multiPartSection: multiPartSections.sort(
        (a, b) => a.displayOrder - b.displayOrder
      ),
      description: m.description,
      price: priceList.reduce((a, b) => a + b, 0),
      tags: category.tags,
      tagLookup: category.tagLookup,
      imageAngleUrl: m.imageAngleUrl || undefined,
      imageTopDownUrl: m.imageTopDownUrl || undefined,
      templateId: category.templateId,
      displayOrder:
        config.version === locales.US ? category.displayOrder : undefined,
    });
  });

  return multiParts.sort((a, b) => a.displayOrder - b.displayOrder);
};

export const mapTacoCategory = (category: Category): Category => {
  const prices: number[] = [];
  for (const mulitPartIndex in category?.multiPart) {
    const multiPart = category?.multiPart[mulitPartIndex];
    let multiPartPrice = 0;
    for (const mulitPartSectionIndex in multiPart.multiPartSection) {
      const mulitPartSection =
        multiPart.multiPartSection[mulitPartSectionIndex];
      // create a virtual category to navigate to each template
      const categories: Category[] = [];
      const product = mulitPartSection.products?.[0];
      const virtualCategory: CategoryResponse = {
        products: mulitPartSection.products,
        id: parseInt(mulitPartSectionIndex),
        name: mulitPartSection.name,
        imageTopDownUrl: product?.imageTopDownUrl,
        description: product?.description,
        imageAngleUrl: product?.imageAngleUrl,
        tags: [],
        displayOrder: mulitPartSection.displayOrder,
        tagLookup: mulitPartSection.tagLookup,
        modifierLookup: mulitPartSection.modifierLookup,
      };
      const categoryExpected = AddCategoryTags(virtualCategory);
      categoryExpected.templateId = category.templateId;
      const priceList = categoryExpected.products.map((n) => n.price);
      const minPrice = Math.min(...priceList);
      categoryExpected.price = minPrice;
      categories.push(categoryExpected);
      mulitPartSection.categories = categories.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );
      mulitPartSection.price = minPrice;
      category.multiPart[mulitPartIndex].multiPartSection[
        mulitPartSectionIndex
      ] = mulitPartSection;
      multiPartPrice += minPrice;
    }
    category.multiPart[mulitPartIndex].price = multiPartPrice;
    category?.multiPart[mulitPartIndex].multiPartSection.sort(
      (a, b) => a.displayOrder - b.displayOrder
    );
    prices.push(multiPartPrice);
  }
  category.price = Math.min(...prices);
  return category;
};

export const flattenNonCustomisableProducts = (
  category: Category
): Product[] => {
  let products: Product[] = [];
  const tags = category.tagLookup;
  if (category.products) {
    // adds tagLookup for each non customisable product
    const p = category.products.map((product) => {
      return { ...product, ...{ tagLookup: tags } };
    });
    products = products.concat(p);
  }
  return products.sort((a, b) => a.displayOrder - b.displayOrder);
};

export const ConvertMenuStructure = (
  menuStructureResponse: MenuStructureResponse
): MenuStructure => {
  const menuStructure: MenuStructure = {
    store: menuStructureResponse.store,
    sections: [],
    commonSections: menuStructureResponse.commonSections as CommonSection[],
    categoryLookup: {},
    posMenuId: menuStructureResponse.menu.posMenuId,
  };

  menuStructureResponse.commonSections.map(
    (section: CommonSectionResponse, commonSectionIndex) => {
      section.categories?.forEach(
        (category: CategoryResponse, categoryIndex) => {
          category?.multiPart?.forEach(
            (multipart: MultiPartResponse, multipartIndex) => {
              // assigns MIAM category tagLookup to multipart tagLookup
              menuStructure.commonSections[commonSectionIndex].categories[
                categoryIndex
              ].multiPart[multipartIndex].tagLookup =
                menuStructure.commonSections[commonSectionIndex].categories[
                  categoryIndex
                ].tagLookup;

              const multiPartSections = multipartSectionMapper(multipart);
              menuStructure.commonSections[commonSectionIndex].categories[
                categoryIndex
              ].multiPart[multipartIndex].multiPartSection =
                multiPartSections.sort(
                  (a, b) => a.displayOrder - b.displayOrder
                );

              menuStructure.categoryLookup[multipart.id] =
                `commonSections/${commonSectionIndex}/categories/${categoryIndex}/multiPart/${multipartIndex}`;
            }
          );
        }
      );
    }
  );

  for (const sectionIndex in menuStructureResponse.sections) {
    const section = menuStructureResponse.sections[sectionIndex] as MenuSection;
    // Sort subSections by displayOrder
    section.subSections = section.subSections.sort(
      (a, b) => a.displayOrder - b.displayOrder
    );
    menuStructure.sections[sectionIndex] = section as unknown as MenuSection;
    for (const subsectionIndex in section.subSections) {
      const subsection = section.subSections[subsectionIndex];

      const categoryIndexToRemove: string[] = [];
      for (const categoryIndex in subsection.categories) {
        const category = subsection.categories[
          categoryIndex
        ] as CategoryResponse;

        let categoryExpected: Category;
        try {
          categoryExpected = AddCategoryTags(category);
          const productRoute = getProductRoute(categoryExpected.templateId);
          if (productRoute === ProductRoute.HotDrinks) {
            categoryExpected = addHotDrinkTags(categoryExpected);
          }
          if (productRoute === ProductRoute.ComplexCustomisableWithMeal) {
            //KIDS SECTION WITH MEAL

            categoryIndexToRemove.push(categoryIndex);

            const complexMealCategory =
              mapComplexCustomisationWithMeal(categoryExpected);

            const newCategory =
              menuStructure.sections[sectionIndex].subSections[
                subsectionIndex
              ].categories.concat(complexMealCategory);

            menuStructure.sections[sectionIndex].subSections[
              subsectionIndex
            ].categories = newCategory;
          }
          if (
            category?.multiPart &&
            (productRoute === ProductRoute.Bundles ||
              productRoute === ProductRoute.KidsBundle)
          ) {
            //BUNDLES SECTION

            categoryIndexToRemove.push(categoryIndex);

            const expectedMultiparts = flattenMultiParts(categoryExpected);
            if (
              menuStructure.sections[sectionIndex].subSections[subsectionIndex]
                .multiParts
            ) {
              const newMultiparts =
                menuStructure.sections[sectionIndex].subSections[
                  subsectionIndex
                ].multiParts.concat(expectedMultiparts);
              menuStructure.sections[sectionIndex].subSections[
                subsectionIndex
              ].multiParts = newMultiparts;
            } else {
              menuStructure.sections[sectionIndex].subSections[
                subsectionIndex
              ].multiParts = expectedMultiparts;
            }
          }
          if (category?.multiPart && productRoute === ProductRoute.Tacos) {
            categoryExpected = mapTacoCategory(categoryExpected);
          }
          // maps category that is returning non customisable products
          if (productRoute === ProductRoute.NonCustomization) {
            categoryIndexToRemove.push(categoryIndex);

            // flattens products
            const categoryProducts =
              flattenNonCustomisableProducts(categoryExpected);
            menuStructure.sections[sectionIndex].subSections[
              subsectionIndex
            ].products = categoryProducts;
          } else {
            menuStructure.sections[sectionIndex].subSections[
              subsectionIndex
            ].categories[categoryIndex] = categoryExpected;
          }
        } catch (error) {
          SentryLoggerInstance.sentryCaptureCustomError(
            "ConvertMenuStructure: menu structure mapping error:",
            error
          );
        }
      }

      // removes category that is flatten out
      for (const i of categoryIndexToRemove.sort().reverse()) {
        menuStructure.sections[sectionIndex].subSections[
          subsectionIndex
        ].categories.splice(parseInt(i), 1);
      }

      // Sort categories by displayOrder
      subsection.categories = subsection.categories?.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );
      subsection.products = subsection.products?.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );
      subsection.multiParts = subsection.multiParts?.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );

      // creates paths in categoryLookup
      subsection.categories?.forEach((category, categoryIndex) => {
        const productRoute = getProductRoute(category.templateId);
        if (category?.multiPart && productRoute === ProductRoute.Tacos) {
          category?.multiPart.forEach((multipart) => {
            menuStructure.categoryLookup[multipart.id] =
              `sections/${sectionIndex}/subSections/${subsectionIndex}/categories/${categoryIndex}`;
          });
        } else if (
          category.products &&
          productRoute !== ProductRoute.ComplexCustomisableWithMeal
        ) {
          category.products.forEach((product) => {
            menuStructure.categoryLookup[product.id] =
              `sections/${sectionIndex}/subSections/${subsectionIndex}/categories/${categoryIndex}`;
          });
        } else {
          menuStructure.categoryLookup[category.id] =
            `sections/${sectionIndex}/subSections/${subsectionIndex}/categories/${categoryIndex}`;
        }
      });
      subsection.multiParts?.forEach((multiPart, multiPartIndex) => {
        menuStructure.categoryLookup[multiPart.id] =
          `sections/${sectionIndex}/subSections/${subsectionIndex}/multiParts/${multiPartIndex}`;
      });
      subsection.products?.forEach((product, productIndex) => {
        menuStructure.categoryLookup[product.id] =
          `sections/${sectionIndex}/subSections/${subsectionIndex}/products/${productIndex}`;
      });
    }
  }

  return menuStructure;
};

export const getProductTypeTag = (
  tagLookup: TagLookup[],
  tags: string[]
): TagLookup | undefined => {
  for (const i in tags) {
    const tag = tagLookup.find(
      (tagLookUpData) => tagLookUpData.tagId === tags[i]
    );
    if (tag && tag.type === TagType.ProductType) {
      return tag;
    }
  }
};

export const getCategoryTagMapperPath = (
  tags: string[],
  tagLookup: TagLookup[]
): string => {
  let path = "";
  tags.forEach((tagId: string) => {
    const tag = tagLookup.find((t) => t.tagId === tagId);
    if (tag) {
      path += `${tag.type}:${tag.value};`;
    }
  });
  return path;
};
