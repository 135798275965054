/* eslint-disable @typescript-eslint/no-explicit-any */
import { CartItem } from "../../redux_store/cart/models";
import {
  FavouriteBasketItem,
  FavouriteCartItem,
  GetFavouriteResponse,
} from "../../redux_store/favourite/models";
import {
  Category,
  MenuStructure,
  MultiPart,
  MultipartSection,
  Product,
} from "../../redux_store/menu/models";
import { jsonPathAccessor } from "../Menu/utils";
import { getModifierFromLookup } from "../Order/utils";

interface MappedFavourite {
  item: CartItem;
  imageTopDownUrl?: string;
  imageAngleUrl?: string;
}

/**
 * Gets all products from multi-part data, to be used for cross checking
 * if the previous selected multi-part product exists in the new menu structure.
 */
export const getAllProductsFromMultipart = (
  multipartData: MultipartSection[]
) => {
  return multipartData.flatMap((multipart) => multipart.products);
};

/**
 * Cross checks current selected multi-part product on the new menu structure.
 * Retains the product if it's exist, otherwise remove.
 */
export const filterCartItemPartsBasedOnMultipartProducts = (
  parts: FavouriteBasketItem[],
  multiPartProducts: Product[]
): CartItem[] => {
  const partsData = [];

  parts.forEach((part) => {
    const data = multiPartProducts.find(
      (multiPartProduct) => multiPartProduct.id === part.id
    );

    if (data) {
      partsData.push({ ...part, productId: part.id });
    }
  });

  return partsData;
};

export const mapFavouriteBasketItemToCart = (
  item: FavouriteBasketItem,
  menuStructure: MenuStructure,
  parentMultipart?: FavouriteBasketItem
): MappedFavourite => {
  let objPath: any;
  let category: any;
  let directCategory: any;

  // gets original product object from menu that will be the source of the price
  let productObj: any;
  if (parentMultipart) {
    objPath =
      menuStructure.categoryLookup[parentMultipart.id]?.split("/") ?? [];
    category = jsonPathAccessor(objPath, menuStructure) as MultiPart;

    if (category?.multiPartSection?.length > 0) {
      // Family Fiesta parts
      category.multiPartSection?.forEach((s: MultipartSection) => {
        if (s.categories?.length > 0) {
          // Customisable Product (Burrito, Bowl)
          s.categories.forEach((c) => {
            const match = c.products?.find((p) => p.id === item.id);
            if (!productObj && match) {
              productObj = match;
              directCategory = c;
            }
          });
        } else {
          // Non-customisable product (Souce, Side)
          const match = s.products.find((p) => p.id === item.id);
          if (match) {
            productObj = match;
            directCategory = s;
          }
        }
      });
    } else {
      // Little G Meal parts, Taco parts
      productObj = category?.products?.find(
        (product: Product) => product.id === item.id
      );
      directCategory = category;

      if (!productObj) {
        category?.multiPart?.forEach((mp: MultiPart) => {
          mp.multiPartSection?.forEach((mps: MultipartSection) => {
            const match = mps.products?.find((p) => p.id === item.id);
            if (match) {
              productObj = match;
              if (category?.multiPart?.length > 1) {
                // Customisable Product (Taco)
                directCategory = mps;
              }
            }
          });
        });
      }
    }
  } else {
    objPath = menuStructure.categoryLookup[item.id]?.split("/") ?? [];
    category = jsonPathAccessor(objPath, menuStructure) as Category;
    directCategory = category;
    productObj =
      (category?.products?.find(
        (product: Product) => product.id === item.id
      ) as Product) || (category as Category);

    // Main bundle product
    if (category?.multiPart) {
      productObj = category?.multiPart?.find(
        (m: MultiPart) => m.id === item.id
      );
    }
  }

  if (productObj) {
    const multipartProducts = getAllProductsFromMultipart(
      productObj?.multiPartSection ?? []
    );

    const newCartItem: CartItem = {
      productId: item.id,
      name: productObj.name,
      miamGUID: item.miamGUID,
      isMiam: item.miamGUID ? true : false,
      quantity: item.quantity || 1,
      unitPrice: productObj?.price || 0,
      price: productObj?.price || 0,
      menuFlowId: productObj?.menuFlowId,
      posPlu: productObj?.posPlu,
    };

    if (item.parts && item.parts.length > 0) {
      newCartItem.parts =
        item.parts.length > 0
          ? filterCartItemPartsBasedOnMultipartProducts(
              item.parts,
              multipartProducts
            )
          : [];
    }

    if (item.addFillings) {
      newCartItem.addFilling = getModifierFromLookup(
        item.addFillings,
        directCategory.modifierLookup
      );
    }
    if (item.removeModifiers) {
      newCartItem.removeModifier = getModifierFromLookup(
        item.removeModifiers,
        directCategory.modifierLookup
      );
    }
    if (item.extraModifiers) {
      newCartItem.extraModifier = getModifierFromLookup(
        item.extraModifiers,
        directCategory.modifierLookup
      );
    }
    if (item.addModifiers) {
      newCartItem.addModifier = getModifierFromLookup(
        item.addModifiers,
        directCategory.modifierLookup
      );
    }

    return {
      item: newCartItem,
      imageAngleUrl: category.imageAngleUrl,
      imageTopDownUrl: category.imageTopDownUrl,
    };
  } else {
    return {
      item: null,
      imageAngleUrl: undefined,
      imageTopDownUrl: undefined,
    };
  }
};

export const mapCartItemParts = (
  cartItem: FavouriteBasketItem,
  menuStructure: MenuStructure
): CartItem[] => {
  if (!cartItem.parts) {
    return [];
  }

  return cartItem.parts
    .map((cartItemPart) => {
      return mapFavouriteBasketItemToCart(cartItemPart, menuStructure, cartItem)
        .item;
    })
    .filter((part) => part !== null);
};

export const getMiamCartItem = (
  miamItem: FavouriteBasketItem,
  menuStructure: MenuStructure
): CartItem => {
  const miamProductMapped = mapFavouriteBasketItemToCart(
    miamItem,
    menuStructure
  );

  return {
    ...miamProductMapped.item,
    parts: miamItem.parts
      ? mapCartItemParts(miamItem, menuStructure)
      : undefined,
  };
};

interface FormatBasketItemToCartParams {
  basketItem: FavouriteBasketItem;
  miamItem?: FavouriteBasketItem;
  menuStructure: MenuStructure;
}
interface FormatBasketItemToCartReturn {
  cartItem: CartItem;
  miamItem?: CartItem;
  mainImgTopDownUrl: string;
  mainImgAngleUrl: string;
}
const formatBasketItemToCart = ({
  basketItem,
  miamItem,
  menuStructure,
}: FormatBasketItemToCartParams): FormatBasketItemToCartReturn => {
  let miamCartItem: CartItem | null = null;

  if (miamItem) {
    miamCartItem = getMiamCartItem(miamItem, menuStructure);
  }

  const mainProductMapped = mapFavouriteBasketItemToCart(
    basketItem,
    menuStructure
  );

  if (mainProductMapped.item) {
    return {
      cartItem: {
        ...mainProductMapped.item,
        parts: basketItem.parts
          ? mapCartItemParts(basketItem, menuStructure)
          : undefined,
      },
      miamItem: miamCartItem,
      mainImgTopDownUrl: mainProductMapped.imageTopDownUrl,
      mainImgAngleUrl: mainProductMapped.imageAngleUrl,
    };
  }
};

export const getFavouriteProducts = (
  menuStructure: MenuStructure,
  favourites: GetFavouriteResponse[]
): FavouriteCartItem[] => {
  const favouriteCartItems: FavouriteCartItem[] = [];

  favourites.forEach((favourite: GetFavouriteResponse) => {
    const { cartItem, mainImgAngleUrl, mainImgTopDownUrl, miamItem } =
      formatBasketItemToCart({
        basketItem: favourite.basketItems[0],
        miamItem: favourite.basketItems[1] || null,
        menuStructure,
      });

    const favouriteCartItem: FavouriteCartItem = {
      favouriteId: favourite.favouriteId,
      label: favourite.label,
      cartItem: {
        ...cartItem,
        isMiam: miamItem?.miamGUID ? true : false,
      },
      miamItem,
      imageTopDownUrl: mainImgTopDownUrl,
      imageAngleUrl: mainImgAngleUrl,
    };

    favouriteCartItems.push(favouriteCartItem);
  });

  return favouriteCartItems;
};
