import { sortBy, uniqBy } from "lodash";
import moment from "moment-timezone";

import {
  Category,
  CommonSection,
  FeaturedItemType,
  MenuSection,
  MenuSubsection,
  Product,
  TagLookup,
  TagType,
  TimePeriod,
} from "../../redux_store/menu/models";

export interface FallBackTimeProps {
  fallBackTime: moment.Moment | undefined;
  gap: number | undefined;
  isTimeValid: boolean;
}

export interface TimeSplitterProps {
  hour: number;
  minute: number;
}

/**
 * Gets product size from tags and title without the size.
 * @param product non-customisable product
 * @returns size and title
 */
export const getProductSizeAndTitle = (
  product?: Product
): { size: string | undefined; title: string | undefined } => {
  if (!product) {
    return {
      size: undefined,
      title: undefined,
    };
  }
  const categoryTags = product.tagLookup;
  const tag = categoryTags?.find((catTag) =>
    product.tags?.find(
      (t) => t === catTag.tagId && catTag.type === TagType.Size
    )
  );
  const size = tag?.value;
  // uses split 'cuz needs to trim extra white space that is added in place of size string
  const title = size
    ? product.name
        .split(size)
        .map((e) => e.trim())
        .join(" ")
    : product.name;

  const updatedProductDetails = {
    size,
    title,
  };
  return updatedProductDetails;
};

const sortAndUniqByCategories = (menuSubsection: MenuSubsection[]) => {
  for (const validSubSection of menuSubsection) {
    if (validSubSection.categories) {
      validSubSection.categories = uniqBy(
        validSubSection.categories,
        (i) => i.id
      );
      validSubSection.categories = sortBy(
        validSubSection.categories,
        (i) => i.displayOrder
      );
    }
    if (validSubSection.products) {
      validSubSection.products = uniqBy(validSubSection.products, (i) => i.id);
      validSubSection.products = sortBy(
        validSubSection.products,
        (i) => i.displayOrder
      );
    }
    if (validSubSection.multiParts) {
      validSubSection.multiParts = uniqBy(
        validSubSection.multiParts,
        (i) => i.id
      );
      validSubSection.multiParts = sortBy(
        validSubSection.multiParts,
        (i) => i.displayOrder
      );
    }
  }
};

/**
 * Merge Menu sections if valid index is returned more than 1
 * @param validIndex valid index returned by CalculateMenuSectionEnabledByOrderTime
 * @param sections section from menu sections
 * @returns
 */
export const mergeMenuSection = (
  validIndex: number[],
  sections: MenuSection[]
): MenuSection | undefined => {
  let validMenuSection: MenuSection | undefined = undefined;
  for (const index of validIndex) {
    if (!validMenuSection) {
      validMenuSection = JSON.parse(JSON.stringify(sections[index]));
    } else {
      for (const validSubSection of validMenuSection.subSections) {
        for (const newSubSection of sections[index].subSections) {
          if (validSubSection.title === newSubSection.title) {
            if (validSubSection.categories) {
              const newValidCategories = validSubSection.categories.concat(
                newSubSection.categories
              );
              validSubSection.categories = newValidCategories;
            }
            if (validSubSection.products) {
              const newValidProducts = validSubSection.products.concat(
                newSubSection.products
              );
              validSubSection.products = newValidProducts;
            }
            if (validSubSection.multiParts) {
              const newValidMultiparts = validSubSection.multiParts.concat(
                newSubSection.multiParts
              );
              validSubSection.multiParts = newValidMultiparts;
            }
          }
        }
      }

      sortAndUniqByCategories(validMenuSection.subSections);

      validMenuSection.subSections = [
        ...validMenuSection.subSections,
        ...sections[index].subSections,
      ];

      validMenuSection.subSections = uniqBy(
        validMenuSection.subSections,
        (i) => i.title
      );

      validMenuSection.subSections = sortBy(
        validMenuSection.subSections,
        (i) => i.displayOrder
      );
    }
  }

  return validMenuSection;
};

export const jsonPathAccessor = (
  path: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any
): Category | undefined => {
  try {
    if (path.length > 0) {
      object = jsonPathAccessor(path.slice(1), object[path[0]]);
    }
    return object;
  } catch (e) {
    return undefined;
  }
};

/**
 * Gets the lowest price from section level amongs all categories.
 * To use it for single category, wrapp it in an array.
 * @param category
 * @returns
 */
export const getTheLowestPriceInCategories = (
  categories: Category[]
): string | undefined => {
  const prices: number[] = [];
  categories.find((cat) => {
    // copy array as original can't be modified
    let productsArray: Product[] = [];

    // don't look deep for price in multiparts 'cuz during mapping
    // it already calculated total min price for each multipart
    // and it's available on multipart level
    if (cat?.multiPart) {
      const multipartArr = [...cat.multiPart];
      multipartArr.sort((a, b) => a.price - b.price);
      const lowestMultipartPrice = multipartArr.find(
        (m) => m.price !== (undefined || null)
      )?.price;
      if (lowestMultipartPrice !== undefined) {
        prices.push(lowestMultipartPrice);
      }
    } else {
      productsArray = [...(cat?.products || [])];
    }

    // sort from the lowest to the highest and take the first price as the lowest
    // Match.min can't distinguish the lowest when numbers are mixed float and intigers
    productsArray?.sort((a, b) => a.price - b.price);
    // gets price of first product from the array that has a value
    const productPrice =
      productsArray &&
      productsArray.find((p) => p.price !== (undefined || null))?.price;
    // checks for nullable , 0 is treated as false
    if (productPrice !== (undefined || null)) {
      prices.push(productPrice as number);
    }
  });
  prices.sort((a, b) => a - b);
  return prices[0]?.toFixed(2);
};

/**
 * Returns the lowest possible price for MIAM catgeory based on categories and products inside of multiParts.
 * @returns
 */
export const getLowestMIAMPrice = (commonSection: CommonSection): number => {
  const priceList: number[] = [];
  commonSection.categories[0].multiPart?.forEach((multiPart) => {
    let multiPartPrice = 0;
    multiPart.multiPartSection?.forEach((multiPartSection) => {
      const prodPriceList = multiPartSection.products.map((p) => p.price);
      const prodPrice = Math.min(...prodPriceList);
      let catPrice = undefined;
      if (multiPartSection.categories) {
        catPrice = Number(
          getTheLowestPriceInCategories(multiPartSection.categories)
        );
      }
      const sectionPrice = catPrice
        ? Math.min(...[catPrice, prodPrice])
        : prodPrice;
      multiPartPrice += sectionPrice;
    });
    priceList.push(multiPartPrice);
  });
  return Math.min(...priceList);
};

export const getOpeningTimeMenuHoursForDay = (
  timePeriods: TimePeriod[]
): string => {
  if (timePeriods?.length > 0) {
    let openingTime = timePeriods[0].openTime;
    for (const timePeriod of timePeriods) {
      if (openingTime > timePeriod.openTime) {
        openingTime = timePeriod.openTime;
      }
    }

    return openingTime;
  }
};

export const getClosingTimeMenuHoursForDay = (
  timePeriods: TimePeriod[]
): string => {
  if (timePeriods?.length > 0) {
    let closingTime = timePeriods[0].endTime;
    for (const timePeriod of timePeriods) {
      if (closingTime < timePeriod.endTime) {
        closingTime = timePeriod.endTime;
      }
    }

    return closingTime;
  }
};

export const getDayOfMenuSectionTimePeriod = (
  menuSection: MenuSection,
  orderDayOfWeek: string
): TimePeriod[] | null => {
  if (!menuSection?.tradingHours?.length) {
    return null;
  }

  for (const tradingHour of menuSection?.tradingHours) {
    if (tradingHour.dayOfWeek === orderDayOfWeek) {
      return tradingHour.timePeriods;
    }
  }
};

/**
 * @param id
 * @param subSections
 * @returns title of the subsection by ID
 */
export const getSubSectionTitleById = (
  id: number,
  subSections: MenuSubsection[]
): string | null => {
  for (const subSection of subSections) {
    if (subSection.id === id) {
      return subSection.title;
    }
  }
  return null;
};

export const checkForFeaturedTag = (
  tags?: string[],
  tagLookup?: TagLookup[]
): TagLookup | undefined => {
  return tagLookup?.find((tagObj: TagLookup) =>
    tags?.find(
      (tag: string) =>
        tag === tagObj.tagId && tagObj.type === TagType.FeaturedType
    )
  );
};

export const getFeaturedItems = (
  validMenuSection: MenuSection
): FeaturedItemType[] => {
  const featuredItems: FeaturedItemType[] = [];

  validMenuSection.subSections.forEach((subsection) => {
    subsection.products?.forEach((product) => {
      const featureTag = checkForFeaturedTag(product.tags, product.tagLookup);
      if (featureTag)
        featuredItems.push({
          ...product,
          displayOrder: featureTag.tagDisplayOrder,
        } as FeaturedItemType);
    });
    subsection.categories?.forEach((category) => {
      const featureTag = checkForFeaturedTag(category.tags, category.tagLookup);
      if (featureTag)
        featuredItems.push({
          ...category,
          displayOrder: featureTag.tagDisplayOrder,
        } as FeaturedItemType);
    });
    subsection.multiParts?.forEach((multipart) => {
      const featureTag = checkForFeaturedTag(
        multipart.tags,
        multipart.tagLookup
      );
      if (featureTag)
        featuredItems.push({
          ...multipart,
          displayOrder: featureTag.tagDisplayOrder,
        } as FeaturedItemType);
    });
  });

  // return unique featured items based on id, name, posPlu
  return uniqBy(featuredItems, (v) => [v.id, v.name, v.posPlu].join()).sort(
    (a, b) => a.displayOrder - b.displayOrder
  );
};

export const getFeaturedItemImages = (
  item: FeaturedItemType
): { imageUrl?: string | null; imageWideUrl?: string | null } | undefined => {
  const imageUrl = item.tagLookup.find(
    (tagLookup) => tagLookup.type === TagType.FeaturedImageApp
  )?.value;
  const imageWideUrl = item.tagLookup.find(
    (tagLookup) => tagLookup.type === TagType.FeaturedImageWeb
  )?.value;

  if (imageUrl && imageWideUrl) {
    return {
      imageUrl,
      imageWideUrl,
    };
  } else {
    return undefined;
  }
};

export const getAllMenuIDs = (
  subSections: MenuSubsection[],
  commonSections: CommonSection[]
): number[] => {
  const output = [];

  //check if common section available for categories
  const availableCommonSectionsArray = [];
  for (const item of subSections) {
    if (item.categories?.length) {
      for (const category of item.categories) {
        category.commonSection?.map((sectionID) => {
          const commonSectionArray = commonSections.filter(
            (section) => section.id == sectionID
          );
          if (commonSectionArray?.length) {
            const section = commonSectionArray[0];
            if (
              !availableCommonSectionsArray.filter(
                (model) => model.id == section.id
              )?.length
            ) {
              availableCommonSectionsArray.push(section);
            }
          }
        });
      }
    }
  }
  const items = subSections.concat(availableCommonSectionsArray);

  for (const item of items) {
    if (item.categories) {
      for (let i = 0; i < item.categories.length; ++i) {
        output.push(item.categories[i]?.id);
        if (item.categories[i]?.products) {
          for (let j = 0; j < item.categories[i]?.products.length; ++j)
            output.push(item.categories[i]?.products[j]?.id);
        }
        if (item.categories[i]?.multiPart) {
          for (let j = 0; j < item.categories[i]?.multiPart.length; ++j)
            output.push(item.categories[i]?.multiPart[j]?.id);
        }
      }
    }
    if (item.multiParts) {
      for (let i = 0; i < item.multiParts.length; ++i) {
        output.push(item.multiParts[i]?.id);
      }
    }
    if (item.products) {
      for (let i = 0; i < item.products.length; ++i) {
        output.push(item.products[i]?.id);
      }
    }
  }
  return output;
};

export const timeSplitter = (time: string): TimeSplitterProps => {
  const timeArray = time.split(":");
  const splittedTime =
    timeArray.length === 2
      ? {
          hour: Number(time.split(":")[0]),
          minute: Number(time.split(":")[1]),
        }
      : {
          hour: 0,
          minute: 0,
        };

  return splittedTime;
};

const createMomentBasedOn24HoursTime = (
  time: string,
  dayOfWeek: string,
  timezone: string
) => {
  return moment()
    .tz(timezone)
    .day(dayOfWeek)
    .hour(timeSplitter(time).hour)
    .minute(timeSplitter(time).minute)
    .second(0);
};

export const findTimePeriodsForDay = (
  sections: MenuSection[],
  selectedDate: Date,
  timezone: string
): TimePeriod[] => {
  let timePeriods: TimePeriod[] = [];
  const selectedDay = moment.tz(selectedDate, timezone).format("dddd");

  sections.forEach((section) => {
    const tradingHour = section?.tradingHours?.find(
      (hour) => hour.dayOfWeek.toLowerCase() === selectedDay.toLowerCase()
    );

    if (tradingHour) {
      timePeriods = timePeriods.concat(tradingHour.timePeriods);
    }
  });

  return timePeriods;
};

const calculateTimeGaps = (
  momentOpeningClosingTime: moment.Moment,
  momentSelectedTime: moment.Moment
) => {
  return Math.abs(momentOpeningClosingTime.diff(momentSelectedTime, "minutes"));
};

export const generateFallbackTime = (
  fallBackTime: FallBackTimeProps,
  openingTime: string,
  closingTime: string,
  selectedDay: string,
  selectedTime: string,
  timezone?: string
): FallBackTimeProps => {
  const momentOpeningTime = createMomentBasedOn24HoursTime(
    openingTime,
    selectedDay,
    timezone
  );
  const momentClosingTime = createMomentBasedOn24HoursTime(
    closingTime,
    selectedDay,
    timezone
  );
  const momentSelectedTime = createMomentBasedOn24HoursTime(
    selectedTime,
    selectedDay,
    timezone
  );

  const gapBetweenOpeningTime = calculateTimeGaps(
    momentOpeningTime,
    momentSelectedTime
  );
  const gapBetweenClosingTime = calculateTimeGaps(
    momentClosingTime,
    momentSelectedTime
  );

  //True if there no initial fallbacktime gap
  //if there initial gap is larger than the new gap
  if (
    !fallBackTime.gap ||
    fallBackTime.gap > gapBetweenOpeningTime ||
    fallBackTime.gap > gapBetweenClosingTime
  ) {
    if (gapBetweenOpeningTime < gapBetweenClosingTime) {
      fallBackTime.fallBackTime = momentOpeningTime;
      fallBackTime.gap = gapBetweenOpeningTime;
    } else {
      fallBackTime.fallBackTime = momentClosingTime;
      fallBackTime.gap = gapBetweenClosingTime;
    }
    fallBackTime.isTimeValid = false;
  }

  return fallBackTime;
};

export const checkSelectedTimeWithinTradingHours = (
  selectedDate: Date,
  timezone: string,
  sections?: MenuSection[]
): FallBackTimeProps => {
  const selectedDay = moment.tz(selectedDate, timezone).format("dddd");
  const selectedTime = moment
    .tz(selectedDate, timezone)
    .set("second", 0)
    .tz(timezone)
    .format("HH:mm");

  let fallBackTime: FallBackTimeProps = {
    fallBackTime: undefined,
    gap: undefined,
    isTimeValid: false,
  };

  const timePeriodsForDay = sections
    ? findTimePeriodsForDay(sections, selectedDate, timezone)
    : [];

  if (timePeriodsForDay.length) {
    for (const timePeriod of timePeriodsForDay) {
      const openingTime = timePeriod.openTime;
      const closingTime = timePeriod.endTime;

      //return if time selected is within one of the time periods
      if (selectedTime >= openingTime && selectedTime <= closingTime) {
        fallBackTime = {
          fallBackTime: undefined,
          gap: undefined,
          isTimeValid: true,
        };
        return fallBackTime;
      } else {
        /**
         * If time selected is outside of all the time periods
         * Generate a fallback time for time picker to fall back to
         */

        fallBackTime = generateFallbackTime(
          fallBackTime,
          openingTime,
          closingTime,
          selectedDay,
          selectedTime,
          timezone
        );
      }
    }
  }

  return fallBackTime;
};
