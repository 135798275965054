import "./style.scss";

import React, { useEffect, useState } from "react";
import { Text } from "react-native";

import { useFlyingBurritoAsset, useLottieWeb } from "gyg_common/hooks";

import {
  DESCRIPTION_TEXT_DISPLAY_DELAY,
  LoadingScreenProps,
  styles,
} from "./model";

const LoadingScreen: React.FC<LoadingScreenProps> = (props) => {
  const [displayDescription, setDisplayDescription] = useState(false);
  const { lottie } = useLottieWeb();
  const { isAssetLoading, flyingBurrito } = useFlyingBurritoAsset();

  useEffect(() => {
    if (props.description && props.loading) {
      setTimeout(() => {
        setDisplayDescription(true);
      }, DESCRIPTION_TEXT_DISPLAY_DELAY);
    } else {
      setDisplayDescription(false);
    }
  }, [props.loading, props.description]);

  useEffect(() => {
    if (isAssetLoading || !lottie) {
      return;
    }

    //fixes animation to multiply
    if (!props.disableDestroy) {
      lottie.destroy();
    }
    lottie.loadAnimation({
      container: document.getElementById("flying-burrito") as HTMLDivElement,
      renderer: "svg",
      loop: true,
      autoplay: false,
      animationData: flyingBurrito,
    });

    if (props.json) {
      lottie.loadAnimation({
        container: document.getElementById("loading-text") as HTMLDivElement,
        renderer: "canvas",
        loop: true,
        autoplay: false,
        animationData: props.json,
      });
    }

    if (props.loading) {
      lottie.play();
    } else {
      lottie.stop();
    }
  }, [
    props.loading,
    props.json,
    props.disableDestroy,
    lottie,
    flyingBurrito,
    isAssetLoading,
  ]);

  if (props.loading) {
    return (
      <div className='loading-screen'>
        <div
          data-testid='flyingBurrito'
          className='loading-screen__animation'
          id='flying-burrito'></div>
        <div className='loading-screen__text' id='loading-text'></div>

        {displayDescription && !!props.description && (
          <Text style={styles.description}>
            {props.description.toUpperCase()}
          </Text>
        )}
      </div>
    );
  }
  return null;
};

export default React.memo(LoadingScreen);
