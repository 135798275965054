export interface ISentryLogger {
  sentryEndpointError(exception: unknown, additionalErrorInfo?: string): void;
  sentryRefreshTokenError(exception: unknown): void;
  sentryLowMemoryWarning(): void;
  sentryBraintreeError(exception: unknown): void;
  sentryCaptureCustomError(description: string, error: unknown): void;
  sentrySetTag(tagName: string, tagValue: string): void;
  sentryRouteTracing(routeName: string): void;
}

export let SentryLoggerInstance: ISentryLogger;

export function configSentryLogger(newSentryLogger: ISentryLogger) {
  SentryLoggerInstance = newSentryLogger;
}

//Ignored Sentry errors which are not causing crashes
//https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-954602174

export const ignoreErrors = [
  "Branch onOpenComplete error:", // Possibly caused by AdBlock https://github.com/BranchMetrics/android-branch-deep-linking-attribution/issues/844#issuecomment-1309220458
];
