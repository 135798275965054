import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";

import { StoreState } from "../../redux_store/store/models";
import * as StoreApiService from "../../services/api/store";
import { errorActions } from "../error/error.slice";
import { ErrorResponse } from "../error/models";
import { buildErrorResponse } from "../error/utils";
import { StateEnum, stateSelector } from "../utils/selector";
import {
  GetStoreOfferResponse,
  Promo,
  PromoDetails,
  PromoState,
  SetStoreOfferIdSelected,
} from "./models";
import { promoActions, PromoActionsType } from "./promo.slice";

function* handleSetStoreOfferIdSelected(action: PromoActionsType) {
  const payload = action.payload as SetStoreOfferIdSelected;
  const promoState = (yield select(
    stateSelector(StateEnum.promo)
  )) as PromoState;

  const selectedAvailablePromo = promoState.availablePromos.find(
    (availablePromoItem) => {
      if (availablePromoItem.storeOfferId === payload.storeOfferId)
        return availablePromoItem;
    }
  );

  const selectedAvailablePromoDetails = promoState.storeOffers.find(
    (storeOffer) => {
      if (storeOffer.brazeOfferId === payload.storeOfferId) {
        return storeOffer;
      }
    }
  );

  yield put(
    promoActions.setAvailablePromoSelected({
      selectedAvailablePromo,
      selectedAvailablePromoDetails,
    })
  );
}

function* handleGetStoreOffer() {
  try {
    const result = yield call(StoreApiService.getStoreOffers);
    yield put(
      promoActions.setStoreOffers({
        storeOffers: result as GetStoreOfferResponse[],
        clearCurrentStoreOffers: true,
      })
    );

    const promoState = (yield select(
      stateSelector(StateEnum.promo)
    )) as PromoState;

    const storeState = (yield select(
      stateSelector(StateEnum.store)
    )) as StoreState;

    const currentStoreId = storeState.selectedStore?.id;

    const availablePromos: Promo[] = promoState.promos.filter(
      (promo: Promo) => {
        const promoStoreOffer = promoState.storeOffers.find(
          (storeOffer: PromoDetails) => {
            return storeOffer.brazeOfferId === promo.storeOfferId;
          }
        );

        if (promoStoreOffer) {
          const isPromoAvailableToCurrentSelectedStore =
            currentStoreId &&
            promoStoreOffer.participatingStores?.length &&
            promoStoreOffer.participatingStores.includes(
              storeState.selectedStore.id
            );

          return (
            isPromoAvailableToCurrentSelectedStore || promoStoreOffer.alwaysShow
          );
        } else {
          return true;
        }
      }
    );

    yield put(
      promoActions.setAvailablePromos({
        availablePromos: availablePromos,
        clearCurrentAvaiablePromos: true,
      })
    );
  } catch (e) {
    const errorResponse: ErrorResponse = buildErrorResponse(e);
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* watchGetStoreOffer(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(promoActions.getStoreOffers().type, handleGetStoreOffer);
}

export function* watchSetStoreOfferIdSelected(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    promoActions.setStoreOfferIdSelected().type,
    handleSetStoreOfferIdSelected
  );
}

const saga = [fork(watchGetStoreOffer), fork(watchSetStoreOfferIdSelected)];

export default saga;
