import { useFeatureIsOn } from "@growthbook/growthbook-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Platform, Text, View } from "react-native";

import { StoreModules, StoreReduxModels } from "gyg_common";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import SecondaryButton from "gyg_common/components/Buttons/SecondaryButton";
import {
  StoreAddress,
  StoreHead,
  StoreStatus,
} from "gyg_common/components/StoreDetails";
import { FeatureFlags } from "gyg_common/modules/FeatureFlags/constants";

import BasicDetailsBlackButton from "./BasicDetailsBlackButton";
import styles from "./BasicStoreDetails.styles";

export interface BasicStoreDetailsProps {
  store: StoreReduxModels.Store;
  handleOrder: (store: StoreReduxModels.Store) => void;
  buttonVersion: "primary" | "secondary";
  showAddress?: boolean;
  withDelivery?: boolean;
}

const BasicStoreDetails: React.FC<BasicStoreDetailsProps> = ({
  store,
  handleOrder,
  showAddress,
  buttonVersion,
  withDelivery,
}) => {
  const storeOpenStatus: StoreReduxModels.StoreOpenTime =
    StoreModules.StoreUtils.checkStoreOpenHours(
      store?.tradingHours ?? [],
      store.timeZoneInfo.storeTimeZone
    );
  const { t } = useTranslation();

  const hasDelivery = useFeatureIsOn(FeatureFlags.IN_APP_DELIVERY as string);

  const onPressInfo = () => {
    handleOrder(store);
  };

  return (
    <View style={styles.details}>
      <View style={withDelivery && styles.detailsRow}>
        <View
          style={[
            styles.basicInfo,
            withDelivery && styles.basicInfoWithDelivery,
          ]}>
          <StoreHead
            store={store}
            showInfoBtn={!withDelivery}
            showDistanceText={!withDelivery}
            onPressInfo={hasDelivery ? onPressInfo : undefined}
          />
          {showAddress && (
            <View style={styles.row}>
              <StoreAddress
                address1={store.address1}
                address2={store.address2}
                city={store.city}
                postCode={store.postCode}
                state={store.state}
              />
            </View>
          )}
          {withDelivery && <StoreStatus {...storeOpenStatus} />}
        </View>
        {withDelivery && (
          <View style={styles.halfFlex}>
            <Text style={[styles.distanceText, styles.distancePaddings]}>
              {store.distanceText}
            </Text>
            <View style={styles.btnWrapper}>
              <SecondaryButton
                onClick={() => handleOrder(store)}
                buttonName={t("StoreSearch:orderBtnSelect").toUpperCase()}
              />
            </View>
          </View>
        )}
      </View>
      {!withDelivery && (
        <View style={styles.detailsRow}>
          <View style={styles.storeInfo}>
            <StoreStatus {...storeOpenStatus} />
            <View style={styles.tagWrapper}>
              {store.tags &&
                store.tags.map(
                  ({ tag }: StoreReduxModels.StoreTag, i: number) => (
                    <Text key={i} style={styles.tag}>
                      {tag}
                      {i !== store.tags.length - 1 ? " | " : ""}
                    </Text>
                  )
                )}
            </View>
          </View>
          <View style={styles.btnWrapper}>
            {buttonVersion === "secondary" ? (
              <SecondaryButton
                onClick={() => handleOrder(store)}
                buttonName={t("StoreSearch:orderBtn")}
              />
            ) : Platform.OS === "ios" ? (
              <BasicDetailsBlackButton
                onClick={() => handleOrder(store)}
                title={t("StoreSearch:orderBtn")}
              />
            ) : (
              <PrimaryBlackButton
                onClick={() => handleOrder(store)}
                buttonName={t("StoreSearch:orderBtn")}
              />
            )}
          </View>
        </View>
      )}
    </View>
  );
};

export default BasicStoreDetails;
