import { CartItem } from "../../redux_store/cart/models";
import {
  FavouriteCartItem,
  UpdateFavouriteResponse,
} from "../../redux_store/favourite/models";
import { compareCartItemWithFavourite } from "./isFavouriteItem";

export const getFavouriteIdLabel = (
  item: CartItem,
  favouriteItems: FavouriteCartItem[]
): UpdateFavouriteResponse | undefined => {
  if (favouriteItems && favouriteItems.length > 0) {
    for (const favouriteItem of favouriteItems) {
      if (compareCartItemWithFavourite(item, favouriteItem)) {
        return {
          favouriteId: favouriteItem.favouriteId,
          displayLabel: item.isOfferProduct ? item.name : favouriteItem.label,
          label: favouriteItem.label,
        };
      }
    }
  }

  return undefined;
};
