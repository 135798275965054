import * as AnalyticsConst from "../modules/Analytics/const";
import { AnalyticsType } from "../modules/Analytics/model";

export interface TealiumView {
  page_type: string;
  page_name: string;
}

export interface IAnalytics {
  trackView(view: string | TealiumView): void;
  trackEvent(
    event: string | AnalyticsConst.Events,
    eventPayload: AnalyticsType
  ): void;
  setCustomAttribute(
    attributeName: string | AnalyticsConst.CustomAttribute,
    attributeValue: Date | boolean | string | number
  ): void;
  trackBrazeEvent(event: string | AnalyticsConst.Events): void;
}

export let AnalyticsInstance: IAnalytics;

export function configAnalytics(newAnalytics: IAnalytics) {
  AnalyticsInstance = newAnalytics;
}
