import { useCallback, useEffect, useState } from "react";

interface UseFlyingBurritoAssetReturn {
  isAssetLoading: boolean;
  flyingBurrito: unknown | undefined;
}

// to reduce bundle size
export const useFlyingBurritoAsset = (): UseFlyingBurritoAssetReturn => {
  const [isAssetLoading, setIsAssetLoading] = useState(true);
  // unknown, as per .json dts typedef
  const [flyingBurrito, setFlyingBurrito] = useState<unknown | undefined>();

  const loadFlyingBurrito = useCallback(async () => {
    const assetLib = await import(
      "../components/assets/gif/FlyingBurrito_001.json"
    );
    setFlyingBurrito(assetLib.default);
    setIsAssetLoading(false);
  }, []);

  useEffect(() => {
    loadFlyingBurrito();
  }, [loadFlyingBurrito]);

  return { isAssetLoading, flyingBurrito };
};
