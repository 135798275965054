import { GrowthBookProvider } from "@growthbook/growthbook-react";
import React, { ReactNode, useEffect } from "react";

import { getVersionAndPlatformData } from "../../modules/platformUtils";
import { gb } from "../../utils/growthbook";

interface FeatureFlagProps {
  children: ReactNode;
  id?: number;
  email?: string;
  phoneNumber?: string;
  sessionId?: string;
  storeId?: number;
  latitude?: number;
  longitude?: number;
}

const FeatureFlag: React.FC<FeatureFlagProps> = ({
  children,
  sessionId,
  id,
  email,
  phoneNumber,
  storeId,
  latitude,
  longitude,
}) => {
  useEffect(() => {
    // Load features from the GrowthBook API
    if (gb.getClientKey()?.length > 0) {
      gb.loadFeatures({ autoRefresh: true });
    }
  }, []);

  useEffect(() => {
    const versionPlatform = getVersionAndPlatformData();
    // update to track user specific attributes when available
    if (gb.getClientKey()?.length > 0) {
      gb.setAttributes({
        ...gb.getAttributes(),
        id,
        email,
        phoneNumber,
        version: versionPlatform.clientVersion,
        platform: versionPlatform.clientPlatformType,
        sessionId,
        storeId,
        latitude,
        longitude,
      });
      gb.refreshFeatures();
    }
  }, [email, id, phoneNumber, sessionId, storeId, latitude, longitude]);

  return <GrowthBookProvider growthbook={gb}>{children}</GrowthBookProvider>;
};

export default FeatureFlag;
