/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Effects from "redux-saga/effects";
import { fork, put, select, takeLatest } from "redux-saga/effects";

import config from "../../config";
import { locales } from "../../locales/locales.enum";
import translationAU from "../../locales/LoginRegistration/au.json";
import translationUS from "../../locales/LoginRegistration/us.json";
import {
  getFavouriteProducts,
  mapFavouriteToMenu,
} from "../../modules/Favourite";
import * as ApiService from "../../services/api/favourite";
import { AuthState } from "../auth/models";
import { errorActions } from "../error/error.slice";
import { ErrorResponse } from "../error/models";
import { MenuState } from "../menu/menu.slice";
import { MenuListItemProps, MenuStructure } from "../menu/models";
import { StateEnum, stateSelector } from "../utils/selector";
import { favouriteActions, FavouriteActionsType } from "./favourite.slice";
import {
  DeleteFavResponse,
  FavouriteCartItem,
  FavouriteResponse,
  GetFavouriteResponse,
  UpdateFavouriteResponse,
} from "./models";

const call: any = Effects.call;

export function* handleFavouriteUpdateToState(menuStructure: MenuStructure) {
  try {
    const authState = (yield select(
      stateSelector(StateEnum.login)
    )) as AuthState;

    if (authState.isAuthSuccess) {
      const result: GetFavouriteResponse[] = yield call(
        ApiService.getFavourite
      );
      const favouriteCartItems: FavouriteCartItem[] = getFavouriteProducts(
        menuStructure,
        result
      );
      const favouriteMenuItems: MenuListItemProps[] = mapFavouriteToMenu(
        favouriteCartItems || []
      );

      yield put(
        favouriteActions.setFavouriteCartItemsAndMenu({
          favouriteCartItems,
          favouriteMenuItems,
        })
      );
    } else {
      // Passes empty data for guest branch deeplink auto-scroll
      yield put(
        favouriteActions.setFavouriteCartItemsAndMenu({
          favouriteCartItems: [],
          favouriteMenuItems: [],
        })
      );
    }
  } catch (e) {
    yield put(favouriteActions.setFavouriteCartItemsAndMenuError());
    const errorResponse: ErrorResponse = {
      ...e.response?.data,
      statusCode: e.response?.status,
    };
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

//list Favourites
export function* handleFetchFavourite(action: FavouriteActionsType) {
  try {
    const result: GetFavouriteResponse[] = yield call(
      ApiService.getFavourite,
      action.payload
    );
    yield put(favouriteActions.setFavourite(result));

    const menuState = (yield select(
      stateSelector(StateEnum.menu)
    )) as MenuState;

    if (menuState.menuStructure) {
      const favouriteCartItems: FavouriteCartItem[] = getFavouriteProducts(
        menuState.menuStructure,
        result
      );
      const favouriteMenuItems: MenuListItemProps[] = mapFavouriteToMenu(
        favouriteCartItems || []
      );
      yield put(
        favouriteActions.setFavouriteCartItemsAndMenu({
          favouriteCartItems,
          favouriteMenuItems,
        })
      );
    } else {
      // Passes empty data for guest branch deeplink auto-scroll
      yield put(
        favouriteActions.setFavouriteCartItemsAndMenu({
          favouriteCartItems: [],
          favouriteMenuItems: [],
        })
      );
    }
  } catch (e) {
    yield put(favouriteActions.error());
    const errorResponse: ErrorResponse = {
      ...e.response?.data,
      statusCode: e.response?.status,
    };
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* watchFetchFavourite() {
  yield takeLatest(favouriteActions.getFavourite.type, handleFetchFavourite);
}

//create Favourite
export function* handleCreateFavourite(action: FavouriteActionsType) {
  try {
    const result: FavouriteResponse = yield call(
      ApiService.createFavouriteItem,
      action.payload
    );

    yield put(favouriteActions.createFavouriteSuccess(result));
    yield put(favouriteActions.getFavourite());
  } catch (e) {
    yield put(
      favouriteActions.createFavouriteStatus(
        e.response?.data?.heading ?? config.version === locales.AU
          ? translationAU.loginUnexpectedError
          : translationUS.loginUnexpectedError
      )
    );
    const errorResponse: ErrorResponse = {
      ...e.response?.data,
      statusCode: e.response?.status,
    };
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* watchCreateFavourite() {
  yield takeLatest(
    favouriteActions.createFavourite.type,
    handleCreateFavourite
  );
}

//Update Favourite
export function* handleDeleteFavourite(action: FavouriteActionsType) {
  try {
    const result: DeleteFavResponse = yield call(
      ApiService.deleteFavouriteItem,
      action.payload
    );
    yield put(favouriteActions.deleteFavouriteSuccess(result));
    yield put(favouriteActions.getFavourite());
  } catch (e) {
    yield put(
      favouriteActions.createFavouriteStatus(
        e.response?.data?.heading ?? config.version === locales.AU
          ? translationAU.loginUnexpectedError
          : translationUS.loginUnexpectedError
      )
    );
    const errorResponse: ErrorResponse = {
      ...e.response?.data,
      statusCode: e.response?.status,
    };
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* watchDeleteFavourite() {
  yield takeLatest(
    favouriteActions.deleteFavourite.type,
    handleDeleteFavourite
  );
}

export function* handleRenameFavourite(action: FavouriteActionsType) {
  try {
    const result: UpdateFavouriteResponse = yield call(
      ApiService.renameFavourite,
      action.payload
    );
    yield put(favouriteActions.renameFavouriteSuccess(result));
  } catch (e: any) {
    yield put(
      favouriteActions.createFavouriteStatus(
        e.response?.data?.heading ?? config.version === locales.AU
          ? translationAU.loginUnexpectedError
          : translationUS.loginUnexpectedError
      )
    );
    const errorResponse: ErrorResponse = {
      ...e.response?.data,
      statusCode: e.response?.status,
    };
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* watchRenameFavourite() {
  yield takeLatest(
    favouriteActions.renameFavourite.type,
    handleRenameFavourite
  );
}

const saga = [
  fork(watchFetchFavourite),
  fork(watchCreateFavourite),
  fork(watchDeleteFavourite),
  fork(watchRenameFavourite),
];

export default saga;
