import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { StyleSheet, View } from "react-native";

import { StoreReduxModels } from "gyg_common";
import {
  CollectionType,
  DeliveryAddress,
  DeliveryModalState,
  ModalState,
  PickupModalState,
} from "gyg_common/redux_store/order/models";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export interface OrderSetupProps {
  isVisible?: boolean;
  isLoading?: boolean;
  store?: StoreReduxModels.Store | null;
  collectionType?: CollectionType;
  onCloseModal: () => void;
  onSubmitDelivery: (finalState: DeliveryModalState) => void;
  onSubmitPickup: (finalState: PickupModalState) => void;
}

export interface OrderSetupContentProps extends OrderSetupProps {
  OrderCollectionCategory: React.FC<SetupScreenComponent<ModalState>>;
  DeliveryAddress: React.FC<SetupScreenComponent<DeliveryModalState>>;
  DeliveryOrderSetup: React.FC<SetupScreenComponent<DeliveryModalState>>;
  DeliveryInfo: React.FC<SetupScreenComponent<DeliveryModalState>>;
  DeliveryRestaurant: React.FC<SetupScreenComponent<DeliveryModalState>>;
  PickupOrderSetup: React.FC<SetupScreenComponent<PickupModalState>>;
  PickupRestaurant: React.FC<SetupScreenComponent<PickupModalState>>;
  PickupRestaurantConfirm: React.FC<SetupScreenComponent<PickupModalState>>;
  setCurrentScreen?: (currentScreen: SetupScreen<ModalState> | null) => void;
}

export class OrderSetupGlobalState {
  private static instance: OrderSetupGlobalState;

  public modalState: ModalState | null = null;

  public editAddress: boolean = false;

  public recentAddresses: DeliveryAddress[] | null = null;

  //Reset global states for order setup, used after submitting an order
  public resetOrderSetupStates = () => {
    //reset recent addresses to allow fetching updated recent addresses from API on next order setup
    OrderSetupGlobalState.getInstance().recentAddresses = null;
    OrderSetupGlobalState.getInstance().editAddress = false;
  };

  private constructor() {
    this.modalState = null;
  }

  public static getInstance(): OrderSetupGlobalState {
    if (!OrderSetupGlobalState.instance) {
      OrderSetupGlobalState.instance = new OrderSetupGlobalState();
    }
    return OrderSetupGlobalState.instance;
  }
}

export type SetupScreenComponent<ModalState> = {
  state: ModalState;
  onNext: (newState: ModalState) => void;
  onBack: (state: ModalState) => void;
  onBackToScreen: (screenName: string) => void;
  onChangeCollectionType: (
    collectionType: CollectionType,
    modalState?: ModalState,
    ChosenStore?: StoreReduxModels.Store
  ) => void;
  onSubmit: (finalState: ModalState) => void;
};

export class SetupScreen<ModalState> {
  constructor(
    public title: string,
    public Component: React.FC<SetupScreenComponent<ModalState>>,
    public cleanupOnBack: (state: ModalState) => ModalState = (state) => state,
    public hasBack: boolean = true
  ) {}
}

type SetupFlow<T extends ModalState> = {
  [index: string]: SetupScreen<T>;
};

export type OrderSetupContentForWardRef = {
  onDismissModal: () => void;
  onBackPress: () => void;
};

const OrderSetupContent: ForwardRefRenderFunction<
  OrderSetupContentForWardRef,
  OrderSetupContentProps
> = (
  {
    isVisible,
    onSubmitDelivery,
    onSubmitPickup,
    setCurrentScreen,
    onCloseModal,
    OrderCollectionCategory,
    DeliveryAddress,
    DeliveryInfo,
    DeliveryOrderSetup,
    DeliveryRestaurant,
    PickupOrderSetup,
    PickupRestaurant,
    PickupRestaurantConfirm,
    // Start screen
  },
  ref: ForwardedRef<OrderSetupContentForWardRef>
) => {
  const { t } = useTranslation();

  const OrderCollectionCategoryScreen: SetupScreen<DeliveryModalState> =
    new SetupScreen<DeliveryModalState>(
      t("OrderManagement:ChooseOrderType"),
      OrderCollectionCategory,
      (state) => state,
      false
    );

  const DeliveryAddressScreen: SetupScreen<DeliveryModalState> =
    new SetupScreen<DeliveryModalState>(
      t("OrderManagement:DeliveryAddress"),
      DeliveryAddress,
      (state) => {
        state.DropoffAddress = undefined;
        state.AutoCompleteResult = undefined;
        return state;
      }
    );

  const DeliveryInfoScreen: SetupScreen<DeliveryModalState> =
    new SetupScreen<DeliveryModalState>(
      t("OrderManagement:DeliveryInfo"),
      DeliveryInfo,
      (state) => {
        return state;
      }
    );

  const DeliveryRestaurantScreen: SetupScreen<DeliveryModalState> =
    new SetupScreen<DeliveryModalState>(
      t("OrderManagement:Restaurants"),
      DeliveryRestaurant,
      (state) => {
        state.ChosenStore = undefined;
        return state;
      }
    );

  const DeliveryOrderSetupScreen: SetupScreen<DeliveryModalState> =
    new SetupScreen<DeliveryModalState>(
      t("OrderManagement:OrderSetup"),
      DeliveryOrderSetup
    );

  const DeliveryStepsScreens: SetupFlow<DeliveryModalState> = {
    DeliveryAddress: DeliveryAddressScreen,
    DeliveryInfo: DeliveryInfoScreen,
    DeliveryRestaurant: DeliveryRestaurantScreen,
    DeliveryOrderSetup: DeliveryOrderSetupScreen,
  };

  const PickupRestaurantSelectScreen: SetupScreen<PickupModalState> =
    new SetupScreen<PickupModalState>(
      t("OrderManagement:Restaurants"),
      PickupRestaurant
    );

  const PickupRestaurantConfirmScreen: SetupScreen<PickupModalState> =
    new SetupScreen<PickupModalState>(
      t("OrderManagement:Restaurant"),
      PickupRestaurantConfirm
    );

  const PickupOrderSetupScreen: SetupScreen<PickupModalState> =
    new SetupScreen<PickupModalState>(
      t("OrderManagement:OrderSetup"),
      PickupOrderSetup
    );

  const PickupStepsScreens: SetupFlow<PickupModalState> = {
    PickupRestaurantSelect: PickupRestaurantSelectScreen,
    PickupRestaurantConfirm: PickupRestaurantConfirmScreen, // Conditional if they haven't enabled location sharing to get their closest restaurant
    PickupOrderSetup: PickupOrderSetupScreen,
  };

  const [currentFlow, setCurrentFlow] = useState<{
    [index: string]: SetupScreen<ModalState>;
  }>();
  const [currentFlowIndex, setCurrentFlowIndex] = useState<number>(-1);
  const [modalState, setModalState] = useState<ModalState>({
    OrderCollectionType: undefined,
  });
  const [navigateTo, setNavigateTo] = useState<string | null>(null);
  const initialSetup = useRef(false);

  const CurrentScreen =
    currentFlow && currentFlowIndex >= 0
      ? Object.values(currentFlow)[currentFlowIndex]
      : OrderCollectionCategoryScreen;

  const handleNavigation = useCallback(
    (direction: number) => {
      if (currentFlowIndex < 0 && direction < 0) {
        onCloseModal();
      } else if (currentFlowIndex == 0 && direction < 0) {
        setCurrentFlow(undefined);
        setCurrentFlowIndex(-1);
      } else {
        // TODO: Add else if new index is not greater than current flow steps
        setCurrentFlowIndex(direction + currentFlowIndex);
      }
    },
    [currentFlowIndex, onCloseModal]
  );

  const handleOnBack = useCallback(() => {
    setModalState(CurrentScreen.cleanupOnBack(modalState));
    handleNavigation(-1);
  }, [CurrentScreen, handleNavigation, modalState]);

  const handleOnBackToScreen = useCallback(
    (screenName: string) => {
      if (!currentFlow) {
        return;
      }
      const currentFlowKeys = Object.keys(currentFlow);
      const destinationScreenIndex = currentFlowKeys.findIndex(
        (x) => x === screenName
      );
      if (destinationScreenIndex !== -1) {
        for (let x = currentFlowIndex; x > destinationScreenIndex; x--) {
          currentFlow[currentFlowKeys[x]].cleanupOnBack(modalState);
        }
        setCurrentFlowIndex(destinationScreenIndex);
      }
    },
    [currentFlow, currentFlowIndex, modalState]
  );

  const handleOnChangeCollectionType = (
    collectionType: CollectionType,
    modalState?: ModalState,
    ChosenStore?: StoreReduxModels.Store
  ) => {
    setCurrentFlowIndex(0);
    if (collectionType == CollectionType.DELIVERY) {
      setCurrentFlow(DeliveryStepsScreens);
    } else {
      setCurrentFlow(PickupStepsScreens);
    }
    if (ChosenStore) {
      setModalState({
        ...modalState,
        OrderCollectionType: collectionType,
        ChosenStore,
      });
    } else {
      setModalState({
        ...modalState,
        OrderCollectionType: collectionType,
      });
    }

    if (ChosenStore && collectionType == CollectionType.PICK_UP) {
      //Move to selected store if store was selected before
      setNavigateTo("PickupRestaurantConfirm");
    }
  };

  useEffect(() => {
    if (currentFlow && navigateTo) {
      handleOnBackToScreen(navigateTo);
      setNavigateTo(null);
    }
  }, [navigateTo, currentFlow, handleOnBackToScreen]);

  const handleOnNext = (newState: ModalState) => {
    setModalState({
      ...modalState,
      ...newState,
    });
    handleNavigation(+1);
  };

  const handleOnSubmit = (finalState: ModalState) => {
    //save modal state to reuse when order setup edit opened
    OrderSetupGlobalState.getInstance().modalState = finalState;
    if (finalState.OrderCollectionType == CollectionType.DELIVERY) {
      onSubmitDelivery(finalState as DeliveryModalState);
    } else {
      onSubmitPickup(finalState as PickupModalState);
    }
  };

  //Used to call functions from parent components
  useImperativeHandle(ref, () => ({
    // each key is connected to `ref` as a method name
    // they can execute code directly, or call a local method
    onBackPress: () => {
      handleOnBack();
    },
    onDismissModal: () => {
      initialSetup.current = false;
    },
  }));

  useEffect(() => {
    //Update OrderSetup Container title and button
    if (setCurrentScreen) {
      setCurrentScreen(
        currentFlow
          ? Object.values(currentFlow)[currentFlowIndex]
          : OrderCollectionCategoryScreen
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFlowIndex, currentFlow]);

  //Used to open order setup screen if order type was selected before
  useEffect(() => {
    if (
      OrderSetupGlobalState.getInstance().modalState?.ChosenStore &&
      isVisible &&
      !initialSetup.current &&
      OrderSetupGlobalState.getInstance().modalState?.OrderCollectionType
    ) {
      handleOnChangeCollectionType(
        OrderSetupGlobalState.getInstance().modalState!.OrderCollectionType!,
        {
          ...OrderSetupGlobalState.getInstance().modalState,
          ChosenStore:
            OrderSetupGlobalState.getInstance().modalState?.ChosenStore,
        }
      );

      if (!currentFlow) {
        if (
          OrderSetupGlobalState.getInstance().modalState?.OrderCollectionType ==
          CollectionType.DELIVERY
        ) {
          setCurrentFlow(DeliveryStepsScreens);
        } else {
          setCurrentFlow(PickupStepsScreens);
        }
      }
      if (currentFlow) {
        initialSetup.current = true;
        handleOnBackToScreen(
          OrderSetupGlobalState.getInstance().modalState?.OrderCollectionType ==
            CollectionType.DELIVERY
            ? OrderSetupGlobalState.getInstance().editAddress
              ? "DeliveryAddress"
              : "DeliveryOrderSetup"
            : "PickupOrderSetup"
        );
        //reset to initial state
        OrderSetupGlobalState.getInstance().editAddress = false;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isVisible,
    currentFlow,
    handleOnBackToScreen,
    handleOnChangeCollectionType,
  ]);

  return (
    <View style={styles.container}>
      <CurrentScreen.Component
        state={modalState}
        onNext={
          currentFlow
            ? handleOnNext.bind(this)
            : (state: ModalState) => {
                if (state.OrderCollectionType == CollectionType.DELIVERY) {
                  setCurrentFlow(DeliveryStepsScreens);
                } else {
                  setCurrentFlow(PickupStepsScreens);
                }
                handleNavigation(+1);
              }
        }
        onBack={handleOnBack.bind(this)}
        onBackToScreen={handleOnBackToScreen.bind(this)}
        onChangeCollectionType={handleOnChangeCollectionType.bind(this)}
        onSubmit={handleOnSubmit.bind(this)}
      />
    </View>
  );
};

export default forwardRef(OrderSetupContent) as (
  props: OrderSetupContentProps & {
    ref?: React.ForwardedRef<OrderSetupContentForWardRef>;
  }
) => ReturnType<typeof OrderSetupContent>;
