import "./styles.scss";

import { useMediaQuery } from "hooks/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ErrorReduxModel,
  MenuModels,
  OrderReduxModels,
  StoreReduxModels,
} from "gyg_common";
import closeIcon from "gyg_common/components/assets/icons/icon_close.svg";
import PrimaryBlackButton from "gyg_common/components/Buttons/PrimaryBlackButton";
import IconButton from "gyg_common/components/IconButton";
import MiddleModal from "gyg_common/components/modals/MiddleModal";
import {
  OrderOffset,
  OrderTime,
  StoresTimeZone,
} from "gyg_common/services/api/store/model";

import { OrderSetupSheetContent } from "./OrderSetupSheetContent";

export interface OrderSetupProps {
  cartTotalPrice: number;
  storeOrderOffset: OrderOffset[];
  storeOrderTimes: OrderTime[];
  menuError: ErrorReduxModel.ErrorResponse | undefined;
  menuLoading: boolean;
  menuStructure?: MenuModels.MenuStructure;
  selectedStore: StoreReduxModels.Store | null;
  orderASAP: boolean;
  orderCollectionType: OrderReduxModels.CollectionType;
  orderTime: number | null;
  storeOpenTime: StoreReduxModels.StoreOpenTime;
  menuOpenTime: StoreReduxModels.StoreMenu;
  isVisible: boolean;
  onOrderConfirmClick: (orderSetup: OrderReduxModels.SetupOrderProps) => void;
  onChangeRestaurantClick: () => void;
  onChangeOrderCollectionType: (
    orderSetup: OrderReduxModels.OrderCollectionTypeProps
  ) => void;
  navigateBack: () => void;
  navigateToStoreDetails: () => void;
  onCloseModal: () => void;
  navigateToDownload: (platform: string) => void;
  storeAsapTime: Date;
  storeTimeZoneInfo: StoresTimeZone;
}
export const OrderSetup: React.FC<OrderSetupProps> = ({
  cartTotalPrice,
  storeOrderOffset,
  storeOrderTimes,
  menuError,
  menuStructure,
  selectedStore,
  orderCollectionType,
  orderASAP,
  orderTime,
  isVisible,
  storeOpenTime,
  menuOpenTime,
  onOrderConfirmClick,
  onChangeRestaurantClick,
  onChangeOrderCollectionType,
  navigateToStoreDetails,
  navigateToDownload,
  onCloseModal,
  menuLoading,
  storeAsapTime,
  storeTimeZoneInfo,
}) => {
  const { t } = useTranslation();
  const { isTabletScreen } = useMediaQuery();

  const [orderSetupFormData, setOrderSetupFormData] =
    useState<OrderReduxModels.SetupOrderProps>({
      orderCollectionType: OrderReduxModels.CollectionType.PICK_UP,
      orderTime: 0,
      orderASAP: false,
    });
  const [viewHeight, setViewHeight] = useState<string>("auto");

  const setModalHeight = () => {
    setViewHeight(`${window.innerHeight}px`);
  };

  useEffect(() => {
    setModalHeight();
    window.addEventListener("scroll", setModalHeight);
    window.addEventListener("resize", setModalHeight);
    return () => {
      window.removeEventListener("scroll", setModalHeight);
      window.removeEventListener("resize", setModalHeight);
    };
  }, []);

  return (
    <MiddleModal
      isVisible={isVisible}
      testId='order-setup'
      maxHeight={viewHeight}>
      <div
        className='order-setup-no-delivery'
        style={{ maxHeight: viewHeight }}>
        <div className='order-setup-no-delivery__header'>
          <div className='order-setup-no-delivery__invisible'>
            <div>Invisible</div>
          </div>
          <div className='title-one'>
            {t("OrderManagement:orderSetupTitle")}
          </div>
          <IconButton
            testID='order-setup-close-icon'
            withShadow={false}
            onPress={onCloseModal}
            iconImage={closeIcon}
          />
        </div>
        <OrderSetupSheetContent
          cartTotalPrice={cartTotalPrice}
          storeOrderOffset={storeOrderOffset}
          storeOrderTimes={storeOrderTimes}
          menuLoading={menuLoading}
          menuStructure={menuStructure}
          storeOpenTime={storeOpenTime}
          menuOpenTime={menuOpenTime}
          selectedStore={selectedStore}
          orderCollectionType={orderCollectionType}
          orderASAP={orderASAP}
          orderTime={orderTime}
          onChangeRestaurantClick={onChangeRestaurantClick}
          onChangeOrderCollectionType={onChangeOrderCollectionType}
          navigateToStoreDetails={navigateToStoreDetails}
          setData={setOrderSetupFormData}
          handleLink={navigateToDownload}
          storeAsapTime={storeAsapTime}
          storeTimeZoneInfo={storeTimeZoneInfo}
        />

        {isTabletScreen && <div className='divider' />}
        <div
          className={`${
            isTabletScreen ? "order-setup-no-delivery__button" : "sticky-button"
          }`}>
          {isTabletScreen && (
            <div className='order-setup-no-delivery__button-wrapper' />
          )}
          <div className='order-setup-no-delivery__button-wrapper'>
            <PrimaryBlackButton
              loading={menuLoading}
              border={!isTabletScreen}
              testId='confirm-order-setup-button'
              disable={
                orderCollectionType ==
                  OrderReduxModels.CollectionType.TABLE_SERVICE || !!menuError
              }
              buttonName={t("OrderManagement:confirmButton")}
              onClick={() => {
                onOrderConfirmClick(orderSetupFormData);
              }}
            />
          </div>
        </div>
      </div>
    </MiddleModal>
  );
};
