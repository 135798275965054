import { GrowthBook } from "@growthbook/growthbook-react";

import { AnalyticsInstance } from "../analytics";
import config from "../config";
import * as AnalyticsConst from "../modules/Analytics/const";

// Create a GrowthBook instance
export const gb = new GrowthBook({
  apiHost: config.growthBook.apiHost,
  clientKey: config.growthBook.clientKey,
  // Enable easier debugging during development
  enableDevMode: true,
  // Only required for A/B testing
  // Called every time a user is put into an experiment
  trackingCallback: (experiment, result) => {
    const fallbackValue = "not found";
    const experimentPayload = {
      experiment_id: experiment.key,
      experiment_name: experiment.name || fallbackValue,
      variation_id: result.key,
      variation_name: result.name || fallbackValue,
    };
    AnalyticsInstance.trackEvent(
      AnalyticsConst.Events.ExperimentViewed,
      experimentPayload
    );
  },
});
