import { StoreState } from "redux_store/store/models";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";

import config from "../../config";
import { DEFAULT_TIMEZONE } from "../../modules/Order/constants";
import { basketMapper, getNextAvailableTime } from "../../modules/Order/utils";
import { getVersionAndPlatformData } from "../../modules/platformUtils";
import * as OrderApiService from "../../services/api/order";
import {
  AddToAccountPayload,
  AddToAccountResponse,
  CreateOrderPayload,
  GetOrderByOrderIdPayload,
  GetOrderResponse,
  OrderResponse,
  RateOrderPayload,
  UpdateOrderPayload,
} from "../../services/api/order/model";
import { AnalyticsAction } from "../analytics/analytics.slice";
import { braintreeActions } from "../braintree/braintree.slice";
import { cartActions } from "../cart/cart.slice";
import { CartUpdateState } from "../cart/models";
import { CheckoutAction } from "../checkout/checkout.slice";
import { CheckoutResponse, CheckoutState } from "../checkout/model";
import { errorActions } from "../error/error.slice";
import { ErrorResponse } from "../error/models";
import { buildErrorResponse } from "../error/utils";
import { guestActions } from "../guest/guest.slice";
import { loyaltyActions } from "../loyalty/loyalty.slice";
import { MenuState } from "../menu/menu.slice";
import { isOrderTimeAvailable } from "../utils/OrderUtils";
import { StateEnum, stateSelector } from "../utils/selector";
import {
  GetAuthenticatedOrdersPayload,
  OrderCollectionTypeProps,
  OrderState,
  SetupOrderProps,
} from "./models";
import { orderActions, OrderActionsType } from "./order.slice";

export function* handleCreateOrder(
  action: OrderActionsType
): Generator<
  unknown,
  void,
  OrderResponse | MenuState | OrderState | CheckoutState | StoreState
> {
  try {
    const payload = {
      ...(action.payload as CreateOrderPayload),
      ...getVersionAndPlatformData(),
    };
    const result = (yield call(
      OrderApiService.createOrder,
      payload
    )) as OrderResponse;

    const checkoutState = (yield select(
      stateSelector(StateEnum.checkout)
    )) as CheckoutState;

    // reset checkout slice errors
    yield put(CheckoutAction.resetPayment());
    yield put(orderActions.setOrderResponse(result));
    yield put(loyaltyActions.setUserCoffeeLoyalty(result.basket.coffeeLoyalty));
    yield put(
      CheckoutAction.getClientToken({
        orderId: result.orderId,
        storePaymentMethod: checkoutState.isSavePayment,
      })
    );

    const orderState = (yield select(
      stateSelector(StateEnum.order)
    )) as OrderState;
    const menuState = (yield select(
      stateSelector(StateEnum.menu)
    )) as MenuState;

    const storeState = (yield select(
      stateSelector(StateEnum.store)
    )) as StoreState;

    const timezone =
      menuState?.menuStructure.store.timeZoneInfo.storeTimeZone ??
      DEFAULT_TIMEZONE;

    if (
      orderState &&
      !isOrderTimeAvailable(
        orderState.orderTime,
        storeState.storeOrderTimeSlots.storeOrderTimes,
        timezone
      )
    ) {
      yield put(
        orderActions.updateOrderTime(
          getNextAvailableTime(
            orderState.orderTime,
            storeState.storeOrderTimeSlots.storeOrderTimes,
            timezone
          )
        )
      );
    }

    yield put(AnalyticsAction.setBasketId(result.orderId));
    yield put(AnalyticsAction.setOrderLoyaltyPoints(result.loyalty));
    yield put(
      AnalyticsAction.setTotals({
        totalAfterDiscount: result.basket.total,
        totalBeforeDiscount: result.basket.totalBeforeDiscount,
      })
    );
    yield put(guestActions.setGuestOrderLoyaltyPoints(result.loyalty));
    yield put(braintreeActions.clearPaymentPayload());

    const newCart: CartUpdateState = {
      items: basketMapper(
        result.basket.basketItems,
        menuState?.menuStructure,
        result.basket.tagLookup,
        result.basket.storeInvalidProducts
      ),
      totalPrice: result.basket.total < 0 ? 0 : result.basket.total,
    };
    yield put(cartActions.updateCart(newCart));
  } catch (e) {
    const errorResponse: ErrorResponse = buildErrorResponse(e);
    yield put(orderActions.getOrderError(errorResponse));
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* handleSetupOrderCollectionType(
  action: OrderActionsType
): Generator<unknown, void> {
  try {
    const payload = action.payload as OrderCollectionTypeProps;
    yield put(AnalyticsAction.setupOrderCollectionType(payload));
  } catch (e) {}
}

export function* handleSetupOrder(
  action: OrderActionsType
): Generator<unknown, void> {
  try {
    const payload = action.payload as SetupOrderProps;
    yield put(AnalyticsAction.setupOrder(payload));
  } catch (e) {}
}

export function* handleGetOrderStatus(
  action: OrderActionsType
): Generator<unknown, void, GetOrderResponse> {
  try {
    const result: GetOrderResponse = yield call(
      OrderApiService.getOrderStatus,
      action.payload as GetOrderByOrderIdPayload
    );

    yield put(orderActions.getOrderStatusSuccess(result));
    yield put(CheckoutAction.updatePaymentResponse(result));
    yield put(guestActions.updateOrderStatus(result));
  } catch (e) {
    const errorResponse: ErrorResponse = buildErrorResponse(e);

    yield put(orderActions.getOrderError(errorResponse));
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* handleGetAuthenticatedOrders(
  action: OrderActionsType
): Generator<unknown, void, CheckoutResponse[]> {
  try {
    const authenticatedOrdersPayload: GetAuthenticatedOrdersPayload =
      action.payload || {
        offset: 0,
      };
    const result: CheckoutResponse[] = yield call(
      OrderApiService.getAuthenticatedOrders,
      authenticatedOrdersPayload
    );
    yield put(
      orderActions.setCurrentOrRecentOrders({
        currentOrRecentOrders: result,
        clearCurrentAndRecentOrders: authenticatedOrdersPayload.offset === 0,
      })
    );
  } catch (e) {
    const errorResponse: ErrorResponse = buildErrorResponse(e);

    yield put(orderActions.getOrderError(errorResponse));
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* handleAddToAccount(
  action: OrderActionsType
): Generator<unknown, void, AddToAccountResponse> {
  try {
    const resultAddToAccount: AddToAccountResponse = yield call(
      OrderApiService.addToAccount,
      action.payload as AddToAccountPayload
    );
    yield put(loyaltyActions.getUserLoyalty());
    yield put(guestActions.clearGuestOrderLoyaltyPoints());
    const ordersAddedToAccount = resultAddToAccount.assignedOrders.concat(
      resultAddToAccount.loyaltyAwardedOrders
    );
    yield put(guestActions.removeGuestOrder(ordersAddedToAccount));
    yield put(orderActions.setToAccount());
    yield put(orderActions.getAuthenticatedOrders(undefined));
  } catch (e) {
    yield put(orderActions.addToAccountError());
  }
}

export function* handleGetOrderById(
  action: OrderActionsType
): Generator<unknown, void, CheckoutResponse> {
  try {
    const result: CheckoutResponse = yield call(
      OrderApiService.getOrderById,
      action.payload as string
    );
    yield put(CheckoutAction.setPaymentResponse(result));
    yield put(orderActions.getOrderByIdSuccess(result));
  } catch (e) {
    yield put(orderActions.getOrderByIdError());

    const errorResponse: ErrorResponse = buildErrorResponse(e);

    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* handleUpdateOrderById(
  action: OrderActionsType
): Generator<unknown, void, OrderResponse | MenuState> {
  try {
    const payload = {
      ...(action.payload as UpdateOrderPayload),
      ...getVersionAndPlatformData(),
    };
    const result = (yield call(
      OrderApiService.updateOrderById,
      payload
    )) as OrderResponse;

    // reset checkout slice errors
    yield put(CheckoutAction.resetPayment());
    yield put(orderActions.setOrderResponse(result));
    yield put(
      orderActions.updateOrderByIdSuccess({
        orderResponse: result,
        orderTimeUpdated: payload.orderTimeUpdated,
      })
    );
    yield put(loyaltyActions.setUserCoffeeLoyalty(result.basket.coffeeLoyalty));

    if (result.pickUpTime) {
      yield put(
        orderActions.updateOrderTime(new Date(result.pickUpTime).getTime())
      );
      yield put(
        AnalyticsAction.updateOrderTime(new Date(result.pickUpTime).getTime())
      );
    }

    const menuState = (yield select(
      stateSelector(StateEnum.menu)
    )) as MenuState;

    const newCart: CartUpdateState = {
      items: basketMapper(
        result.basket.basketItems,
        menuState?.menuStructure,
        result.basket.tagLookup,
        result.basket.storeInvalidProducts
      ),
      totalPrice: result.basket.total < 0 ? 0 : result.basket.total,
    };
    yield put(cartActions.updateCart(newCart));

    //update guest loyalty only if the user is a guest
    if (!config.accessToken) {
      yield put(guestActions.setGuestOrderLoyaltyPoints(result.loyalty));
      yield put(AnalyticsAction.setOrderLoyaltyPoints(result.loyalty));
    }
  } catch (e) {
    const errorResponse: ErrorResponse = buildErrorResponse(e);
    yield put(orderActions.getOrderError(errorResponse));
    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* handleRateOrder(
  action: OrderActionsType
): Generator<unknown, void, { message: string }> {
  try {
    yield call(OrderApiService.rateOrder, action.payload as RateOrderPayload);
  } catch (e) {
    const errorResponse: ErrorResponse = buildErrorResponse(e);

    yield put(errorActions.setErrorAPIResponse(errorResponse));
  }
}

export function* watchCreateOrder(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(orderActions.createOrder.type, handleCreateOrder);
}

export function* watchSetupOrderCollectionType(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    orderActions.setupOrderCollectionType().type,
    handleSetupOrderCollectionType
  );
}

export function* watchSetupOrder(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(orderActions.setupOrder().type, handleSetupOrder);
}

export function* watchGetOrderStatus(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(orderActions.getOrderStatus().type, handleGetOrderStatus);
}

export function* watchGetAuthenticatedOrders(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    orderActions.getAuthenticatedOrders.type,
    handleGetAuthenticatedOrders
  );
}

export function* watchAddToAccount(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(orderActions.addToAccount().type, handleAddToAccount);
}

export function* watchGetOrderById(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(orderActions.getOrderById().type, handleGetOrderById);
}

export function* watchUpdateOrderById(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(orderActions.updateOrderById().type, handleUpdateOrderById);
}

export function* watchRateOrder(): Generator<
  Effects.ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(orderActions.rateOrder().type, handleRateOrder);
}

const saga = [
  fork(watchCreateOrder),
  fork(watchSetupOrderCollectionType),
  fork(watchSetupOrder),
  fork(watchGetOrderStatus),
  fork(watchGetAuthenticatedOrders),
  fork(watchAddToAccount),
  fork(watchGetOrderById),
  fork(watchUpdateOrderById),
  fork(watchRateOrder),
];

export default saga;
