import React from "react";
import { useTranslation } from "react-i18next";
import { ImageSourcePropType } from "react-native";

import { openMaps } from "../../modules/Map/utils";
import directionIcon from "../assets/icons/icon_near_me_filled.png";
import TertiaryButton from "../Buttons/TertiaryButton";

interface GetDirectionsProps {
  longitude: string;
  latitude: string;
  id: number;
  name: string;
  onError?: () => void;
}

const GetDirections: React.FC<GetDirectionsProps> = ({
  longitude,
  latitude,
  id,
  name,
  onError,
}) => {
  const { t } = useTranslation();

  const onGetDirections = () => {
    try {
      openMaps(parseFloat(latitude), parseFloat(longitude), id, name);
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onError && onError();
    }
  };

  return (
    <TertiaryButton
      icon={directionIcon as ImageSourcePropType}
      onClick={onGetDirections}
      title={t("StoreSearch:directionsBtn")}
    />
  );
};

export default GetDirections;
