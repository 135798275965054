import { Platform, StyleSheet } from "react-native";

import colours from "../../styles/colours";
import { BorderRadius, Spacing } from "../../styles/number";
import { Typography } from "../../styles/typography";
import {
  PrimaryBlackButtonBackgroundColor,
  PrimaryBlackButtonProps,
} from "./index";

interface StyleProps extends PrimaryBlackButtonProps {
  hovered?: boolean;
  pressed?: boolean;
}

const blackFakeOpacity = "rgba(51, 51, 51, 1)";
// zIndex string only valid for web
const containerZIndex = Platform.OS === "web" ? { zIndex: "auto" } : {};

const styles = (props: StyleProps) =>
  StyleSheet.create({
    bottomNodge: {
      flex: 0,
      backgroundColor: colours.white,
    },
    borderContainer: {
      minWidth: "100%",
      borderWidth: props.border ? 4 : 0,
      borderColor: !props.border
        ? colours.transparent
        : props.backgroundColor === PrimaryBlackButtonBackgroundColor.WHITE ||
            props.disable
          ? colours.white
          : colours.yellow,
      backgroundColor: !props.border
        ? colours.transparent
        : props.backgroundColor === PrimaryBlackButtonBackgroundColor.WHITE ||
            props.disable
          ? colours.white
          : colours.yellow,
      shadowColor: colours.black,
      shadowOffset: {
        width: 0,
        height: -6,
      },
      shadowOpacity: props.shadow ? 0.1 : 0,
      elevation: props.shadow ? 10 : 0,
    },
    container: {
      ...containerZIndex,
      minHeight: 44,
      flexDirection: "row",
      borderRadius: BorderRadius.Medium,
      backgroundColor: props.disable
        ? colours.lightGrey
        : props.hovered || props.pressed
          ? blackFakeOpacity
          : props.loading
            ? colours.semiDarkGrey
            : colours.black,
      paddingVertical: props.border ? Spacing.Thin : Spacing.ExtraLight,
    },
    buttonContainer: {
      flex: 1,
      flexDirection: "row",
      paddingRight: Spacing.Light,
      paddingLeft: Spacing.Light,
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      opacity: props.hovered || props.pressed ? 0.2 : 1,
    },
    leftView: {
      flex: 0.5,
      justifyContent: "flex-start",
    },
    centerText: {
      ...Typography.titleThree,
      flex: 1,
      justifyContent: "center",
      color: props.disable ? colours.uiGrey : colours.yellow,
      textAlign: "center",
      textTransform: "uppercase",
    },
    rightText: {
      ...Typography.btnPrice,
      flex: 0.5,
      justifyContent: "flex-end",
      color: props.disable ? colours.uiGrey : colours.white,
      textAlign: "right",
    },
    loaderStyle: { width: 34, height: 34, position: "absolute" },
    webLoader: {
      width: 32,
      height: 32,
      position: "absolute",
    },
  });

export default styles;
