import React from "react";
import { useTranslation } from "react-i18next";

import ModalContent from "../modals/ModalContent";

interface ToFarAwayViewProps {
  userDistanceFromSelectedLocation: number;
  onChangeAddress: () => void;
  onConfirm: () => void;
}

const ToFarAwayView: React.FC<ToFarAwayViewProps> = ({
  userDistanceFromSelectedLocation,
  onChangeAddress,
  onConfirm,
}) => {
  const { t } = useTranslation();
  return (
    <ModalContent
      title={t("OrderManagement:farAwayTitle")}
      description={t("OrderManagement:farAwayDescription", {
        userDistanceFromSelectedLocation: `${userDistanceFromSelectedLocation.toFixed(
          0
        )}`,
      })}
      actionButtonTitle={t("OrderManagement:changeAddress")}
      secondButtonTitle={t("OrderManagement:continue")}
      onActionTap={onChangeAddress}
      onSecondActionTap={onConfirm}
    />
  );
};

export default ToFarAwayView;
