import React from "react";
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";

import { generateTestID } from "../../modules/platformUtils";
import GradientLine from "../GradientLine";
import { BorderRadius, Spacing } from "../styles/number";
import { Typography } from "../styles/typography";

interface Props {
  handleShowAll: () => void;
  showMore: boolean;
  dataLength: number;
  footerTitle: string;
}

export const styles = StyleSheet.create({
  footer: {
    marginHorizontal: Spacing.Light,
    marginVertical: Spacing.Thin,
    paddingVertical: Spacing.ExtraLight,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: BorderRadius.Medium,
  },
  icon: {
    top: 1,
    width: 24,
    height: 24,
    resizeMode: "cover",
  },
});

const GroupFooter: React.FC<Props> = ({
  footerTitle,
  handleShowAll,
  showMore,
  dataLength,
}) => {
  return (
    <View>
      <TouchableWithoutFeedback
        {...generateTestID(Platform.OS, `SHOW${footerTitle}Button`)}
        onPress={handleShowAll}>
        <View>
          {Platform.OS === "web" ? (
            <div className={"gradient_line"} />
          ) : (
            <GradientLine />
          )}
          <View style={styles.footer}>
            <Text style={Typography.titleThree}>
              {showMore
                ? `SHOW LESS ${footerTitle}`
                : `SHOW ALL ${footerTitle} (${dataLength})`}
            </Text>
            <Image
              style={styles.icon}
              source={
                showMore
                  ? require("../assets/icons/icon_chevron_up_small.png")
                  : require("../assets/icons/icon_chevron_down_small.png")
              }
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
};

export default GroupFooter;
